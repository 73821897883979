import notie from 'notie'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { groupByFunction, stringComparator } from '../../js-lib/array-helpers'
import Icon from '../../app/icon'
import { categories } from '../../recipe/ingredient-categoriser'
import { findGrocer } from '../index'
import { GROCER } from '../../shoppingList/ui/settingsConstants'
import GrocerProductEdit from './GrocerProductEdit'
import GrocerShoppingItem from '../../shoppingList/domain/grocerShoppingItem'
import { generateId } from '../../js-lib/storage/idGenerator'
import ShoppingItemSelector from '../../shoppingList/selector'
import { formatCurrency } from '../../js-lib/currency-formatting'
import { addMinutes, daysDifferent } from '../../js-lib/date-helpers'
import { formatPersonFriendly } from '../../js-lib/date-formatting'

class GrocerCatalogue extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddForm: false,
    }
  }

  handleAdd(product, itemOnShoppingList) {
    const item = itemOnShoppingList || new GrocerShoppingItem({ id: generateId(), quantity: 0 }, product)
    item.quantity = item.quantity + 1
    window.storageService.store('shoppingItem', [item.toStorageFormat()])
  }

  handleRemove(itemOnShoppingList) {
    if (itemOnShoppingList.quantity === 1) {
      itemOnShoppingList.setCompleted()
    } else {
      itemOnShoppingList.quantity = itemOnShoppingList.quantity - 1
    }
    window.storageService.store('shoppingItem', [itemOnShoppingList.toStorageFormat()])
  }

  render() {
    const { props, state } = this
    if (!this.props.grocer) return null
    const productsByCategory = groupByFunction(props.products, (p) => p.category || undefined)
    const cats = Object.keys(productsByCategory)
    let productGroups = []
    categories()
      .concat(['undefined'])
      .forEach((possibleCat) => {
        if (cats.indexOf(possibleCat) > -1) {
          productGroups.push({
            name: possibleCat !== 'undefined' ? possibleCat : 'Miscellaneous',
            products: productsByCategory[possibleCat],
          })
        }
      })

    const outOfDateProducts = props.products.filter((p) => p.outOfDate)

    const oldest = props.products.reduce((lowest, product) => {
      if (product.pricesLastFetched && product.pricesLastFetched < lowest) return product.pricesLastFetched
      if (product.serverLastUpdated < lowest) return product.serverLastUpdated
      return lowest
    }, new Date().getTime())

    return (
      <div className="shopping-list">
        <button
          className="float-right button"
          onClick={(e) => {
            const twoHoursAgo = addMinutes(new Date(), -120).getTime()
            const longestWithoutChange = props.products
              .filter((p) => p.serverLastUpdated < twoHoursAgo)
              .sort((a, b) => a.serverLastUpdated - b.serverLastUpdated)
              .slice(0, 50)
            if (longestWithoutChange.length > 0) {
              props.grocer.getLatestPrices([...new Set(longestWithoutChange.map((p) => p.grocerProductId))]).then((latestPrices) => {
                const updatedProducts = latestPrices.map((latestPrice) => {
                  const product = longestWithoutChange.find((p) => p.grocerProductId === latestPrice.id)
                  product.price = latestPrice.price
                  product.pricesLastFetched = new Date().getTime()
                  product.onSpecial = latestPrice.onSpecial
                  product.previousPrice = latestPrice.previousPrice
                  return product
                })
                window.storageService.store('grocerProduct', updatedProducts)
                notie.alert({ type: 'success', text: `Updated ${updatedProducts.length} products` })
              })
            }
          }}
        >
          Update{daysDifferent(oldest, new Date().getTime()) > 1 && ' (' + formatPersonFriendly(new Date(oldest)) + ')'}
        </button>
        <h1 className="subtitle">{props.grocer.getName()} Catalogue</h1>

        {outOfDateProducts.length > 0 && (
          <div style={{ marginBottom: '10px', padding: '10px', borderRadius: '5px', backgroundColor: '#e5795c', color: 'white' }}>
            <p>Sync error: these products were updated on another device and local changes are conflicting.</p>

            <ul style={{ 'list-style': 'disc', paddingLeft: '20px' }}>
              {outOfDateProducts.map((outOfDateProducts) => {
                return <li key={outOfDateProducts.name}>{outOfDateProducts.name}</li>
              })}
            </ul>
            <button
              className="button"
              onClick={(e) => {
                window.storageService.resetAndSync('grocerProduct')
              }}
            >
              Discard your changes
            </button>
          </div>
        )}

        {state.showAddForm && <GrocerProductEdit product={{}} />}

        {!state.showAddForm &&
          productGroups.map((productGroup) => {
            return (
              <div key={productGroup.name} style={{ marginTop: '15px' }}>
                <h3 style={{ color: '#e5795c', fontSize: '1rem' }}>{productGroup.name}</h3>
                {productGroup.products
                  .sort((a, b) => stringComparator(a.name, b.name))
                  .map((product) => {
                    const itemOnShoppingList = props.shoppingItems.find(
                      (si) => si.grocerProduct && si.grocerProduct.grocerProductId === product.grocerProductId
                    )
                    return (
                      <div key={product.name} className="shopping-list-item">
                        <div style={{ display: 'flex' }}>
                          <div>
                            {product.image && <img src={product.image} alt={product.name} style={{ padding: '5px' }} width="70px" />}
                          </div>
                          <div style={{ padding: '5px', width: '100%' }}>
                            <p>{product.name}</p>
                            <small>
                              {!!product.price && <span>{formatCurrency('AUD', product.price * 100)} </span>}
                              {product.url && <a href={product.url}>{new URL(product.url).hostname.replace('www.', '')}</a>}
                              <div style={{ float: 'right' }}>
                                {!itemOnShoppingList && (
                                  <button
                                    onClick={(e) => this.handleAdd(product, null)}
                                    style={{ marginLeft: '5px' }}
                                    className="button is-small"
                                  >
                                    Buy
                                  </button>
                                )}
                                {itemOnShoppingList && (
                                  <div style={{ display: 'inline-block' }}>
                                    Buying
                                    <button
                                      onClick={(e) => this.handleRemove(itemOnShoppingList)}
                                      style={{ marginLeft: '5px' }}
                                      className="button is-small"
                                    >
                                      -
                                    </button>
                                    &nbsp;{itemOnShoppingList.quantity}
                                    <button
                                      onClick={(e) => this.handleAdd(product, itemOnShoppingList)}
                                      style={{ marginLeft: '5px' }}
                                      className="button is-small"
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                                <Link to={'/grocer/products/edit/' + product.id}>
                                  <button style={{ marginLeft: '5px' }} className="button is-small">
                                    Edit
                                  </button>
                                </Link>
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            )
          })}

        {!state.showAddForm && (
          <div className="hover-button-wrapper">
            <Link to="/grocer/products/add">
              <button alt="Add product" className="hover-button">
                <span className="hover-button-icon">
                  <Icon width="20" height="20" id="add" />
                </span>
              </button>
            </Link>
          </div>
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect((state, ownProps) => {
  const grocer = state.setting.byName[GROCER] ? findGrocer(state.setting.byName[GROCER]) : null
  let products = []
  if (grocer) {
    products = Object.values(state.grocerProduct.byId).filter((p) => p.grocerId === grocer.getId())
  }

  const shoppingItems = new ShoppingItemSelector(state).notCompleted().filter((i) => i instanceof GrocerShoppingItem)
  return { grocer, products, shoppingItems }
}, mapDispatchToProps)(GrocerCatalogue)
