import notie from 'notie'
import { getAuthToken, removeAuthToken } from '../auth-helpers'

var parseJwt = function (token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

const extractUserId = function (token) {
  var decoded = parseJwt(token)
  return decoded.data.id
}

export const extractUsername = function (token) {
  var decoded = parseJwt(token)
  return decoded.data.username
}

const defaultState = function () {
  const token = getAuthToken()
  const userId = token ? extractUserId(token) : null
  const username = token ? extractUsername(token) : null
  return { token, userId, username }
}

const reducer = function (state = defaultState(), action) {
  switch (action.type) {
    case 'LOGOUT':
      return {}
    case 'AUTHENTICATED':
      window.location.href = '/'
      return { ...state.auth, token: action.token, userId: extractUserId(action.token), username: extractUsername(action.token) }
      return state
    case 'AUTHENTICATION_TOKEN_EXPIRED':
      removeAuthToken()
      return { ...state.auth, token: null, userId: null }
    case 'AUTHENTICATION_FAILED':
      notie.alert({ type: 'error', text: 'Login failed', time: 2 })
      return state
    default:
      return state
  }
}

export default reducer
