import { getAuthToken } from "./auth-helpers";
import OptimisticLockingError from '../js-lib/storage/optimisticLockingError'

export const _verifyResponse = (res) => {
  let contentType = res.headers.get('Content-Type');

  if (res.status === 401) {
    _handleError({ message: 'Access denied' });
    return
  }

  if (res.status === 409) {
    throw new OptimisticLockingError("Object is out-of-date")
  }

  if (res.status >= 400) {
    _handleError({ message: 'Bad request or server error' });
    return
  }

  if (contentType && contentType.indexOf('application/json') === 0) {
    return res.json();
  } else {
    console.error(`API request failed ${res.status} ${res.statusText}`)
    _handleError({ message: 'Response was not JSON'});
  }
}

export const _handleError = (error) => {
  console.error('An error occurred:', error);
  throw error;
}

export class HeadersBuilder {
  constructor() {
    this.values = {}
  }

  auth() {
    const authToken = getAuthToken()
    if (authToken) {
      this.values['authorization'] = 'Bearer ' + authToken
    }
    return this
  }

  json() {
    this.values['content-type'] = 'application/json'
    return this
  }

  text() {
    this.values['content-type'] = 'text/plain'
    return this
  }

  userOverride(userId) {
    this.values['user-id-override'] = userId
    return this
  }

  build() {
    return new Headers(this.values)
  }
}
