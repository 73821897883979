import React from 'react'
import { connect } from 'react-redux'
import { setSetting } from '../../app/settings'
import notie from 'notie'
import AuthSelector from '../../app/AuthSelector'
import { fetchEatingPlanDetails } from '../api'
import { withoutValue } from '../../js-lib/array-helpers'
import { getAuthToken } from '../../app/auth-helpers'

class Settings extends React.Component {
  constructor(props) {
    super(props)
    this.state = { shareWith: '' }
  }

  async componentWillMount() {
    const details = await fetchEatingPlanDetails()
    this.setState({ listDetails: details })
  }

  async handleChange(property, newValue) {
    const setting = await setSetting(property, newValue)
    this.props.settingsFetched([setting])
  }

  async handleShareEatingPlan(email) {
    if (email) {
      email = email.trim()
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      notie.alert({ type: 'error', text: 'That email address does not appear to be valid.' })
      return
    }

    if (email === this.props.username) {
      notie.alert({ type: 'error', text: "You can't invite yourself!" })
      return
    }

    let invite = {
      type: 'eatingPlan',
      inviteeUsername: email,
    }
    await window.storageService.store('invite', [invite])

    notie.alert({ type: 'success', text: 'Successfully invited ' + email + ' to share your eating plan.' })
  }

  render() {
    const { props, state } = this
    const authToken = getAuthToken()
    return (
      <div>
        <h4 className="subtitle is-4">Meal planner settings</h4>

        <div className="padded-below-big">
          <h5 className="subtitle is-5">Eating plan frequency</h5>
          <div className="select">
            <select value={props.settings['eatingPlan.view']} onChange={(e) => this.handleChange('eatingPlan.view', e.target.value)}>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="adhoc">Ad-hoc</option>
            </select>
          </div>
        </div>

        {props.settings['eatingPlan.view'] === 'weekly' && (
          <div className="padded-below-big">
            <h5 className="subtitle is-5">Week starts on</h5>
            <div className="select">
              <select
                value={props.settings['eatingPlan.firstDayOfWeek']}
                onChange={(e) => this.handleChange('eatingPlan.firstDayOfWeek', e.target.value)}
              >
                <option value="sunday">Sunday</option>
                <option value="monday">Monday</option>
              </select>
            </div>
          </div>
        )}

        <div className="padded-below-big">
          <form onSubmit={(e) => e.preventDefault() || this.handleShareEatingPlan(this.state.shareWith)}>
            <h5 className="subtitle is-5">Share meal plan</h5>
            <p>
              People can share meal plans. If you'd like to share with someone, enter their email address and an invitation will be sent to
              them. If they accept your invitation, their items will be merged with yours.
            </p>
            {authToken && (
              <input
                id="shareList"
                autoComplete="off"
                type="email"
                placeholder="Email address"
                className="input"
                value={state.shareWith}
                onInput={(e) => this.setState({ shareWith: e.target.value })}
              />
            )}
            {!authToken && (
              <div style={{ marginBottom: '10px', padding: '10px', borderRadius: '5px', backgroundColor: '#e5795c', color: 'white' }}>
                Only available after you are logged in
              </div>
            )}

            {state.listDetails && state.listDetails.users && state.listDetails.users.length > 1 && (
              <div style={{ marginTop: '10px' }}>
                Your eating plan is currently shared with:{' '}
                <ul>
                  {withoutValue(state.listDetails.users, props.username).map((user) => {
                    return (
                      <div key={user}>
                        <li>&middot; {user}</li>
                      </div>
                    )
                  })}
                </ul>
              </div>
            )}
          </form>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    settingsFetched: (settings) => {
      dispatch({ type: 'SETTINGS_FETCHED', objects: settings })
    },
  }
}

export default connect(
  (state, ownProps) => ({
    username: new AuthSelector(state).username(),
    settings: {
      'eatingPlan.view': state.setting.byName['eatingPlan.view'],
      'eatingPlan.firstDayOfWeek': state.setting.byName['eatingPlan.firstDayOfWeek'],
    },
  }),
  mapDispatchToProps
)(Settings)
