import { deleteDB } from 'idb'
import StorageService from '../js-lib/storage/storage-service'
import { store as storeRemote, fetchLatest } from './api'
import { isAuthenticated } from './auth-helpers'

const migrate = (db, currentVersion, newVersion, syncedTable, notSyncedTable) => {
  const range = []
  for (let i = currentVersion + 1; i <= newVersion; i++) {
    range.push(i)
  }

  if (range.indexOf(1) > -1) {
    db.createObjectStore(syncedTable('recipe'), { keyPath: 'id' })
    db.createObjectStore(notSyncedTable('recipe'), { keyPath: 'id' })
    db.createObjectStore(syncedTable('recipeView'), { keyPath: 'id' })
    db.createObjectStore(notSyncedTable('recipeView'), { keyPath: 'id' })
  }

  if (range.indexOf(2) > -1) {
    db.createObjectStore(syncedTable('setting'), { keyPath: 'id' })
    db.createObjectStore(notSyncedTable('setting'), { keyPath: 'id' })
  }

  if (range.indexOf(4) > -1) {
    db.createObjectStore(syncedTable('shoppingItem'), { keyPath: 'id' })
    db.createObjectStore(notSyncedTable('shoppingItem'), { keyPath: 'id' })
  }

  if (range.indexOf(5) > -1) {
    db.createObjectStore(syncedTable('invite'), { keyPath: 'id' })
    db.createObjectStore(notSyncedTable('invite'), { keyPath: 'id' })
  }

  if (range.indexOf(6) > -1) {
    db.createObjectStore(syncedTable('mealPlan'), { keyPath: 'id' })
    db.createObjectStore(notSyncedTable('mealPlan'), { keyPath: 'id' })
  }
  if (range.indexOf(7) > -1) {
    db.createObjectStore(syncedTable('recipeOverride'), { keyPath: 'id' })
    db.createObjectStore(notSyncedTable('recipeOverride'), { keyPath: 'id' })
  }
  if (range.indexOf(8) > -1) {
    db.createObjectStore(syncedTable('grocerProduct'), { keyPath: 'id' })
    db.createObjectStore(notSyncedTable('grocerProduct'), { keyPath: 'id' })
  }
}

export const objectTypes = ['recipe', 'recipeView', 'recipeOverride', 'setting', 'shoppingItem', 'invite', 'mealPlan', 'grocerProduct']

const generateDatabaseName = async () => 'palatable.kitchen'

export const initStorage = () => {
  const api = { storeRemote, fetchLatest }
  const database = {
    generateName: generateDatabaseName,
    version: 8,
    migrate
  }
  window.storageService = new StorageService(objectTypes, isAuthenticated, api, database)
  return window.storageService
}

export const destroyStorage = async () => {
  const name = await generateDatabaseName()
  // don't await; let other db transactions complete
  deleteDB(name)
}
