import React from 'react'

import MealPlanShow from './MealPlanShow'
import EatingPlanDayEdit from './EatingPlanDayEdit'
import { shortDayOfWeek } from '../../js-lib/date-helpers'

class EatingPlanDayShow extends React.Component {
  constructor(props) {
    super(props)
    const mealPlans = props.mealPlans.filter((mp) => mp.day === props.date.getDate())
    this.state = {
      editMode: false,
      mealPlans,
    }
  }

  orderOfMeal(meal) {
    if (meal === 'breakfast') {
      return 0
    }
    if (meal === 'lunch') {
      return 1
    }
    if (meal === 'dinner') {
      return 2
    }
  }

  renderMealContentForDay(mealPlans) {
    if (mealPlans.length === 0) {
      return <div className="meal">Nothing planned</div>
    }

    return (
      <div>
        {mealPlans
          .sort((a, b) => {
            return this.orderOfMeal(a.meal) - this.orderOfMeal(b.meal)
          })
          .map((mealPlan) => {
            return <MealPlanShow key={mealPlan.id} mealPlan={mealPlan} />
          })}
      </div>
    )
  }

  render() {
    const { props, state } = this
    const { date, mealPlans } = props
    return (
      <div>
        <div className="clickable" onClick={(e) => this.setState({ editMode: true })}>
          <div className="columns is-mobile">
            <div className="column is-narrow">
              <div className="calendar">
                <div className="day">{date.getDate()}</div>
                <div className="action">{shortDayOfWeek(date)}</div>
              </div>
            </div>
            <div className="column meal-content">
              {!state.editMode && this.renderMealContentForDay(mealPlans)}
              {state.editMode && (
                <EatingPlanDayEdit
                  date={props.date}
                  mealPlans={props.mealPlans}
                  recipeToAdd={props.recipeToAdd}
                  onFinished={(e) => {
                    this.setState({ editMode: false })
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EatingPlanDayShow
