import React from 'react'

import EatingPlanDayShow from './EatingPlanDayShow'
import { getDatesInMonth, getMonth, getYear, startOfMonth, addMonth, addMonths } from '../../js-lib/date-helpers'
import { formatMonthYear } from '../../js-lib/date-formatting'
import Swipe from './Swipe'

class EatingPlanMonthShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  nextMonth() {
    this.setState({ dateToShow: addMonth(this.state.dateToShow) })
  }

  previousMonth() {
    this.setState({ dateToShow: addMonths(this.state.dateToShow, -1) })
  }

  async componentWillMount() {
    this.setState({ dateToShow: startOfMonth(new Date()) })
  }

  render() {
    const { props, state } = this
    const month = getMonth(state.dateToShow)
    const year = getYear(state.dateToShow)
    const days = getDatesInMonth(month, year)

    const mealPlans = (props.mealPlans || []).filter((mealPlan) => mealPlan.month === month && mealPlan.year === year)
    return (
      <Swipe swipeRight={() => this.previousMonth()} swipeLeft={() => this.nextMonth()}>
        <h3 className="subtitle is-6 text-section-header">{formatMonthYear(state.dateToShow)}</h3>
        {days.map((day, index) => {
          return (
            <div key={'meal-plan-' + index}>
              <EatingPlanDayShow date={day} mealPlans={mealPlans.filter((mp) => mp.day === day.getDate())} />
              {index !== days.length - 1 && <div className="hr" />}
            </div>
          )
        })}
        <div style={{ marginTop: '20px' }}>
          <button className="button" onClick={(e) => this.previousMonth()}>
            Previous month
          </button>
          &nbsp;
          <button className="button" onClick={(e) => this.nextMonth()}>
            Next month
          </button>
        </div>
      </Swipe>
    )
  }
}

export default EatingPlanMonthShow
