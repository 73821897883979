// @ts-ignore
import { useState } from "react";
import { StyledTextBox, SubmitButton } from "./form-components/form-components";

function AuthenticationForm(props: any) {
  let [username, setUsername] = useState('');
  let [password, setPassword] = useState('');

  return (
    <div style={{margin: "20px"}}>
      <form onSubmit={(e) => { e.preventDefault(); props.onSubmit(username, password)}}>
        <StyledTextBox vertical label="Username" value={username} onChange={(value: string) => setUsername(value)} />
        <StyledTextBox vertical fieldType="password" label="Password" value={password} onChange={(value: string) => setPassword(value)} />
        <SubmitButton buttonText="Login" />
      </form>
    </div>
  )
}

export default AuthenticationForm
