import 'notie/dist/notie.min.css'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { connect } from 'react-redux'
import './app.css'
import './index.css'
import Header from './header'
import { AppWarnings } from '../../js-lib-react/components/AppWarnings'
import AuthSelector from '../AuthSelector'

const queryClient = new QueryClient()

function Layout(props: any) {
  return (
    <div className="App">
      <ScrollRestoration />
      <Header />

      <div className="main-content main-content-extended">
        <div className="container">
          <AppWarnings>{/* TODO: should include warnings about when data conflicts occur */}</AppWarnings>
          <QueryClientProvider client={queryClient}>
            <Outlet />
          </QueryClientProvider>
        </div>
      </div>
    </div>
  )
}

const ConnectedLayout = connect((state, props) => {
  return {
    authenticated: new AuthSelector(state).isAuthenticated(),
  }
})(Layout)

export default ConnectedLayout
