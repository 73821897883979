import React from 'react'
import { connect } from 'react-redux'
import MealPlan from '../meal-plan'
import { getMonth, getYear } from '../../js-lib/date-helpers'
import Icon from '../../app/icon'
import RecipeAutoComplete from '../../recipe/ui/RecipeAutoComplete'
import { withNavigation } from '../../js-lib-react'

class EatingPlanDayEdit extends React.Component {
  constructor(props) {
    super(props)
    const mealPlans = props.mealPlans.filter((mp) => mp.day === props.date.getDate())
    this.state = {
      breakfast: this.initMealPlan('breakfast', mealPlans),
      lunch: this.initMealPlan('lunch', mealPlans),
      dinner: this.initMealPlan('dinner', mealPlans),
    }
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleKeyPress(event) {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault()
      this.handleMealPlansSave()
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress, false)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false)
  }

  initMealPlan(meal, mealPlans) {
    let mealPlan = mealPlans.find((mp) => mp.meal === meal)
    if (!mealPlan) {
      mealPlan = new MealPlan({
        day: this.props.date.getDate(),
        month: getMonth(this.props.date),
        year: getYear(this.props.date),
        meal: meal,
        dishes: [],
      })
    }
    if (!this.props.recipeToAdd) {
      const alreadyHasEmptyOne = mealPlan.dishes.length > 0 && mealPlan.dishes[mealPlan.dishes.length - 1].text === ''
      if (!alreadyHasEmptyOne) {
        mealPlan.dishes.push({ type: 'plainText', text: '' })
      }
    }
    return mealPlan
  }

  removeEmptyDishes(mealPlan) {
    const result = this.cloneMealPlan(mealPlan)
    result.dishes = result.dishes.filter((d) => (d.type === 'plainText' && d.text !== '') || d.type === 'recipe')
    return result
  }

  cloneMealPlan(mealPlan) {
    return new MealPlan({
      id: mealPlan.id,
      lastUpdated: mealPlan.lastUpdated,
      serverLastUpdated: mealPlan.serverLastUpdated,
      day: mealPlan.day,
      month: mealPlan.month,
      year: mealPlan.year,
      meal: mealPlan.meal,
      dishes: [...mealPlan.dishes],
    })
  }

  async handleMealPlansSave() {
    const mealPlans = [
      this.removeEmptyDishes(this.state.breakfast),
      this.removeEmptyDishes(this.state.lunch),
      this.removeEmptyDishes(this.state.dinner),
    ].filter((mp) => mp.id || mp.dishes.length > 0)

    const plansToStore = []
    for (let mealPlan of mealPlans) {
      if (mealPlan.dishes.length === 0) {
        mealPlan.archived = true
      }
      plansToStore.push(mealPlan)
    }
    if (plansToStore.length > 0) {
      await window.storageService.store('mealPlan', plansToStore)
    }

    this.props.onFinished()
  }

  handleInput(meal, index, value) {
    const mealPlan = this.cloneMealPlan(this.state[meal])
    mealPlan.dishes[index] = value
    const last = mealPlan.dishes[mealPlan.dishes.length - 1]
    if (last && last.type === 'plainText' && last.text !== '') {
      mealPlan.dishes.push({ type: 'plainText', text: '' })
    }
    this.setState({ [meal]: mealPlan })
  }

  removeDish(meal, index) {
    const mealPlan = this.cloneMealPlan(this.state[meal])
    mealPlan.dishes.splice(index, 1)
    this.setState({ [meal]: mealPlan })
  }

  renderMealPlanEdit(mealPlan, onInput, onRemoveDish) {
    return (
      <div className={'meal meal-' + mealPlan.meal}>
        <div className="meal-name">{mealPlan.meal}</div>
        <div>
          {mealPlan.dishes.map((dish, index) => {
            const recipe = dish.recipeId ? this.props.recipesById[dish.recipeId] : null
            return (
              <div key={index} className="dish-input">
                {!this.props.recipeToAdd && dish.type === 'plainText' && (
                  <RecipeAutoComplete
                    value={dish.text}
                    items={Object.values(this.props.recipesById)}
                    onSelect={(ignore, recipe) => {
                      if (recipe) {
                        onInput(index, { ...dish, type: 'recipe', recipeId: recipe.id, recipeName: recipe.name })
                      }
                    }}
                    onInput={(name) => {
                      onInput(index, { ...dish, text: name })
                    }}
                  />
                )}
                {this.props.recipeToAdd && dish.type === 'plainText' && <span>{dish.text}</span>}
                {dish.type === 'recipe' && (
                  <div>
                    {recipe ? recipe.name : dish.recipeId}
                    {!this.props.recipeToAdd && (
                      <button className="button" style={{ marginLeft: '10px' }} onClick={(e) => e.preventDefault() || onRemoveDish(index)}>
                        <Icon width="16" height="16" id="delete" />
                      </button>
                    )}
                  </div>
                )}
              </div>
            )
          })}
          {this.props.recipeToAdd && (
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault()
                onInput(mealPlan.dishes.length, {
                  type: 'recipe',
                  recipeName: this.props.recipeToAdd.name,
                  recipeId: this.props.recipeToAdd.id,
                })
                this.handleMealPlansSave()
                this.props.navigate('/mealPlanner')
              }}
            >
              Add here
            </button>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { props, state } = this
    return (
      <div>
        <form onSubmit={(e) => this.handleMealPlansSave()}>
          {this.renderMealPlanEdit(
            state.breakfast,
            (index, value) => this.handleInput('breakfast', index, value),
            (index) => this.removeDish('breakfast', index)
          )}
          {this.renderMealPlanEdit(
            state.lunch,
            (index, value) => this.handleInput('lunch', index, value),
            (index) => this.removeDish('lunch', index)
          )}
          {this.renderMealPlanEdit(
            state.dinner,
            (index, value) => this.handleInput('dinner', index, value),
            (index) => this.removeDish('dinner', index)
          )}
          {!this.props.recipeToAdd && (
            <button
              className="button margin-top margin-right"
              type="submit"
              onClick={(e) => e.preventDefault() || this.handleMealPlansSave()}
            >
              Save
            </button>
          )}
          {!this.props.recipeToAdd && (
            <button
              className="button margin-top margin-right"
              type="button"
              onClick={(e) => e.stopPropagation() || this.props.onFinished()}
            >
              Cancel
            </button>
          )}
        </form>
      </div>
    )
  }
}

export default withNavigation(
  connect((state, ownProps) => {
    return {
      recipesById: state.recipe.byId,
    }
  })(EatingPlanDayEdit)
)
