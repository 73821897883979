const defaults = {
  'recipe.temperatureUnit': '',
  'recipe.listingPageContent': 'recentlyViewed',
  'recipe.listingPageContentTag': null,
  'eatingPlan.view': 'weekly',
  'eatingPlan.firstDayOfWeek': 'sunday',
}

export const getSetting = async (settingName) => {
  const settings = await window.storageService.fetchImmediatelyAvailable('setting')
  return settings.find(s => s.name === settingName)
}

export const getSettingValue = async (settingName) => {
  const setting = await getSetting(settingName)
  if (!setting) {
    return defaults[settingName]
  }

  return setting.value
}

export const getSettings = async (settingNamePrefix) => {
  let settings = await window.storageService.fetchImmediatelyAvailable('setting')
  let names = Object.keys(defaults)
  if (settingNamePrefix) {
    names = names.filter(d => d.startsWith(settingNamePrefix))
  }
  const result = {}
  names.forEach(name => {
    const setting = settings.find(s => s.name === name)
    result[name] = setting ? setting.value : defaults[name]
  })
  return result
}

export const setSetting = async (settingName, value) => {
  let setting = await getSetting(settingName)
  if (!setting) {
    setting = {
      name: settingName,
    }
  }
  setting.value = value

  window.storageService.store('setting', [setting])
  return setting
}
