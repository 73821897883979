import React from 'react'
import { v4 as uuid } from 'uuid'

class Swipe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uuid: uuid(),
    }

    this.xDown = null
    this.yDown = null
    this.doTouchBreak = null
  }

  componentDidMount() {
    const element = this.findElement(this)
    element.addEventListener('touchstart', (evt) => this.handleTouchStart(this, evt), false)
    element.addEventListener('touchmove', (evt) => this.handleTouchMove(this, evt), false)
    element.addEventListener('touchend', () => this.handleTouchEnd(this), false)
    element.addEventListener('touchcancel', () => this.handleTouchEnd(this), false)
  }

  // componentWillUnmount() {
  //   const element = this.findElement(this)
  //   //...
  // }

  handleTouchEnd(instance) {
    instance.doTouchBreak = null
  }

  handleTouchStart(instance, evt) {
    instance.xDown = evt.touches[0].clientX
    instance.yDown = evt.touches[0].clientY
  }

  handleTouchMove(instance, evt) {
    const minDelta = 100
    if (!instance.xDown || !instance.yDown || instance.doTouchBreak) {
      return
    }

    var xUp = evt.touches[0].clientX
    var yUp = evt.touches[0].clientY

    var xDiff = instance.xDown - xUp
    var yDiff = instance.yDown - yUp

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > minDelta) {
        if (instance.props.swipeLeft) {
          instance.props.swipeLeft(instance)
        }
        instance.doTouchBreak = true
      } else if (xDiff < -minDelta) {
        if (instance.props.swipeRight) {
          instance.props.swipeRight(instance)
        }
        instance.doTouchBreak = true
      }
    } else {
      if (yDiff > minDelta) {
        /* up swipe */
        console.log('up')
        if (instance.props.swipeUp) {
          instance.props.swipeUp()
        }
        instance.doTouchBreak = true
      } else if (yDiff < -minDelta) {
        /* down swipe */
        console.log('down')
        if (instance.props.swipeDown) {
          instance.props.swipeDown()
        }
        instance.doTouchBreak = true
      }
    }

    if (instance.doTouchBreak) {
      instance.xDown = null
      instance.yDown = null
    }
  }

  findElement(instance) {
    return document.getElementsByClassName('swipe_' + instance.state.uuid)[0]
  }

  render() {
    const { props, state } = this
    return <div className={'swipe_' + this.state.uuid}>{props.children}</div>
  }
}

export default Swipe
