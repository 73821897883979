import {mapListById, separate} from '../js-lib/array-helpers'

const defaultState = {
  byId: {},
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return defaultState
    case 'GROCER_PRODUCTS_FETCHED':
      const [archived, nonArchived] = separate(action.objects, o => o.archived === true)
      const result = { ...state, byId: { ...state.byId, ...mapListById(nonArchived) } }
      for (let arch of archived) {
        delete result.byId[arch.id]
      }
      return result
    default:
      return state
  }
}

export default reducer
