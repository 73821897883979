

export const filter = (recipes, includes, excludes) => {

  if (includes.length === 0 && excludes.length === 0) {
    return recipes
  }

  return recipes.filter(recipe => {
    return matchingRecipes(recipe, excludes).length === 0 && matchingRecipes(recipe, includes).length === includes.length
  })
}

const matchingRecipes = (recipe, searchTerms) => {
  return searchTerms.filter(searchTerm => {
    if (searchTerm.startsWith('tag: ')) {
      return recipe.tags && recipe.tags.find( tag => contains(tag, searchTerm.replace('tag: ', '')))
    }
    if (searchTerm.startsWith('source: ')) {
      return recipe.source && contains(recipe.source, searchTerm.replace('source: ', ''))
    }

    searchTerm = searchTerm.toLowerCase()
    return (recipe.name && contains(recipe.name.toLowerCase(), searchTerm)) ||
      (recipe.intro && contains(recipe.intro.toLowerCase(), searchTerm)) ||
      (recipe.ingredients && contains(recipe.ingredients.toLowerCase(), searchTerm)) ||
      (recipe.instructions && contains(recipe.instructions.toLowerCase(), searchTerm)) ||
      (recipe.source && contains(recipe.source.toLowerCase(), searchTerm)) ||
      (recipe.tags && recipe.tags.find( tag => contains(tag.toLowerCase(), searchTerm)))
  })
}

const contains = (haystack, needle) => {
  return haystack.indexOf(needle) > -1
}

export const tokeniseSearchString = (searchString) => {
  const result = {
    includes: [],
    excludes: []
  }

  if (searchString) {
    const quoted = searchString.match(/-?"(.+?)"/g)
    if (quoted) {
      for (var q of quoted) {
        searchString = searchString.replace(q, '')
        if (q.indexOf('-') === 0) {
          result.excludes.push(q.substring(2, q.length - 1))
        } else {
          result.includes.push(q.substring(1, q.length - 1))
        }
      }
    }

    searchString.split(" ").filter(t => !!t).forEach(s => {
      if (s.indexOf('-') === 0) {
        result.excludes.push(s.substring(1))
      } else {
        result.includes.push(s)
      }
    })
  }

  return result
}
