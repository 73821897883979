import BaseShoppingItem from "./baseShoppingItem"
import { getGrocer } from "../../grocer"

class GrocerShoppingItem extends BaseShoppingItem {

  constructor(attributes, grocerProduct) {
    const {id, category, userId, lastUpdated, serverLastUpdated, shoppingListId, completedAt, outOfDate, recipeId, recipeName, archived, quantity} = attributes
    super(id, category, userId, lastUpdated, serverLastUpdated, shoppingListId, completedAt, outOfDate, recipeId, recipeName, archived, quantity)
    this.grocerProduct = grocerProduct
  }

  getTitle() {
    return this.grocerProduct ? this.grocerProduct.name : null
  }

  getUrl() {
    return this.grocerProduct ? this.grocerProduct.url : null
  }

  getShop() {
    if (this.grocerProduct) {
      return getGrocer(this.grocerProduct.grocerId).getName()
    }
    return null
  }

  getPrettyHost() {
    const url = this.getUrl()
    return url ? new URL(url).hostname.replace('www.', '') : null
  }

  getImage() {
    return this.grocerProduct ? this.grocerProduct.image : null
  }

  getCategory() {
    return this.grocerProduct ? this.grocerProduct.category : null
  }

  getPrice() {
    return this.grocerProduct ? this.grocerProduct.price : null
  }

  getIsOnSpecial() {
    return this.grocerProduct && this.grocerProduct.onSpecial
  }

  // TODO: not sure this belongs on this object -- it shouldn't really be aware of
  // the way it is stored
  toStorageFormat() {
    const {id, category, userId, lastUpdated, serverLastUpdated, shoppingListId, completedAt, outOfDate, recipeId, recipeName, archived, quantity} = this
    return {
      id, category, userId, lastUpdated, serverLastUpdated, shoppingListId, completedAt, outOfDate, recipeId, recipeName, archived, quantity,
      grocerProductId: this.grocerProduct.id,
    }
  }

  clone() {
    return new GrocerShoppingItem(this.toStorageFormat(), this.grocerProduct)
  }
}

export default GrocerShoppingItem
