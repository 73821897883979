import React from 'react'
import { generateId } from '../../js-lib/storage/idGenerator'
import EatingPlanDayShow from './EatingPlanDayShow'
import { addDays, daysOfWeek, getDatesInWeek, startOfWeek, zeroTime } from '../../js-lib/date-helpers'
import Swipe from './Swipe'
import MealPlan from '../meal-plan'
import { setSetting } from '../../app/settings'
import { choose } from '../auto-meal-plan'

class EatingPlanWeekShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentWillMount() {
    let firstDay = zeroTime(startOfWeek(new Date()))
    if (this.props.firstDayOfWeek === 'monday') {
      firstDay = addDays(firstDay, 1)
    }

    this.setState({ firstDay })
  }

  nextWeek() {
    this.setState({ firstDay: addDays(this.state.firstDay, 7) })
  }

  previousWeek() {
    this.setState({ firstDay: addDays(this.state.firstDay, -7) })
  }

  autoPlanMeals() {
    const mealPlans = this.props.mealPlans
    const allRecipes = this.props.allRecipes
    const dinnerRotationSettings = this.props.dinnerRotationSettings
    let dinnerRotationSettingsModified = false

    const dates = getDatesInWeek(this.state.firstDay)

    for (const [index, date] of dates.entries()) {
      const dayOfWeek = daysOfWeek[date.getDay()]

      const dayMealPlan = mealPlans.filter((mp) => dates[index].getTime() === mp.getDate().getTime() && mp.meal === 'dinner')
      if (dayMealPlan.length > 0) {
        console.log('Already have plans for ' + dayOfWeek + '. Skipping')
        continue
      }
      const candidates = allRecipes.filter((r) => (r.tags || []).indexOf('Dinner rotation: ' + dayOfWeek) > -1)
      if (candidates.length === 0) {
        console.log('No tagged recipes found for ' + dayOfWeek + '.')
        continue
      }

      console.log(`Choosing which recipe for ${dayOfWeek} from ${candidates.map((dc) => "'" + dc.name + "'")}`)
      if (!dinnerRotationSettings[dayOfWeek]) {
        dinnerRotationSettings[dayOfWeek] = []
      }
      for (const candidate of candidates) {
        if (dinnerRotationSettings[dayOfWeek].indexOf(candidate.id) === -1) {
          console.log(`adding new recipe to settings for ${dayOfWeek}: ${candidate.name} `)
          dinnerRotationSettings[dayOfWeek].push(candidate.id)
        }
      }

      const { chosenRecipe, futureCandidates } = choose(date, allRecipes, dinnerRotationSettings[dayOfWeek])
      if (!chosenRecipe) {
        console.log('No recipes appropriate for ' + dayOfWeek + '.')
        continue
      }
      dinnerRotationSettingsModified = true
      dinnerRotationSettings[dayOfWeek] = futureCandidates

      const mp = new MealPlan({
        id: generateId(),
        day: dates[index].getDate(),
        month: dates[index].getUTCMonth() + 1,
        year: dates[index].getFullYear(),
        meal: 'dinner',
        dishes: [
          {
            type: 'recipe',
            recipeId: chosenRecipe.id,
            recipeName: chosenRecipe.name,
          },
        ],
      })
      window.storageService.store('mealPlan', [mp])
    }

    if (dinnerRotationSettingsModified) {
      setSetting('eatingPlan.dinnerRotation', JSON.stringify(dinnerRotationSettings))
    }
  }

  render() {
    const { props, state } = this
    if (!state.firstDay) return null
    const days = getDatesInWeek(state.firstDay)
    const dayTimestamps = days.map((d) => d.getTime())
    const mealPlans = props.mealPlans.filter((mp) => dayTimestamps.indexOf(mp.getDate().getTime()) > -1)

    return (
      <Swipe swipeRight={() => this.previousWeek()} swipeLeft={() => this.nextWeek()}>
        {days.map((day, index) => {
          return (
            <div key={'meal-plan-' + index}>
              <EatingPlanDayShow
                date={day}
                mealPlans={mealPlans.filter((mp) => mp.day === day.getDate())}
                recipeToAdd={props.recipeToAdd}
              />
              {index !== days.length - 1 && <div className="hr" />}
            </div>
          )
        })}
        <div style={{ marginTop: '20px' }}>
          <button className="button" onClick={(e) => this.previousWeek()}>
            Previous week
          </button>
          &nbsp;
          <button className="button" onClick={(e) => this.nextWeek()}>
            Next week
          </button>
          &nbsp;
          {props.showAutoPlan && (
            <button className="button" style={{ marginRight: '5px' }} onClick={(e) => this.autoPlanMeals()}>
              Auto plan
            </button>
          )}
        </div>
      </Swipe>
    )
  }
}

export default EatingPlanWeekShow
