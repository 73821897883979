import React from 'react'
import notie from 'notie'

import { signup } from '../app/api'
import { setAuthToken } from '../app/auth-helpers'
import { connect } from 'react-redux'

class SignupForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { type: 'event' }
  }

  handleSubmit(state) {
    if (!state.password) {
      notie.alert({ type: 'error', text: 'Password is required', time: 2 })
      return
    }
    if (state.password !== state.passwordConfirm) {
      notie.alert({ type: 'error', text: 'Passwords do not match', time: 2 })
      return
    }

    if (state.password.length < 6) {
      notie.alert({ type: 'error', text: 'Passwords should be at least 6 characters long', time: 2 })
      return
    }

    signup(state.username, state.password).then(
      (res) => {
        setAuthToken(res)
        this.props.authenticated(res)
      },
      (error) => {
        notie.alert({ type: 'error', text: 'Signup failed', time: 2 })
      }
    )
  }

  render() {
    const { props, state } = this

    return (
      <div>
        <form onSubmit={(e) => e.preventDefault() || this.handleSubmit(state)}>
          <div className="field">
            <label className="label">Email</label>
            <div className="control">
              <input
                className="input"
                type="email"
                value={state.username}
                onInput={(e) => e.preventDefault() || this.setState({ username: e.target.value })}
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Password</label>
            <div className="control">
              <input
                className="input"
                type="password"
                value={state.password}
                onInput={(e) => e.preventDefault() || this.setState({ password: e.target.value })}
              />
            </div>
          </div>

          <div className="field">
            <label className="label">Confirm Password</label>
            <div className="control">
              <input
                className="input"
                type="password"
                value={state.passwordConfirm}
                onInput={(e) => e.preventDefault() || this.setState({ passwordConfirm: e.target.value })}
              />
            </div>
          </div>

          <div className="field">
            <label />
            <div className="control">
              <input className="button" type="submit" value="Login" />
            </div>
          </div>
        </form>
      </div>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return {
    authenticated: (token) => {
      dispatch({ type: 'AUTHENTICATED', token })
    },
  }
}

export default connect((state, ownProps) => ({}), mapDispatchToProps)(SignupForm)
