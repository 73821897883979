
const Spinner = (props: any, context: any) => {
  return (<svg width="40px" height="40px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="lds-pacman">
    <g>
    <circle cx="81.8" cy="50" r="4" fill="#5699d2">
    <animate attributeName="cx" calcMode="linear" values="95;35" keyTimes="0;1" dur="1" begin="-0.67s" repeatCount="indefinite"></animate>
    <animate attributeName="fill-opacity" calcMode="linear" values="0;1;1" keyTimes="0;0.2;1" dur="1" begin="-0.67s" repeatCount="indefinite"></animate>
    </circle>
    <circle cx="42.2" cy="50" r="4" fill="#5699d2">
    <animate attributeName="cx" calcMode="linear" values="95;35" keyTimes="0;1" dur="1" begin="-0.33s" repeatCount="indefinite"></animate>
    <animate attributeName="fill-opacity" calcMode="linear" values="0;1;1" keyTimes="0;0.2;1" dur="1" begin="-0.33s" repeatCount="indefinite"></animate>
    </circle>
    <circle cx="62" cy="50" r="4" fill="#5699d2">
    <animate attributeName="cx" calcMode="linear" values="95;35" keyTimes="0;1" dur="1" begin="0s" repeatCount="indefinite"></animate>
    <animate attributeName="fill-opacity" calcMode="linear" values="0;1;1" keyTimes="0;0.2;1" dur="1" begin="0s" repeatCount="indefinite"></animate>
    </circle>
    </g>
    <g transform="translate(-15 0)">
    <path d="M50 50L20 50A30 30 0 0 0 80 50Z" fill="#1d3f72" transform="rotate(40.5 50 50)">
    <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;45 50 50;0 50 50" keyTimes="0;0.5;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
    </path>
    <path d="M50 50L20 50A30 30 0 0 1 80 50Z" fill="#1d3f72" transform="rotate(-40.5 50 50)">
    <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;-45 50 50;0 50 50" keyTimes="0;0.5;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
    </path>
    </g>
    </svg>
  )
}

export default Spinner
