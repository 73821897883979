
const upsert = (state, objects) => {
  const byId = {...state.byId}
  objects.forEach(object => {
    if (object.archived) {
      delete byId[object.id]
    } else {
      byId[object.id] = object
    }
  })
  return {...state, byId}
}

const defaultState = { byId: {} }

const reducer = function(state = defaultState, action) {
  switch (action.type) {
    case 'LOGOUT':
      return defaultState
    case 'RECIPE_VIEWS_FETCHED':
      return upsert(state, action.objects)
    default:
      return state
  }
}

export default reducer
