import { parse } from "./ingredient-parser"

const categorisedIngredients = {
  'Fruit and veg': ['avocado', 'potato', 'broccoli', 'bok choy', 'pumpkin', 'lettuce', 'cucumber', 'tomato', 'onion', 'zucchini', 'spring onions', 'green beans', 'carrots', 'bananas', 'garlic cloves', 'garlic', 'capsicum', 'celery', 'cherry tomatoes', 'spinach', 'silverbeet', 'mushroom', 'red onion', 'sweet potato', 'fresh ginger', 'roma tomato', 'lime', 'lemon'],
  'Health foods': ['almonds', 'cashews'],
  'Meat': ['beef mince', 'sausages', 'steak', 'chicken breast', 'chicken thigh', 'salmon', 'basa', 'basa fish', 'salmon fillets'],
  'Bakery': ['bread', 'raisin toast'],
  'Dairy': ['milk', 'oat milk', 'butter', 'cooking butter', 'cheese', 'cream', 'cooking cream', 'thickened cream', 'yoghurt', 'yogurt', 'sour cream', 'egg yolk', 'egg', 'oat milk', 'oat milk barista blend', 'barista blend', 'cheddar cheese sticks', 'parmesan'],
  'Cereal': ['muesli'],
  'Biscuits and Snacks': ['popcorn', 'shapes', 'corn chips'],
  'Beverages': ['juice'],
  'Jams and spreads': ['peanut butter', 'jam', 'nutella', 'honey'],
  'Pasta, rice, and noodles': ['pasta', 'spaghetti', 'noodles', 'rice', 'jasmin rice'],
  'International food': ['nacho chips', 'taco sauce'],
  'Canned foods': ['beans'],
  'Cooking and seasoning': ['spice', 'stock', 'bbq sauce', 'tomato sauce', 'oil', 'olive oil', 'chicken stock', 'beef stock', 'vegetable stock', 'soy sauce', 'paprika', 'curry powder', 'cinnamon'],
  'Baking': ['brown sugar', 'white sugar', 'icing sugar', 'sugar', 'plain flour', 'self raising flour', 'vanilla', 'flour', 'baking soda', 'choc chips', 'chocolate chips', 'pancake mix', 'cake in a cup'],
  'Medicinal and health': [],
  'Cleaning': [],
  'Frozen food': ['frozen peas', 'frozen corn', 'ice cream', 'pizza', 'funghi pizza', 'frozen pizzas'],
}

const find = (word) => {
  return Object.keys(categorisedIngredients).find(category => {
    return categorisedIngredients[category].indexOf(word) > -1
  })
}

export const removeInstructionsFromIngredient = (ingredient) => {
  return ingredient
    .replace('minced', '')
    .replace('chopped up', '')
    .replace('chopped', '')
    .replace('crushed', '')
    .replace('diced', '')
    .replace('halved', '')
    .replace('sliced', '')
    .replace('to taste', '')
    .replace(',', ' ')
    .trim()
}

export const categories = () => {
  return Object.keys(categorisedIngredients)
}

export const categorise = (ingredient) => {
  let match = find(ingredient.toLowerCase())
  if (match) return match

  try {
    const ia = parse(ingredient.toLowerCase())
    if (ia) {
      let ingred = removeInstructionsFromIngredient(ia.ingredient)
      match = find(ingred)
      if (match) return match
      if (ingred.endsWith('es')) {
        match = find(ingred.substring(0, ingred.length - 2))
        if (match) return match
      }
      if (ingred.endsWith('s')) {
        match = find(ingred.substring(0, ingred.length - 1))
        if (match) return match
      }
    }
  } catch (e) {
    console.error('Could not parse ingredient', e)
  }

  return null
}
