import { utcTime } from "../../js-lib/date-helpers"

class BaseShoppingItem {

  constructor(id, category, userId, lastUpdated, serverLastUpdated, shoppingListId, completedAt, outOfDate, recipeId, recipeName, archived, quantity) {
    this.id = id
    this.category = category
    this.userId = userId
    this.lastUpdated = lastUpdated
    this.serverLastUpdated = serverLastUpdated
    this.shoppingListId = shoppingListId
    this.completedAt = completedAt
    this.outOfDate = outOfDate
    this.recipeId = recipeId
    this.recipeName = recipeName
    this.archived = archived
    this.quantity = quantity
  }

  isCompleted() {
    return !!this.completedAt
  }

  setArchived() {
    this.archived = true
  }

  setCompleted() {
    this.completedAt = utcTime()
  }

  setUncompleted() {
    this.completedAt = null
  }

  getCategory() {
    return this.category
  }
}

export default BaseShoppingItem
