import IngredientAmount from './ingredient-amount'
import {makeUnitSingular, unitWithCorrectPlurality} from './unit-helpers'

const translations = {
  'cup': {
    'litre': (amount) => amount * 250 / 1000,
    'mililitre': (amount) => amount * 250
  },
  'litre': {
    'cup': (amount) => amount / 250 * 1000,
    'mililitre': (amount) => amount * 1000
  },
  'mililitre': {
    'litre': (amount) => amount / 1000
  },
  'quart': {
    'litre': (amount) => amount * 0.94635299994212
  },
  'teaspoon': {
    'tablespoon': (amount) => amount / 4
  },
  'tablespoon': {
    'teaspoon': (amount) => amount * 4
  },
  'gram': {
    'kilogram': (amount) => amount / 1000
  },
  'kilogram': {
    'gram': (amount) => amount * 1000
  },
  'ounce': {
    'gram': (amount) => amount * 28.35
  },
  'pound': {
    'gram': (amount) => amount * 453.592
  }
}

const unitLongToShort = {
  'kilogram': 'kg',
  'gram': 'g',
  'tablespoon': 'tbsp',
  'teaspoon': 'tsp',
  'mililitre': 'ml',
  'miligram': 'mg',
  'litre': 'L',
  'ounce': 'oz',
  'pound': 'lb',
  'packet': 'pkt',
  'piece': 'pcs',
  'pint': 'pt',
}

export const shortUnit = (unit) => {
  const short = unitLongToShort[unit]
  if (short) {return short}

  return unit
}

export const translateUnit = (ingredientAmount, wantedUnit, sensibleUnits = true) => {
  const currentUnit = makeUnitSingular(ingredientAmount.unit)
  const translatedMeasurements = ingredientAmount.measurements.map(m => translations[currentUnit][wantedUnit](m))
  if (sensibleUnits) {
    let {unit, measurements} = makeUnitsSensible(translatedMeasurements, wantedUnit)
    unit = unitWithCorrectPlurality(measurements, unit)
    return new IngredientAmount(measurements, unit, ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes)
  } else {
    return new IngredientAmount(translatedMeasurements, unitWithCorrectPlurality(translatedMeasurements, wantedUnit), ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes)
  }
}

const largest = (values) => {
  return values.reduce((biggest, value) => {
    return value > biggest ? value : biggest
  }, 0)
}
const smallest = (values) => {
  return values.reduce((smallest, value) => {
    return value < smallest ? value : smallest
  }, values[0])
}

export const makeUnitsSensible = (measurements, unit) => {
  let newMeasurements = measurements
  let largestMeasurement = largest(measurements)
  let smallestMeasurement = smallest(measurements)
  let newUnit = makeUnitSingular(unit)

  if (newUnit === 'gram' && smallestMeasurement >= 1000) {
    newMeasurements = newMeasurements.map(m => translations['gram']['kilogram'](m))
    newUnit = 'kilogram'
  }

  if (newUnit === 'kilogram' && largestMeasurement < 1) {
    newMeasurements = newMeasurements.map(m => translations['kilogram']['gram'](m))
    newUnit = 'gram'
  }

  if (newUnit === 'litre' && largestMeasurement < 1) {
    newMeasurements = newMeasurements.map(m => translations['litre']['mililitre'](m))
    newUnit = 'mililitre'
  }

  if (newUnit === 'mililitre' && largestMeasurement >= 1000) {
    newMeasurements = newMeasurements.map(m => translations['mililitre']['litre'](m))
    newUnit = 'litre'
  }

  if (newUnit === 'teaspoon' && smallestMeasurement >= 4) { // TODO: maybe it should stay this way if its say 5
    newMeasurements = newMeasurements.map(m => translations['teaspoon']['tablespoon'](m))
    newUnit = 'tablespoon'
  }

  if (newUnit === 'tablespoon' && smallestMeasurement < 1) { // TODO: maybe it should stay this way if its say 5
    newMeasurements = newMeasurements.map(m => translations['tablespoon']['teaspoon'](m))
    newUnit = 'teaspoon'
  }

  // these are trickier since specific cup/spoon measures are used. It would look funny to combine multiple
  // 1/4 cup = 60ml
  // 1 tablespoon = 20ml
  // 1 teaspoon = 5ml

  return {measurements: newMeasurements, unit: newUnit}
}

export const round = (measurement) => {
  return Math.round(measurement * 100) / 100
}

export const formatFractions = (measurement) => {
  if (measurement === 0.25) {
    return '1/4'
  }
  if (measurement === 0.33) {
    return '1/3'
  }
  if (measurement === 0.5) {
    return '1/2'
  }
  if (measurement === 0.67) {
    return '2/3'
  }
  if (measurement === 0.75) {
    return '3/4'
  }

  return measurement
}

const parseFractions = (measurement) => {
  if (measurement === '1/4') {
    return 0.25
  }
  if (measurement === '1/3') {
    return 0.33
  }
  if (measurement === '1/2') {
    return 0.5
  }
  if (measurement === '2/3') {
    return 0.67
  }
  if (measurement === '3/4') {
    return 0.75
  }

  return measurement
}

export const translateFlour = (ingredientAmount) => {
  // 2 cups of flour in grams = 300g
  // 1 cup flour in grams = 150g
  // ½ (half) a cup of flour in grams = 75g
  // 1/3 cup = 50g
  // ¼ (quarter) of a cup of flour in grams = 40g
  // 1/8 of a cup of flour in grams = 19g

  const result = []
  if (makeUnitSingular(ingredientAmount.unit) === 'gram') {
    if (ingredientAmount.measurements.length === 1 && ingredientAmount.measurements[0] % 75 === 0) {
      const amount = ingredientAmount.measurements[0] / 75 / 2
      result.push(new IngredientAmount([amount], unitWithCorrectPlurality(amount, 'cup'), ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
    } else {
      if (ingredientAmount.measurements.length === 1 && ingredientAmount.measurements[0] % 50 === 0) {
        const amount = ingredientAmount.measurements[0] / 50 / 3
        result.push(new IngredientAmount([amount], unitWithCorrectPlurality(amount, 'cup'), ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
      }
    }
  }
  return result
}

export const translateButter = (ingredientAmount) => {
  // 1 cup butter or margarine 250g
  // 3/4 cup butter or margarine 185g
  // 1/2 cup butter or margarine 125g
  // 1/3 cup butter or margarine 80g
  // 1/4 cup butter or margarine 60g
  // 3 tablespoons butter or margarine 60g
  // I tablespoon butter or margarine 20g
  // 1 teaspoon butter or margarine 5g

  const result = []

  if (makeUnitSingular(ingredientAmount.unit) === 'gram') {
    const largestMeasurement = largest(ingredientAmount.measurements)
    if (largestMeasurement < 61) {
      const tablespoonMeasurements = ingredientAmount.measurements.map(m => m / 20)
      if (largestMeasurement > 19) {
        result.push(new IngredientAmount(tablespoonMeasurements, unitWithCorrectPlurality(tablespoonMeasurements, 'tablespoon'), ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
      } else {
        const teapoonMeasurements = ingredientAmount.measurements.map(m => m / 5)
        result.push(new IngredientAmount(teapoonMeasurements, unitWithCorrectPlurality(teapoonMeasurements, 'teaspoon'), ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
      }
    }
    if (largestMeasurement > 59) {
      const measurements = ingredientAmount.measurements.map(m => {
        return m * 0.004
      })
      result.push(new IngredientAmount(measurements, unitWithCorrectPlurality(measurements, 'cup'), ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
    }
  } else {
    if (makeUnitSingular(ingredientAmount.unit) === 'tablespoon') {
      const measurements = ingredientAmount.measurements.map(m => m * 20)
      result.push(new IngredientAmount(measurements, unitWithCorrectPlurality(measurements, 'gram'), ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
      if (ingredientAmount.measurements.length === 1 && ingredientAmount.measurements[0] === 4) {
        const measurements = ingredientAmount.measurements.map(m => 1/3)
        result.push(new IngredientAmount(measurements, unitWithCorrectPlurality(measurements, 'cup'), ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
      }
    } else {
      if (makeUnitSingular(ingredientAmount.unit) === 'teaspoon') {
        const measurements = ingredientAmount.measurements.map(m => m * 5)
        result.push(new IngredientAmount(measurements, unitWithCorrectPlurality(measurements, 'gram'), ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
      } else {
        if (makeUnitSingular(ingredientAmount.unit) === 'cup') {
          const measurements = ingredientAmount.measurements.map(m => m * 250)
          result.push(new IngredientAmount(measurements, unitWithCorrectPlurality(measurements, 'gram'), ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
        }
      }
    }
  }

  return result
}

const liquidCupsToMillilitres = [
  {cups: 0.25, mls: 60},
  {cups: 0.33, mls: 80},
  {cups: 0.5, mls: 125},
  {cups: 0.67, mls: 170},
  {cups: 0.75, mls: 190},
  {cups: 1, mls: 250},
]

export const translateLiquid = (ingredientAmount) => {
  const result = []

  if (ingredientAmount.measurements.length === 0) return result

  if (makeUnitSingular(ingredientAmount.unit) === 'cup') {
    const mls = []
    ingredientAmount.measurements.forEach(measurement => {
      const cups = parseFractions(measurement)
      const needle = liquidCupsToMillilitres.find(c2m => c2m.cups === cups)
      if (needle) {
        mls.push(needle.mls)
      } else {
        if (Number.isInteger(measurement) && measurement % 1 === 0) {
          mls.push(measurement * 250)
        }
      }
    })

    if (mls.length === ingredientAmount.measurements.length) {
      result.push(new IngredientAmount(mls, 'mililitre', ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
    }
  }

  if (makeUnitSingular(ingredientAmount.unit) === 'tablespoon') {
    const mls = ingredientAmount.measurements.map(m => m * 20)
    result.push(new IngredientAmount(mls, 'mililitre', ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
  }

  if (makeUnitSingular(ingredientAmount.unit) === 'teaspoon') {
    const mls = ingredientAmount.measurements.map(m => m * 5)
    result.push(new IngredientAmount(mls, 'mililitre', ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
  }

  if (makeUnitSingular(ingredientAmount.unit) === 'mililitre') {
    const cups = []
    ingredientAmount.measurements.forEach(measurement => {
      const needle = liquidCupsToMillilitres.find(c2m => c2m.mls === measurement)
      if (needle) {
        cups.push(needle.cups)
      } else {
        if (Number.isInteger(measurement) && measurement % 250 === 0) {
          cups.push(measurement / 250)
        }
      }
    })
    if (cups.length === ingredientAmount.measurements.length) {
      result.push(new IngredientAmount(cups, 'cup', ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, null, ingredientAmount.notes))
    }
  }

  return result
}
