import { ingredientsWithInfo } from './cooking-info'

export default class Recipe {
  constructor(name, intro, ingredients, instructions, notes, serves, preparationTime, cookingTime, source, images, tags, relatedRecipes) {
    this.name = name
    this.intro = intro
    this.ingredients = ingredients
    this.instructions = instructions
    this.notes = notes
    this.serves = serves
    this.preparationTime = preparationTime
    this.cookingTime = cookingTime
    this.source = source
    this.images = images
    this.tags = tags
    this.relatedRecipes = relatedRecipes
  }

  instructionsAsHtml() {
    let html = (this.instructions || '').replace(/^(.*)$/gm, (match, groups) => {
      if (match.trim().length > 0) {
        return '<p className="instruction">' + match + '</p>\n'
      }
      return ''
    })

    ingredientsWithInfo().forEach((ingredient) => {
      html = html.replace(new RegExp('(' + ingredient + ')', 'gi'), '<span className="clickable-text">$1</span>')
    })

    return html
  }
}
