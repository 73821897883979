import { categorise } from './ingredient-categoriser'
import { formatFractions, makeUnitsSensible, round, shortUnit, translateButter, translateFlour, translateLiquid, translateUnit } from './ingredient-amount-translator'
import { makeUnitSingular, unitWithCorrectPlurality } from './unit-helpers'
import { equals } from '../js-lib/array-helpers'

export default class IngredientAmount {
  constructor (measurements, unit, ingredient, alternatives = null, extraUnitInfo = null, notes = null) {
    this.measurements = measurements
    this.unit = unit
    this.ingredient = ingredient
    this.alternativeMeasurementsAndUnits = alternatives
    this.extraUnitInfo = extraUnitInfo;
    this.notes = notes
  }

  setGroup(group) {
    this.group = group
  }

  getReadableUnit(short = false) {
    if (this.unit) {
      let suffix = ''
      if (this.extraUnitInfo && this.extraUnitInfo.unitAdjective) {
        suffix = this.extraUnitInfo.unitAdjective + ' '
      }

      let {measurements, unit} = makeUnitsSensible(this.measurements, this.unit)
      if (short) {
        return suffix + shortUnit(unit);
      }
      return suffix + unitWithCorrectPlurality(measurements, unit);
    } else {
      return this.unit
    }
  }

  getReadableMeasurements() {
    if (this.unit) {
      let {measurements, unit} = makeUnitsSensible(this.measurements, this.unit)
      unit = unitWithCorrectPlurality(measurements, unit)
      return measurements.map(m => formatFractions(round(m, unit)))
    } else {
      return this.measurements
    }
  }

  getIngredientCategory () {
    return categorise(this.ingredient)
  }

  getAlternatives() {
    let result = (this.alternativeMeasurementsAndUnits || []).map(alt => new IngredientAmount(alt[0], alt[1], this.ingredient))

    if (this.unit) {
      if (this.measurements !== null) {
        if (this.ingredient.match(/butter/) || this.ingredient.match(/margarine/)) {
          const additions = translateButter(this)
          additions.forEach(addition => {
            if (!result.find(r => makeUnitSingular(r.unit) === makeUnitSingular(addition.unit))) {
              result = result.concat(addition)
            }
          })
        }
        if (this.ingredient.match(/flour/)) {
          const additions = translateFlour(this)
          additions.forEach(addition => {
            if (!result.find(r => makeUnitSingular(r.unit) === makeUnitSingular(addition.unit))) {
              result = result.concat(addition)
            }
          })
        }
        if (this.ingredient.match(/cream/) || this.ingredient.match(/milk/) || this.ingredient.match(/water/) || this.ingredient.match(/stock/)) {
          const additions = translateLiquid(this)
          additions.forEach(addition => {
            if (!result.find(r => makeUnitSingular(r.unit) === makeUnitSingular(addition.unit))) {
              result = result.concat(addition)
            }
          })
        }
      }
      if (['ounce', 'pound'].indexOf(makeUnitSingular(this.unit)) > -1) {
        if (!result.find(r => makeUnitSingular(r.unit) === 'gram')) {
          result.push(translateUnit(this, 'gram'))
        }
      }
      if (['quart'].indexOf(makeUnitSingular(this.unit)) > -1) {
        if (!result.find(r => makeUnitSingular(r.unit) === 'litre')) {
          result.push(translateUnit(this, 'litre'))
        }
      }
    }
    return result
  }

  matches(other) {
    return equals(other.measurements || [], this.measurements || []) && other.unit === this.unit && other.ingredient === this.ingredient
  }

  oneLiner() {
    return [this.getReadableMeasurements(), this.getReadableUnit(), this.ingredient].filter(i => !!i).join(' ')
  }
}
