import React from 'react'
import { connect } from 'react-redux'
import ShoppingItem from './ShoppingItem'
import { GROCER } from './settingsConstants'
import { findGrocer } from '../../grocer'

class ShoppingItemList extends React.Component {
  render() {
    const { props, state } = this
    return (
      <div>
        {props.items.map((item, index) => {
          let recipe = null
          if (item.recipeId) {
            recipe = props.recipesById[item.recipeId]
          }
          return (
            <ShoppingItem
              key={item.id}
              allShops={props.allShops}
              item={item}
              recipe={recipe}
              allowCategoryEdit={props.allowCategoryEdit}
              isTicked={props.tickedItems.indexOf(item.id) > -1}
              handleItemTick={props.handleItemTick}
              saveItem={props.saveItem}
              deleteItem={props.deleteItem}
              grocer={props.grocer}
            />
          )
        })}
      </div>
    )
  }
}

export default connect((state, ownProps) => {
  let grocer = state.setting.byName[GROCER] ? findGrocer(state.setting.byName[GROCER]) : null
  return {
    recipesById: { ...state.recipe.byId, ...state.sharedRecipe.byId },
    grocer,
  }
})(ShoppingItemList)
