import React from 'react'

class Icon extends React.Component {
  render() {
    const { props } = this
    return (
      <svg width={props.width} height={props.height}>
        <use xlinkHref={'#' + props.id}></use>
      </svg>
    )
  }
}

export default Icon
