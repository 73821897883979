import React from 'react'
import { createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import routes from './app/routes'
import Layout from './app/layout/layout'
import { doInitialLoad } from './app-lifecycle'
import apiMiddleware from './app/apiMiddleware'
import reducers from './app/reducers'
import { register as registerServiceWorker } from './app-shared/service-worker/index'

const store = createStore(reducers, {}, applyMiddleware(apiMiddleware))
doInitialLoad(store.dispatch)

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement)

const router = createBrowserRouter(createRoutesFromElements(routes(<Layout />)))

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
)

registerServiceWorker('Palatable')
