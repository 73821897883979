
const getDetails = (productIds) => {
  return new Promise((resolve, reject) => {
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        resolve(JSON.parse(xmlhttp.responseText).map(data => ({
          id: data.Stockcode,
          name: data.DisplayName,
          price: data.Price,
          pricesLastFetched: new Date().getTime(),
          onSpecial: data.IsOnSpecial,
          previousPrice: data.WasPrice,
        })))
      }
    }
    xmlhttp.open("GET", 'https://www.woolworths.com.au/apis/ui/products/' + productIds.join(','), true);
    xmlhttp.send();
  })
}

const getImageUrl = productId => {
  return 'https://cdn0.woolworths.media/content/wowproductimages/large/' + productId  + '.jpg'
}

const getProductId = url => {
  let result = url.replace('https://www.woolworths.com.au/shop/productdetails/', '')
  const slashIndex = result.indexOf('/')
  if (slashIndex > -1) {
    result = result.substring(0, slashIndex)
  }
  return padWithZeros(result, 6)
}

const padWithZeros = (value, wantedLength) => {
  let result = value.toString()
  while (result.length < wantedLength) {
    result = "0" + result
  }
  return result
}

export default class Woolies {
  getId() {
    return 1
  }
  getName() {
    return 'Woolies';
  }
  isGrocerUrl(url) {
    return url.startsWith('https://www.woolworths.com.au/')
  }
  findProductUrl(words) {
    return 'https://www.woolworths.com.au/shop/search/products?searchTerm=' + encodeURIComponent(words)
  }
  async getLatestPrices(itemIds) {
    return getDetails(itemIds)
  }

  async getProductDetails(url) {
    if (!url.startsWith('https://www.woolworths.com.au/shop/productdetails/')) {
      return null
    }
    const productId = getProductId(url)
    const productDetails = (await getDetails([productId]))[0]
    return {
      id: parseInt(productId),
      image: getImageUrl(productId),
      ...productDetails
    }
  }

}
