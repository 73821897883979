import React, { createRef } from 'react'

import autoComplete from './auto-complete'
import './auto-complete.css'
import {levenshteinDistance} from '../../../../js-lib/levenshtein-distance'
import { v4 as uuidv4 } from 'uuid';

class TagAutoComplete extends React.Component {

  constructor(props) {
    super(props)
    this.state = {uuid: uuidv4(), newTag: '',}
    this.input = createRef()
  }

  componentDidMount() {
    this.autoCompleteInstance = new autoComplete({
      source: (term, callback) => {
        const termLower = term.toLowerCase();
        const matches = this.props.allTags
            .filter((tag) => tag.toLowerCase().indexOf(termLower) > -1)
            .filter((tag) => (this.props.exclusions || []).indexOf(tag) === -1)
            .sort((a,b) => {
              const aDist = levenshteinDistance(termLower, a.toLowerCase())
              const bDist = levenshteinDistance(termLower, b.toLowerCase())
              if (aDist < bDist) {
                return -1;
              }
              if (aDist > bDist) {
                return 1;
              }
              return 0;
            })
        callback(matches)
      },
      cache: 0,
      minChars: 1,
      selector: '.autocomplete' + this.state.uuid,
      onSelect: (event, term, item) => {
        event.preventDefault()
        this.props.onSelect(term, true)
        this.setState({newTag: ''})
      },
      labelField: 'name',
    });
    if (this.props.autoFocus) {
      this.input.current.focus()
    }
  }

  componentWillUnmount() {
    this.autoCompleteInstance.destroy()
  }

  handleKeyPress(instance, event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      instance.props.onSelect(instance.props.value || this.state.newTag, false)
      this.setState({newTag: ''})
    }

    event.stopPropagation()
  }

  render () {
    return (
      <span className="tag-auto-complete" onClick={e => e.stopPropagation()}>
        <input ref={this.input}
          placeholder={this.props.placeholder || "Add tag"} className={'input autocomplete' + this.state.uuid}
          type="text" autoComplete="off" value={this.state.newTag} required={this.props.required}
          onInput={(e) => this.setState({newTag: e.target.value})}
          onKeyPress={(e) => this.handleKeyPress(this, e)}
           />
     </span>
    )
  }
}

export default TagAutoComplete
