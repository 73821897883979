import React, { createRef } from 'react'

import { autoComplete } from '../../js-lib-react/'
import '../../js-lib-react/components/form-components/auto-complete/auto-complete.css'
import './shopping-item-auto-complete.css'
import { levenshteinDistance } from '../../js-lib/levenshtein-distance'
import { v4 as uuid } from 'uuid'

class ShoppingItemAutoComplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = { uuid: uuid(), newTag: '' }
    this.input = createRef()
    this.titleGetter = props.titleGetter || ((i) => i.getTitle() || '')
  }

  componentDidMount() {
    const instance = this
    this.autoCompleteInstance = new autoComplete({
      source: (term, callback) => {
        instance.setState({ searchQuery: term })
        if (term && term.length > 2) {
          const termLower = term.toLowerCase()
          let matches = instance.props.items.filter((i) => (this.titleGetter(i) || '').toLowerCase().indexOf(termLower) > -1)
          matches = matches.sort((a, b) => {
            const aDist = levenshteinDistance(term, this.titleGetter(a))
            const bDist = levenshteinDistance(term, this.titleGetter(b))
            if (aDist > bDist) {
              return 1
            }
            if (aDist < bDist) {
              return -1
            }
            return 0
          })

          callback(matches)
        } else {
          callback([])
        }
      },
      cache: 0,
      minChars: 1,
      selector: '.autocomplete' + this.state.uuid,
      onSelect: (event, value, item) => {
        const id = item.getAttribute('data-item-id')
        const shoppingItem = instance.props.items.find((i) => i.id === id)
        event.preventDefault()
        this.props.onSelect(value, shoppingItem)
        this.setState({ newTag: '' })
      },
      labelField: 'title', // TODO: use the method?
      wantedSuggestionHeight: '78',
      renderAbove: false,
      renderItem: function (item, search) {
        let image = ''
        if (item.image) {
          image = '<img src="' + item.image + '" />'
        }
        // prettier-ignore
        return '<div class="autocomplete-suggestion" data-val="' + instance.titleGetter(item) +
          '" data-item-id="' + item.id + '">' + image + '<div class="search-result-heading">' + instance.titleGetter(item) + '</div></div>'
      },
    })
    if (this.props.autoFocus) {
      this.input.current.focus()
    }
  }

  componentWillUnmount() {
    this.autoCompleteInstance.destroy()
  }

  handleKeyPress(instance, event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (instance.props.value || this.state.newTag) {
        instance.props.onSelect(instance.props.value || this.state.newTag, null)
        this.setState({ newTag: '' })
        instance.input.current.blur()
      }
    }

    event.stopPropagation()
  }

  render() {
    const { props, state } = this
    return (
      <span className="tag-auto-complete" onClick={(e) => e.stopPropagation()}>
        <input
          ref={this.input}
          placeholder={props.placeholder}
          className={'input autocomplete' + this.state.uuid}
          type="text"
          autoComplete="off"
          value={state.newTag}
          required={this.props.required}
          onInput={(e) => this.setState({ newTag: e.target.value })}
          onKeyPress={(e) => this.handleKeyPress(this, e)}
        />
      </span>
    )
  }
}

export default ShoppingItemAutoComplete
