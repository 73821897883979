
class GrocerProduct {

  constructor(id, grocerId, name, url, image, category, grocerProductId, price, onSpecial, previousPrice, shoppingListId, serverLastUpdated, lastUpdated, pricesLastFetched) {
    this.id = id
    this.grocerId = grocerId  // Tells who is the grocer
    this.name = name
    this.url = url
    this.image = image
    this.category = category
    this.grocerProductId = grocerProductId // The id that the grocer uses for the product
    this.price = price
    this.onSpecial = onSpecial
    this.previousPrice = previousPrice
    this.shoppingListId = shoppingListId
    this.serverLastUpdated = serverLastUpdated
    this.lastUpdated = lastUpdated
    this.pricesLastFetched = pricesLastFetched
  }

  // TODO: not sure this belongs on this object -- it shouldn't really be aware of
  // the way it is stored
  toStorageFormat() {
    const {id, grocerId, name, url, image, category, grocerProductId, price, onSpecial, previousPrice, shoppingListId, serverLastUpdated, lastUpdated, pricesLastFetched} = this
    return {id, grocerId, name, url, image, category, grocerProductId, price, onSpecial, previousPrice, shoppingListId, serverLastUpdated, lastUpdated, pricesLastFetched}
  }
}

export default GrocerProduct
