import Woolies from './woolworths'

const grocers = [
  new Woolies()
]

export const getGrocer = (id) => {
 return grocers.find(g => g.getId() === id)
}

export const findGrocer = (name) => {
 return grocers.find(g => g.getName() === name)
}

export const grocerNames = () => {
 return grocers.map(g => g.getName()).sort()
}
