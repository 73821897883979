import React from 'react'
import Icon from '../../app/icon'

class HoverButton extends React.Component {
  render() {
    const { props, state } = this
    return (
      <button onClick={(e) => props.onClick()} className="hover-button" title={props.title}>
        <span className="hover-button-icon">
          <Icon width={24} height={24} id={props.iconId} />
        </span>
      </button>
    )
  }
}

export default HoverButton
