import { extractData } from '../js-lib/auth/jwt-helpers'
import { fetchLatest } from './api'
import { getAuthToken } from './auth-helpers'

const middleware = (store) => (next) => (action) => {
  // Pass all actions through by default
  next(action)

  switch (action.type) {
    case 'LOAD_SHARED_RECIPES':
      loadSharedRecipes(next)
      break

    default:
      break
  }
}

const loadSharedRecipes = async (dispatch) => {
  const authToken = getAuthToken()
  if (!authToken) return

  for (const share of extractData(authToken).recipesSharedWithMe || []) {
    const recipeStorageKey = 'palatable.recipesShared.' + share.sharingUserId + '.recipes'
    const previouslyFetched = localStorage.getItem(recipeStorageKey)
    if (previouslyFetched) {
      dispatch({ type: 'SHARED_RECIPES_FETCHED', objects: JSON.parse(previouslyFetched) })
    }

    let syncedLastUpdated = 0
    const lastUpdatedStorageKey = 'palatable.recipesShared.' + share.sharingUserId + '.lastUpdated'
    const lastUpdated = localStorage.getItem(lastUpdatedStorageKey)
    if (lastUpdated) {
      syncedLastUpdated = parseInt(lastUpdated)
    }

    const latestRecipes = await fetchLatest('recipe', syncedLastUpdated, share.sharingUserId)
    if (latestRecipes.length > 0) {
      dispatch({ type: 'SHARED_RECIPES_FETCHED', objects: latestRecipes })

      let allRecipes = latestRecipes
      if (previouslyFetched) {
        allRecipes = [...JSON.parse(previouslyFetched), ...latestRecipes]
      }
      localStorage.setItem(recipeStorageKey, JSON.stringify(allRecipes))
      const newLastUpdated = Math.max(...latestRecipes.map((r) => r.serverLastUpdated))
      localStorage.setItem(lastUpdatedStorageKey, newLastUpdated)
    }
  }
}

export default middleware
