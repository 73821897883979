
export const sum = (array) => {
  return array.reduce((a, b) => a + b, 0)
}

export const maxValue = (array) => {
  return array.reduce(function(a, b) {
      return Math.max(a, b);
  });
}

export const moveValueInArray = function(array, fromIndex, toIndex) {
  array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
  return array
}

export const choose = (array, chooser) => {
  if (array.length === 0) {
    return null
  }
  var current = array[0]
  array.forEach((item) => {
    current = chooser(current, item)
  })
  return current
}

export const forEachReverse = function(array, callback) {
  var i;
  var len = array.length-1;
  for (i = len; i >= 0; i--) {
      callback(array[i], i, array);
  }
}

export const withoutElementAtIndex = function(array, index) {
  const result = [...array]
  result.splice(index, 1)
  return result
}

export const withoutValue = function(array, value) {
  const index = array.indexOf(value)
  if (index === -1) {
    return array
  }
  return withoutElementAtIndex(array, index)
}

export const withoutValues = function(array, valuesToRemove) {
  return array.filter(i => valuesToRemove.indexOf(i) === -1)
}

export const withValue = function(array, value) {
  const result = [...array]
  result.push(value)
  return result
}

export const withValueAtIndex = function(array, index, value) {
  let result = [...array]
  result.splice(index, 0, value)
  return result
}

export const stringComparator = function(a, b) {
  if (a === null || b === null) {
    return a === null ? -1 : 1
  }
  if (a === undefined || b === undefined) {
    return a === undefined ? -1 : 1
  }
  var aUpper = a.toUpperCase(); // ignore upper and lowercase
  var bUpper = b.toUpperCase(); // ignore upper and lowercase
  if (aUpper < bUpper) {
    return -1;
  }
  if (aUpper > bUpper) {
    return 1;
  }
  return 0;
}

export const mapListById = function(collection) {
  const result = {}
  collection.forEach(item => result[item.id] = item)
  return result
}

export const flattenArrayOfArrays = function(arrayOfArrays) {
  return [].concat.apply([], arrayOfArrays)
}

export const unique = function(array) {
  return [...new Set(array)]
}

export const difference = (a, b) => {
  return [...new Set([...a].filter(x => !b.includes(x)))]
}

export const intersection = function(a, b) {
    var t;
    if (b.length > a.length) {t = b; b = a; a = t} // indexOf to loop over shorter
    return a.filter(e => b.indexOf(e) > -1)
}

export const containsAll = function(needles, haystack) {
  return intersection(needles, haystack).length === haystack.length
  // return needles.every((val) => haystack.includes(val));
}

export const separate = (array, isInGroupOne) => {
  return array.reduce(([pass, fail], elem) => {
    return isInGroupOne(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
  }, [[], []]);
}

export const groupByField = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {})
}

export const groupByFunction = (xs, f) => {
  return xs.reduce((rv, arrayItem) => {
    const key = f(arrayItem)
    if (!(key in rv)) {
      rv[key] = []
    }
    rv[key].push(arrayItem);
    return rv;
  }, {})
}

export const replaceValue = function(array, match, replace) {
  return array.map(item => {
    if (match(item)) {
      return replace(item)
    } else {
      return item
    }
  })
}

export const mapAsync = async (collection, asyncFunction) => {
  return Promise.all(collection.map(asyncFunction))
}

export const equals = (array1, array2) => array1.length === array2.length && array1.every((value, index) => value === array2[index])
