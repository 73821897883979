import React from 'react'
import { produce } from '.'
import { groupByField } from '../js-lib/array-helpers'
import { toTitleCase } from '../js-lib/string-utils'
import { emoji } from '../recipe/emoji'
import './List.css'

export class SeasonalProduceList extends React.Component {
  render() {
    const { props, state } = this
    const produceByType = groupByField(produce, 'type')

    return (
      <div>
        <h1 className="subtitle">Seasonal Produce</h1>
        <small>Current availability in Brisbane</small>
        {Object.keys(produceByType).map((type) => {
          return (
            <div className="seasonal-produce-section">
              <h3>{toTitleCase(type)}</h3>
              <div className="seasonal-produce-items">
                {produceByType[type].map((p) => {
                  const emo = emoji[p.name]
                  return (
                    <div className="seasonal-produce-item">
                      <span>{p.name}</span> {emo && <span dangerouslySetInnerHTML={{ __html: emo }}></span>} -{' '}
                      <span>{p.currentStatus()}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
