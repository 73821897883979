import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import AuthSelector from '../../app/AuthSelector'
import EatingPlanMonthShow from './EatingPlanMonthShow'
import EatingPlanWeekShow from './EatingPlanWeekShow'
import Icon from '../../app/icon'
import { acceptInvite, declineInvite } from '../../shoppingList/api'
import SharedRecipeSelector from '../../recipe/sharedRecipeSelector'
import EatingPlanAdhocShow from './EatingPlanAdhocShow'
import { withRouterParams } from '../../js-lib-react'

class EatingPlanShow extends React.Component {
  render() {
    const { props, state } = this
    const planType = props.settings.view === 'weekly' ? 'Weekly' : props.settings.view === 'monthly' ? 'Monthly' : 'Ad-hoc'

    return (
      <div className="meal-planner">
        <div>
          <Link className="float-right button" to="/mealPlannerSettings">
            <Icon width="16" height="16" id="settings" />
          </Link>
          {/* {props.settings.view === 'weekly' && props.showAutoPlan && <button className="float-right button" style="marginRight: 5px;" onClick={e => autoPlanWeeklyMeals(props.allRecipes, props.mealPlans) }>Auto plan</button>} */}
        </div>
        <h1 className="subtitle">{planType} Meal Planner</h1>
        {props.invites && props.invites.length > 0 && (
          <div style={{ marginBottom: '10px', padding: '10px', borderRadius: '5px', backgroundColor: '#e5795c', color: 'white' }}>
            {props.invites.map((invite) => {
              return (
                <div key={invite.id}>
                  <p>
                    {invite.inviterUsername} has invited you to share a meal plan. If you accept their invitation, your planned items will
                    be merged with theirs. Would you like to share?
                  </p>
                  <button
                    className="button"
                    onClick={(e) => {
                      acceptInvite(invite.id).then((updated) => {
                        window.storageService.sync('invite')
                        window.storageService.removeCacheWatermark('mealPlan').then(() => {
                          // don't await it
                          window.storageService.sync('mealPlan')
                        })
                      })
                    }}
                  >
                    Yes, share meal plan
                  </button>{' '}
                  <button
                    className="button"
                    onClick={(e) => {
                      declineInvite(invite.id).then((updated) => {
                        window.storageService.sync('invite')
                      })
                    }}
                  >
                    No
                  </button>
                </div>
              )
            })}
          </div>
        )}

        {props.settings.view !== 'adhoc' && props.recipeToAdd && (
          <div style={{ marginBottom: '10px', padding: '10px', borderRadius: '5px', backgroundColor: '#73971d', color: 'white' }}>
            <div style={{ float: 'right' }}>
              <Link style={{ color: 'white' }} to={'/mealPlanner'}>
                x
              </Link>
            </div>
            Adding {props.recipeToAdd.name} to your plan. Choose a date and meal below.
          </div>
        )}

        {props.settings.view === 'weekly' && (
          <EatingPlanWeekShow
            mealPlans={props.mealPlans}
            firstDayOfWeek={props.settings.firstDayOfWeek}
            recipeToAdd={props.recipeToAdd}
            allRecipes={props.allRecipes}
            showAutoPlan={props.showAutoPlan}
            dinnerRotationSettings={props.settings.dinnerRotation}
          />
        )}
        {props.settings.view === 'monthly' && <EatingPlanMonthShow mealPlans={props.mealPlans} />}
        {props.settings.view === 'adhoc' && <EatingPlanAdhocShow mealPlans={props.mealPlans} recipeToAdd={props.recipeToAdd} />}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    inviteFetched: (invite) => {
      dispatch({ type: 'INVITES_FETCHED', objects: [invite] })
    },
  }
}

export default withRouterParams(
  connect((state, ownProps) => {
    let recipeToAdd = null
    if (ownProps.params.recipeId) {
      recipeToAdd = state.recipe.byId[ownProps.params.recipeId]
      if (!recipeToAdd) {
        recipeToAdd = new SharedRecipeSelector(state).get(ownProps.params.recipeId)
      }
    }

    const mealPlans = Object.values(state.mealPlan.byId)
    const username = new AuthSelector(state).username()
    return {
      allRecipes: Object.values(state.recipe.byId),
      mealPlans,
      recipeToAdd,
      settings: {
        view: state.setting.byName['eatingPlan.view'],
        firstDayOfWeek: state.setting.byName['eatingPlan.firstDayOfWeek'],
        dinnerRotation: JSON.parse(state.setting.byName['eatingPlan.dinnerRotation'] || '{}'),
      },
      invites: Object.values(state.invite.byId).filter(
        (i) => i.type === 'eatingPlan' && i.archived !== true && i.inviteeUsername === username
      ),
      showAutoPlan: username === 'carwinz@gmail.com' || username === 'rochelle014@gmail.com',
    }
  }, mapDispatchToProps)(EatingPlanShow)
)
