import { deleteDB } from 'idb'
import notie from 'notie'

import { initStorage, objectTypes } from './app/storage'
import { getSettings } from './app/settings'
import { camelToSnakeCase } from './js-lib/string-utils'
import { getAuthToken, removeAuthToken } from './app/auth-helpers'

export const doInitialLoad = async (dispatch) => {
  const requiredDataVersion = 18

  const storage = initStorage()

  const currentDataVersion = localStorage.getItem('palatable_required_data_version')
  if (!currentDataVersion || parseInt(currentDataVersion, 10) < requiredDataVersion) {
    notie.alert({ type: 'warning', text: 'Clearing all data and re-syncing' })
    console.log(`need to clear all data since data version was ${currentDataVersion}, but version ${requiredDataVersion} is required`)
    await clearData()
    localStorage.setItem('palatable_required_data_version', requiredDataVersion)
  }

  // To ensure that we get the defaults, we do the settings separately
  const settings = await getSettings()
  dispatch({ type: 'DEFAULTED_SETTINGS_FETCHED', settingsByName: settings })

  storage.addChangeListener((objectType, objects) => {
    dispatch({ type: objectTypeToFetchedAction(objectType), objects })
  })

  for (let objectType of ['recipeOverride', 'recipe', 'recipeView', 'shoppingItem', 'invite', 'mealPlan', 'setting', 'grocerProduct']) {
    const objects = await storage.fetchImmediatelyAvailable(objectType)
    if (objects.length > 0) {
      dispatch({ type: objectTypeToFetchedAction(objectType), objects })
    }
    // Is de-duplication needed here?
  }

  const authToken = getAuthToken()
  if (authToken) {
    if (!process.env.REACT_APP_SKIP_SYNC) {
      dispatch({ type: 'LOAD_SHARED_RECIPES' })
    }
    await syncData()
  }
}

const syncData = async () => {
  if (process.env.REACT_APP_SKIP_SYNC) {
    console.log('Skipping data sync')
    return
  }
  for (let objectType of objectTypes) {
    await window.storageService.sync(objectType)
  }
}

const objectTypeToFetchedAction = (objectType) => {
  return camelToSnakeCase(objectType).toUpperCase() + 'S_FETCHED'
}

export const doLogout = async () => {
  removeAuthToken()
  await clearData()
}

const clearData = async () => {
  // TODO: prompt for what to do with non-sync'd data
  await window.storageService.removeAllSynced()

  localStorage.removeItem('palatable_required_data_version')
  localStorage.removeItem('palatable_clean_data')
  localStorage.removeItem('palatable.kitchen_recent_views')
  localStorage.removeItem('palatable_eating_plan_last_update')
  localStorage.removeItem('palatable_meal_plan_last_update')
  localStorage.removeItem('palatable_recipes_last_update')
  localStorage.removeItem('palatable_shopping_items_last_update')
  localStorage.removeItem('palatable_shopping_item_last_update')
  localStorage.removeItem('palatable.recipe.temperatureUnit')

  await deleteDB('palatable-eatingPlan')
  await deleteDB('palatable-mealPlan')
  await deleteDB('palatable-recipes')
  await deleteDB('palatable-shoppingList')
  await deleteDB('palatable.kitchen')
}

window.addEventListener('load', () => {
  function handleNetworkChange(event) {
    if (navigator.onLine) {
      syncData()
      notie.alert({ type: 'success', text: 'Your device is back online!' })
    } else {
      notie.alert({ type: 'error', text: 'Your device seems to be offline' })
    }
  }
  window.addEventListener('online', handleNetworkChange)
  window.addEventListener('offline', handleNetworkChange)
})
