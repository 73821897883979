import BaseSelector from '../app/BaseSelector'
import GrocerShoppingItem from './domain/grocerShoppingItem'
import TextualShoppingItem from './domain/textualShoppingItem'

class ShoppingItemSelector extends BaseSelector {
  notCompleted = () => this.loadAndCreateObjects(i => !i.completedAt)
  all = () => this.loadAndCreateObjects()

  loadAndCreateObjects(filter) {
    let items = Object.values(this.state.shoppingItem.byId)
    if (filter) {
      items = items.filter(filter)
    }
    items = items.map(item => {
      if (item.grocerProductId) {
        return new GrocerShoppingItem(item, this.state.grocerProduct.byId[item.grocerProductId])
      } else {
        return new TextualShoppingItem(item)
      }
    })
    return items
  }
}

export default ShoppingItemSelector
