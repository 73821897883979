import React from 'react'

class Modal extends React.Component {
  render() {
    const { props, state } = this
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={(e) => props.onClose()}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{props.title}</p>
          </header>
          <section className="modal-card-body">{props.children || <p dangerouslySetInnerHTML={{ __html: props.details }}></p>}</section>
          <footer className="modal-card-foot"></footer>
        </div>
      </div>
    )
  }
}

export default Modal
