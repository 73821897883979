
const defaultState = { byId: {} }

const reducer = function(state = defaultState, action) {
  switch (action.type) {
    case 'LOGOUT':
      return defaultState
    case 'INVITES_FETCHED':
      const nextById = {...state.byId}
      for (let object of action.objects) {
        if (object.archived) {
          delete nextById[object.id]
        } else {
          nextById[object.id] = object
        }
      }
      return { ...state, byId: nextById }
    default:
      return state
  }
}

export default reducer
