import notie from 'notie'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export function register(appName) {
  serviceWorkerRegistration.register({
    onSuccess: () => { },
    onUpdate: () => { }
  })

}
