var parseJwt = function(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

export const extractData = (token) => {
  var decoded = parseJwt(token)
  return decoded.data
}
