import BaseSelector from '../app/BaseSelector'
import { unique } from '../js-lib/array-helpers'

class RecipeSelector extends BaseSelector {
  tags = () => {
    let allTags = []
    Object.values(this.state.recipe.byId).forEach(recipe => {
      if (recipe.tags) {
        allTags = allTags.concat(recipe.tags)
      }
    })
    return unique(allTags)
  }
  allTags = () => {
    let allTags = this.tags()
    Object.values(this.state.sharedRecipe.byId).forEach(recipe => {
      if (recipe.tags) {
        allTags = allTags.concat(recipe.tags)
      }
    })
    return unique(allTags)
  }
}

export default RecipeSelector
