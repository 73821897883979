import React from 'react'
import { withNavigation } from '../../js-lib-react'
import { withoutValue, withValue } from '../../js-lib/array-helpers'
import HoverButton from '../../recipe/ui/HoverButton'
import MealPlanEdit from './MealPlanEdit'

import MealPlanShow from './MealPlanShow'

class EatingPlanAdhocShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mealPlansBeingEdited: [],
      showAddForm: false,
    }
  }

  render() {
    const { props, state } = this
    const mealPlans = (props.mealPlans || []).filter((mp) => !mp.day)
    return (
      <div>
        {state.showAddForm && (
          <div>
            <MealPlanEdit mealPlan={{ meal: 'dinner', dishes: [] }} onFinished={(e) => this.setState({ showAddForm: false })} />
          </div>
        )}
        {props.recipeToAdd && (
          <div>
            <MealPlanEdit
              mealPlan={{ meal: 'dinner', dishes: [{ type: 'recipe', recipeId: props.recipeToAdd.id }] }}
              onFinished={(e) => this.props.navigate('/mealPlanner')}
            />
          </div>
        )}
        {mealPlans.length === 0 && <div>No meals planned</div>}
        {mealPlans.map((mealPlan, index) => {
          const beingEdited = state.mealPlansBeingEdited.indexOf(mealPlan.id) > -1
          return (
            <div key={'meal-plan-' + mealPlan.id}>
              {!beingEdited && (
                <div
                  onClick={(e) => this.setState({ mealPlansBeingEdited: withValue([...new Set(state.mealPlansBeingEdited)], mealPlan.id) })}
                >
                  <MealPlanShow
                    mealPlan={mealPlan}
                    onTick={(e) => {
                      e.stopPropagation()
                      mealPlan.archived = true
                      window.storageService.store('mealPlan', [mealPlan])
                    }}
                  />
                </div>
              )}
              {beingEdited && (
                <MealPlanEdit
                  mealPlan={mealPlan}
                  onFinished={(e) => {
                    this.setState({ mealPlansBeingEdited: withoutValue(state.mealPlansBeingEdited, mealPlan.id) })
                  }}
                />
              )}
              {index !== mealPlans.length - 1 && <div className="hr" />}
            </div>
          )
        })}

        <div className="hover-button-wrapper">
          <HoverButton title="Add Meal" iconId="add" onClick={(e) => this.setState({ showAddForm: true })} />
        </div>
      </div>
    )
  }
}

export default withNavigation(EatingPlanAdhocShow)
