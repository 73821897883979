import { useDispatch } from 'react-redux'
import notie from 'notie'

import { authenticate } from '../app/api'
import { setAuthToken } from '../app/auth-helpers'
import { AuthenticationForm } from '../js-lib-react'

const AuthenticationFormWrapper = () => {
  const dispatch = useDispatch()
  return (
    <AuthenticationForm
      onSubmit={async (username: string, password: string) => {
        try {
          const token = await authenticate(username, password)
          setAuthToken(token)
          dispatch({ type: 'AUTHENTICATED', token })
        } catch (e) {
          notie.alert({ type: 'error', text: 'Login failed', time: 2 })
        }
      }}
    />
  )
}

export default AuthenticationFormWrapper
