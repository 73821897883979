// a = available
// b = best
// q = quality risk
// r = risk of short supply or high prices

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

class SeasonalProduce {

  constructor(data) {
    this.name = data.name;
    this.weekData = data.weekData;
    this.type = data.type;
  }

  _positionToMonthAndWeek(position) {
    return {month: Math.floor(position / 4), week: (position % 4) + 1}
  }

  summarisePositions(positions) {
    const monthAndWeeks = positions.map(this._positionToMonthAndWeek)

    const groups = []
    let last = null

    monthAndWeeks.forEach(monthAndWeek => {
      if (!last) {
        groups.push([monthAndWeek])
      } else {
        if ((monthAndWeek.month === last.month && monthAndWeek.week - 1 === last.week) || (last.week === 4 && monthAndWeek.week === 1 && monthAndWeek.month - 1 === last.month)) {
          groups[groups.length - 1].push(monthAndWeek)
        } else {
          groups.push([monthAndWeek])
        }
      }
      last = monthAndWeek
    })
    const summaries = groups.map(group => {
      const startMonthAndWeek = group[0]
      let start = months[startMonthAndWeek.month]
      if (startMonthAndWeek.week !== 1) {
        start = this._weekDescription(startMonthAndWeek.week) + ' ' + start
      }

      const endMonthAndWeek = group[group.length-1]
      let end = months[endMonthAndWeek.month]
      if (endMonthAndWeek.week !== 4) {
        end = this._weekDescription(endMonthAndWeek.week) + ' ' + end
      }
      if (start === 'Jan' && end === 'Dec') {
        return 'all year'
      }
      if (start !== end) {
        return start + " to " + end
      } else {
        return start
      }
    })

    return this._mergeEndsOfYear(summaries)
  }

  _mergeEndsOfYear(summaries) {
    let result = [...summaries]
    const startOfYearText = 'Jan to '
    const endOfYearText = ' to Dec'
    const startOfYearIndex = result.findIndex(s => s.startsWith(startOfYearText))
    const endOfYearIndex = result.findIndex(s => s.endsWith(endOfYearText))

    if (result[startOfYearIndex] !== 'Jan to Dec' && endOfYearIndex > -1 && startOfYearIndex > -1) {
      const merged = result[endOfYearIndex].replace(endOfYearText, '') + ' to ' + result[startOfYearIndex].replace(startOfYearText, '')
      if (endOfYearIndex > startOfYearIndex) {
        result.splice(endOfYearIndex, 1)
        result.splice(startOfYearIndex, 1)
      } else {
        result.splice(startOfYearIndex, 1)
        result.splice(endOfYearIndex, 1)
      }
      result.push(merged)
    }

    return result
  }

  _positionsOf(types) {
    const positions = []
    Array.from(this.weekData).forEach((wd, index) => {
      if (types.includes(wd)) {
        positions.push(index)
      }
    })
    return positions
  }

  _weekDescription(weekNumber) {
    if (weekNumber === 1) {
      return 'start of'
    }
    if (weekNumber === 2) {
      return '2nd week of'
    }
    if (weekNumber === 3) {
      return 'mid'
    }
    if (weekNumber === 4) {
      return 'last week'
    }
  }

  bestTimeOfYear() {
    return this.summarisePositions(this._positionsOf(['b']))
  }

  goodSupply() {
    return this.summarisePositions(this._positionsOf(['a','b']))
  }

  nextAvailable() {
    const na = this._positionsOf(['a','b'])
    return na.length > 0 ? months[this._positionToMonthAndWeek(na[0]).month] : 'unknown'
  }

  _positionForDate(date) {
    let week = 0
    if (date.getDate() < 7) {
      week = 1
    } else {
      if (date.getDate() < 15) {
        week = 2
      } else {
        if (date.getDate() < 22) {
          week = 3
        } else {
          week = 4
        }
      }
    }
    return (date.getMonth()) * 4 + week
  }

  currentStatus() {
    const pos = this._positionForDate(new Date())
    const current = this.weekData[pos-1]
    if (current === ' ') return 'Not available until ' + this.nextAvailable()
    if (current === 'b') return 'Best supply and quality (' + this.bestTimeOfYear() + ')'
    if (current === 'a') return 'Available (' + this.goodSupply() + ')'
    if (current === 'q') return 'Available but check quality (better in ' + this.goodSupply() + ')'
    if (current === 'r') return 'Possible short supply or high price'
    return current
  }
}

const data = [
  new SeasonalProduce({name: 'apple',        weekData: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaarrrrrrrr', type: 'fruit'}),
  new SeasonalProduce({name: 'banana',       weekData: 'aaaaaaaaaaaaaaaaaaaaaaaarrrrrrrraaaaaaaaaaaaaaaa', type: 'fruit'}),
  new SeasonalProduce({name: 'blood orange', weekData: '                        aaaaaaaaaaaaaaa         ', type: 'fruit'}),
  new SeasonalProduce({name: 'blueberry',    weekData: 'aaaaaaaaaaaarrrrrrrrrrrraaaabbbbbbbbbbbbbbbbbaaa', type: 'fruit'}),
  new SeasonalProduce({name: 'cherry',       weekData: 'aaaarrrr                              rrrrbbbbbb', type: 'fruit'}),
  new SeasonalProduce({name: 'raspberry',    weekData: 'aaaaaaaaaaaarrrrrrrrrrrrrrrrrrrrrrrrrrrrrraaaaaa', type: 'fruit'}),
  new SeasonalProduce({name: 'strawberry',   weekData: 'qqqqqqqqrrrrrrrrrrrraaaaaaaabbbbbbbbaaaaaaaaaaaa', type: 'fruit'}),
  new SeasonalProduce({name: 'watermelon',   weekData: 'bbbbbbbbaaaaaaaaaaaarrrrrrrrrrrraaaaaaaaaaaaaaaa', type: 'fruit'}),
  new SeasonalProduce({name: 'beans',        weekData: 'aaaaaaaaaaaaaaaaaaaaaarrrrrrrraaaaaaaaaaaaaaaaaa', type: 'veggie'}),
  new SeasonalProduce({name: 'broccoli',     weekData: 'aaaaaaaaaaaaaaaaaaaaaaaarrrraaaaaaaaaaaaaaaaaaaa', type: 'veggie'}),
  new SeasonalProduce({name: 'carrot',       weekData: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', type: 'veggie'}),
  new SeasonalProduce({name: 'capcisum',     weekData: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', type: 'veggie'}),
  new SeasonalProduce({name: 'celery',       weekData: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', type: 'veggie'}),
  new SeasonalProduce({name: 'corn',         weekData: 'bbbbaaaaaaaaaaaaaaaaaaaarrrrrrrraaaaaaaaaaaabbbb', type: 'veggie'}),
  new SeasonalProduce({name: 'cucumber',     weekData: 'bbbbaaaaaaaaaaaaaaaarrrrrrrrrrrrrrrraaaabbbbbbbb', type: 'veggie'}),
  new SeasonalProduce({name: 'potato',       weekData: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', type: 'veggie'}),
  new SeasonalProduce({name: 'pumpkin',      weekData: 'bbbbbbbbbbbbaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', type: 'veggie'}),
  new SeasonalProduce({name: 'sweet potato', weekData: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', type: 'veggie'}),
  new SeasonalProduce({name: 'lettuce',      weekData: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', type: 'veggie'}),
  new SeasonalProduce({name: 'zucchini',     weekData: 'aaaaaaaaaaaaaaaaaaaaaaaarrrrrrrrrrrraaaaaaaaaaaa', type: 'veggie'}),
]

// data.forEach(d => {
//   // console.log(d.name, d.bestTimeOfYear(), d.goodSupply(), d.currentStatus())
//   console.log(d.name + ' - ' + d.currentStatus())

// })

export const produce = data
