import React from 'react'
import { connect } from 'react-redux'
import { categories } from '../../recipe/ingredient-categoriser'
import { findGrocer } from '../index'
import { GROCER } from '../../shoppingList/ui/settingsConstants'
import { generateId } from '../../js-lib/storage/idGenerator'
import GrocerProduct from '../../shoppingList/domain/grocerProduct'
import { formatCurrency } from '../../js-lib/currency-formatting'
import { withRouterParams, withNavigation } from '../../js-lib-react'

class GrocerProductEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.extractStateFromProps(props)
  }

  componentWillReceiveProps(newProps) {
    this.setState(this.extractStateFromProps(newProps))
  }

  extractStateFromProps(props) {
    return {
      id: props.product ? props.product.id : null,
      name: props.product ? props.product.name : null,
      url: props.product ? props.product.url : null,
      image: props.product ? props.product.image : null,
      category: props.product ? props.product.category : null,
      grocerProductId: props.product ? props.product.grocerProductId : null,
      price: props.product ? props.product.price : null,
      onSpecial: props.product ? props.product.onSpecial : null,
      previousPrice: props.product ? props.product.previousPrice : null,
      shoppingListId: props.product ? props.product.shoppingListId : null,
      serverLastUpdated: props.product ? props.product.serverLastUpdated : null,
      lastUpdated: props.product ? props.product.lastUpdated : null,
      pricesLastFetched: props.product ? props.product.pricesLastFetched : null,
    }
  }

  loadProductDetails(url) {
    this.props.grocer.getProductDetails(url).then((details) => {
      const { id, name, image, price, onSpecial, previousPrice, pricesLastFetched } = details
      this.setState({ name, image, grocerProductId: id, price, onSpecial, previousPrice, pricesLastFetched })
    })
  }

  async handleSave() {
    const grocerProduct = new GrocerProduct(
      this.state.id || generateId(),
      this.props.grocer.getId(),
      this.state.name,
      this.state.url,
      this.state.image,
      this.state.category,
      this.state.grocerProductId,
      this.state.price,
      this.state.onSpecial,
      this.state.previousPrice,
      this.state.shoppingListId,
      this.state.serverLastUpdated,
      this.state.lastUpdated,
      this.state.pricesLastFetched
    )

    await window.storageService.store('grocerProduct', [grocerProduct.toStorageFormat()])

    this.props.navigate('/grocer/products/list')
  }

  handleCancel() {
    this.props.navigate('/grocer/products/list')
  }

  render() {
    const { props, state } = this
    if (!this.props.grocer) return null

    return (
      <div className="shopping-list">
        <h1 className="subtitle">{this.props.id ? 'Edit' : 'Add'} Product</h1>
        <form onSubmit={(e) => this.handleSave()} style={{ margin: '10px 0' }}>
          <div className="field is-horizontal">
            <label className="field-label">URL</label>
            <div className="field-body">
              <input
                type="text"
                className="input"
                value={state.url}
                onInput={(e) => e.preventDefault() || this.setState({ url: e.target.value })}
              />
              <button
                className="button"
                style={{ marginLeft: '5px' }}
                onClick={(e) => e.preventDefault() || this.loadProductDetails(state.url)}
              >
                Load
              </button>
            </div>
          </div>

          <div className="field is-horizontal">
            <label className="field-label">Name</label>
            <div className="field-body">
              <input
                type="text"
                className="input"
                value={state.name}
                onInput={(e) => e.preventDefault() || this.setState({ name: e.target.value })}
              />
            </div>
          </div>

          <div className="field is-horizontal">
            <label className="field-label">Category</label>
            <div className="field-body">
              <div className="select">
                <select value={state.category} onChange={(e) => this.setState({ category: e.target.value })}>
                  <option value=""></option>
                  {categories()
                    .sort()
                    .map((cat) => {
                      return (
                        <option key={cat} value={cat}>
                          {cat}
                        </option>
                      )
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <label className="field-label">Current price</label>
            <div className="field-body">{formatCurrency('AUD', state.price * 100)}</div>
          </div>

          <div className="field is-horizontal">
            <label className="field-label">Image</label>
            <div className="field-body">{state.image && <img alt="product" width="200px" src={state.image} />}</div>
          </div>

          <div className="field is-horizontal">
            <label className="field-label"></label>
            <div className="field-body">
              <button className="button margin-top margin-right" type="submit" onClick={(e) => e.preventDefault() || this.handleSave()}>
                Save
              </button>
              <button className="button margin-top margin-right is-warning" type="button" onClick={(e) => this.handleCancel()}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default withNavigation(
  withRouterParams(
    connect((state, ownProps) => {
      const productId = ownProps.params.id
      const grocer = state.setting.byName[GROCER] ? findGrocer(state.setting.byName[GROCER]) : null
      const product = productId ? state.grocerProduct.byId[productId] : {}
      return { grocer, product }
    }, mapDispatchToProps)(GrocerProductEdit)
  )
)
