import { intersection } from "../js-lib/array-helpers"

const seasons = ['Summer', 'Winter', 'Autumn', 'Spring']

const isAppropriate = (date, recipe) => {
  if (intersection(recipe.tags || [], seasons).length > 0) {  // Has a rule for a particular season
    const month = date.getMonth() + 1
    if (recipe.tags.indexOf('Summer') > -1 && [12, 1, 2].indexOf(month) > -1) return true
    if (recipe.tags.indexOf('Autumn') > -1 && [3, 4, 5].indexOf(month) > -1) return true
    if (recipe.tags.indexOf('Winter') > -1 && [6, 7, 8].indexOf(month) > -1) return true
    if (recipe.tags.indexOf('Spring') > -1 && [9, 10, 11].indexOf(month) > -1) return true
    return false
  }
  return true
}

export const choose = (date, allRecipes, candidateRecipeIds) => {
  let considered = []
  let chosenRecipe = null
  while (!chosenRecipe && candidateRecipeIds.length > 0) {
    let candidateRecipeId = candidateRecipeIds[0]
    candidateRecipeIds = candidateRecipeIds.slice(1)
    considered.push(candidateRecipeId)

    const candidateRecipe = allRecipes.find(r => r.id === candidateRecipeId)
    if (isAppropriate(date, candidateRecipe)) {
      chosenRecipe = candidateRecipe
    }
  }

  return {
    chosenRecipe,
    futureCandidates: [...candidateRecipeIds, ...considered],
  }
}
