
  export const resizeAndRotate = (file) => {
    return new Promise((keep, reneg) => {
      const reader = new FileReader()
      reader.onloadend = function() {
        var tempImg = new Image()
        tempImg.src = reader.result
        tempImg.onload = async function() {
          var MAX_WIDTH = 800;
          var MAX_HEIGHT = 800;

          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext("2d")
          const orientation = await determineOrientation(file)
          console.log('orientation', orientation)

          if (orientation > -1 && orientation < 9) {
            if (4 < orientation && orientation < 9) {
              canvas.width = tempImg.height;
              canvas.height = tempImg.width;
            } else {
              canvas.width = tempImg.width;
              canvas.height = tempImg.height;
            }

            switch (orientation) {
              case 2: ctx.transform(-1, 0, 0, 1, tempImg.width, 0); break;
              case 3: ctx.transform(-1, 0, 0, -1, tempImg.width, tempImg.height); break;
              case 4: ctx.transform(1, 0, 0, -1, 0, tempImg.height); break;
              case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
              case 6: ctx.transform(0, 1, -1, 0, tempImg.height, 0); break;
              case 7: ctx.transform(0, -1, -1, 0, tempImg.height, tempImg.width); break;
              case 8: ctx.transform(0, -1, 1, 0, 0, tempImg.width); break;
              default: break;
            }

            ctx.drawImage(this, 0, 0)
          } else {
            canvas.width = tempImg.width
            canvas.height = tempImg.height
            ctx.drawImage(this, 0, 0, tempImg.width, tempImg.height)
          }
          // console.log(canvas.width, canvas.height)

          const resizeCanvas = document.createElement('canvas')
          if (canvas.width > canvas.height) {
            if (canvas.width > MAX_WIDTH) {
              console.log(MAX_WIDTH / canvas.width)
              resizeCanvas.height = canvas.height * (MAX_WIDTH / canvas.width)
              resizeCanvas.width = MAX_WIDTH;
            } else {
              resizeCanvas.height = canvas.height
              resizeCanvas.width = canvas.width
            }
          } else {
            if (canvas.height > MAX_HEIGHT) {
               resizeCanvas.width = canvas.width * (MAX_HEIGHT / canvas.height)
               resizeCanvas.height = MAX_HEIGHT;
            } else {
              resizeCanvas.height = canvas.height
              resizeCanvas.width = canvas.width
            }
          }
          // console.log(resizeCanvas.width, resizeCanvas.height)
          resizeCanvas.getContext('2d').drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, resizeCanvas.width, resizeCanvas.height)
          keep(resizeCanvas.toDataURL("image/jpeg"))
        }
       }
       reader.readAsDataURL(file)
     })
  }

export const b64ToUint8Array = (b64Image) => {
   var img = atob(b64Image.split(',')[1]);
   var img_buffer = [];
   var i = 0;
   while (i < img.length) {
      img_buffer.push(img.charCodeAt(i));
      i++;
   }
   return new Uint8Array(img_buffer);
 }

export const determineOrientation = (file) => {
  return new Promise((keep, reneg) => {
    var fileReader = new FileReader();
    fileReader.onloadend = function () {
      var scanner = new DataView(fileReader.result);
      var idx = 0;
      var orientation = 1; // Non-rotated is the default
      if (fileReader.result.length < 2 || scanner.getUint16(idx) != 0xFFD8) { // eslint-disable-line
          // Not a JPEG
          keep(orientation)
          return;
      }
      idx += 2;
      var maxBytes = scanner.byteLength;
      var littleEndian = false;
      while (idx < maxBytes - 2) {
        var uint16 = scanner.getUint16(idx, littleEndian);
        idx += 2;
        switch (uint16) {
          case 0xFFE1: // Start of EXIF
            var endianNess = scanner.getUint16(idx + 8);
            // II (0x4949) Indicates Intel format - Little Endian
            // MM (0x4D4D) Indicates Motorola format - Big Endian
            if (endianNess === 0x4949) {
                littleEndian = true;
            }
            var exifLength = scanner.getUint16(idx, littleEndian);
            maxBytes = exifLength - idx;
            idx += 2;
            break;
          case 0x0112: // Orientation tag
            // Read the value, its 6 bytes further out
            // See page 102 at the following URL
            // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
            orientation = scanner.getUint16(idx + 6, littleEndian);
            maxBytes = 0; // Stop scanning
            break;
          default:
            break;
        }
      }
      keep(orientation)
    }
    fileReader.readAsArrayBuffer(file);
  })
}
