
export const emoji = {
  tomato: '&#127813;',
  garlic: '&#129476;',
  mushroom: '&#127812;',
  butter: '&#129480;',
  egg: '&#129370;',
  cheese: '&#129472;',
  parmesan: '&#129472;',
  broccoli: '&#129382;',
  cucumber: '&#129362;',
  avocado: '&#129361;',
  banana: '&#127820;',
  lemon: '&#127819;',
  apple: '&#127822;',
  'red apple': '&#127822;',
  'green apple': '&#127823;',
  grape: '&#127815;',
  cherry: '&#127826;',
  'kiwi fruit': '&#129373;',
  blueberry: '&#129744;',
  strawberry: '&#127827;',
  watermelon: '&#127817;',
  potato: '&#129364;',
  bacon: '&#129363;',
  beef: '&#129385;',
  bread: '&#127838;',
  carrot: '&#129365;',
  corn: '&#127805;',
  capsicum: '&#129745;',
  chicken: '&#127831;',
  'fresh basil': '&#127807;',
  lamb: '&#129385;',
  mango: '&#129389;',
  melon: '&#127816;',
  pea: '&#129755;',
  peach: '&#127825;',
  pear: '&#127824;',
  pineapple: '&#127821;',
  rice: '&#127834;',
  salt: '&#129474;',
  'sweet potato': '&#127840;',
}  // https://emojiguide.org/food-and-drink

export const findEmoji = (str) => {
  const haystack = str.toLowerCase()

  const result = []
  for (let [ingredient, entityCode] of Object.entries(emoji)) {
    const expression = ".*\\b" + ingredient + "\\b.*"
    if (new RegExp(expression).test(haystack)) {
      result.push({ingredient, entityCode})
    }
  }
  return result
}
