import { _handleError, HeadersBuilder } from '../app/api-helpers'
const API = process.env.REACT_APP_API_URL || 'https://api.palatable.kitchen'

export const uploadImage = (file) => {
  const formData = new FormData()
  formData.append('image', file)

  return fetch(`${API}/api/recipe/image`, {
    method: 'POST',
    body: formData,
    headers: new HeadersBuilder().auth().build(),
    mode: 'cors',
  }).then((res) => {
    if (res.status !== 200) {
      throw new Error('Upload failed')
    }
    return res.text()
  }, _handleError)
}

export const addRemoteImage = (url) => {
  return fetch(`${API}/api/recipe/image`, {
    method: 'PUT',
    headers: new HeadersBuilder().auth().json().build(),
    mode: 'cors',
    body: JSON.stringify({ url }),
  }).then((res) => {
    if (res.status !== 200) {
      throw new Error('Upload failed')
    }
    return res.text()
  }, _handleError)
}
