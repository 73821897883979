import React from 'react'
import { Link } from 'react-router-dom'
import HoverButton from './HoverButton'
import Edit from './Edit'
import { withNavigation } from '../../js-lib-react'

class Intro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddForm: false,
    }
  }

  render() {
    const { props, state } = this
    return (
      <div>
        {!state.showAddForm && (
          <div className="content-container">
            <h1 className="subtitle">Welcome!</h1>

            <p>
              <i>palatable.kitchen</i> is a personal recipe manager. Built to be as helpful as possible without getting in your way.
            </p>

            <p>In addition to common recipe management, it can also:</p>
            <ul>
              <li>Convert measurements to your preferred format</li>
              <li>Change the number of serves in a recipe</li>
              <li>Help you plan your meals</li>
              <li>Unify ingredients and instructions so that you don't have to keep looking backwards and forth.</li>
              <li>Let you read your recipes even when you're offline</li>
              <li>It plays nice. No ads. No collecting data about you.</li>
            </ul>

            <p>
              If you want to try it without signing up, just click the big plus button below. We recommend{' '}
              <Link to="/signup">signing up</Link> after you've tried it so that your recipes are viewable on multiple devices and won't get
              lost if your browser decides to clean things up.
            </p>

            <p>Enjoy!</p>

            <div className="hover-button-wrapper">
              <HoverButton title="Add Recipe" iconId="add" onClick={(e) => this.setState({ showAddForm: true })} />
            </div>
          </div>
        )}

        {state.showAddForm && (
          <div>
            <h1 className="subtitle">Add recipe</h1>
            <Edit
              recipe={{ tags: [] }}
              allTags={[]}
              onFinished={(recipe) => {
                if (recipe) {
                  this.props.navigate('/recipe/' + recipe.id)
                } else {
                  this.setState({ showAddForm: false })
                }
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

export default withNavigation(Intro)
