
export default class RawRecipe {
  constructor(id, name, intro, ingredients, instructions, notes, serves, preparationTime, cookingTime, source, images, tags, relatedRecipes, serverLastUpdated = null) {
    this.id = id
    this.name = name
    this.intro = intro
    this.ingredients = ingredients
    this.instructions = instructions
    this.notes = notes
    this.serves = serves
    this.preparationTime = preparationTime
    this.cookingTime = cookingTime
    this.source = source
    this.images = images
    this.tags = tags
    this.relatedRecipes = relatedRecipes
    this.serverLastUpdated = serverLastUpdated
  }
}
