import React from 'react'
import { connect } from 'react-redux'
import { categories, removeInstructionsFromIngredient } from '../ingredient-categoriser'
import notie from 'notie'

class AddToShopping extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: props.ingredients.map((ingredient) => ingredient.getIngredientCategory()),
    }
  }

  handleAdd() {
    const items = this.props.ingredients.map((i, index) => {
      const results = {
        title: removeInstructionsFromIngredient(i.oneLiner()),
        recipeId: this.props.recipeId,
        recipeName: this.props.recipeName,
        quantity: 1,
      }
      if (this.state.categories[index]) {
        results.category = this.state.categories[index]
      }
      return results
    })

    window.storageService.store('shoppingItem', items).then((e) => {
      this.props.onClose()
    })
    notie.alert({ type: 'success', text: 'Successfully added ingredients to your shopping list.' })
  }

  render() {
    const { props, state } = this
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={(e) => props.onClose()}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Add to Shopping List</p>
          </header>
          <section className="modal-card-body">
            {props.isAllIngredients && <p>Add all ingredients to your grocery list?</p>}
            {props.isAllIngredients && <small>You can select just a few by ticking them before hitting the shopping cart button</small>}
            {!props.isAllIngredients && (
              <p>
                Add {props.ingredients.length} ingredient{props.ingredients.length > 1 ? 's' : ''} to your grocery list?
              </p>
            )}
            {props.showCategories &&
              props.ingredients.map((ingredient, index) => {
                return (
                  <div key={index} className="columns is-mobile">
                    <div className="column">{removeInstructionsFromIngredient(ingredient.oneLiner())}</div>
                    <div className="column">
                      <div className="select">
                        <div className="select">
                          <select
                            value={state.categories[index]}
                            onChange={(e) => {
                              const updatedCategories = [...state.categories]
                              updatedCategories[index] = e.target.value
                              this.setState({ categories: updatedCategories })
                            }}
                          >
                            <option value=""></option>
                            {categories()
                              .sort()
                              .map((cat) => {
                                return (
                                  <option key={cat} value={cat}>
                                    {cat}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </section>
          <footer className="modal-card-foot">
            <button style={{ marginLeft: 'auto' }} className="button is-primary" onClick={(e) => this.handleAdd()}>
              Add
            </button>
          </footer>
        </div>
      </div>
    )
  }
}

export default connect((state, ownProps) => {
  return {
    showCategories: !!state.setting.byName['shoppingList.groupByCategory'],
  }
})(AddToShopping)
