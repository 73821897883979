
const tokenName = 'palatable.kitchen_auth_token'

export const getAuthToken = () => localStorage.getItem(tokenName)

export const setAuthToken = (token) => localStorage.setItem(tokenName, token)

export const isAuthenticated = () => !!getAuthToken()

export const removeAuthToken = () => localStorage.removeItem(tokenName)
