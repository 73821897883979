
const defaultState = { byName: {} }

const reducer = function(state = defaultState, action) {
  switch (action.type) {
    case 'LOGOUT':
      return defaultState
    case 'DEFAULTED_SETTINGS_FETCHED':
      return {...state, byName: action.settingsByName }
    case 'SETTINGS_FETCHED':
      const nextByName = {...state.byName}
      for (let object of action.objects) {
        nextByName[object.name] = object.value
      }
      return { ...state, byName: nextByName }
    default:
      return state
  }
}

export default reducer
