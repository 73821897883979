import React from 'react'
import { connect } from 'react-redux'
import notie from 'notie'

import AuthSelector from '../../app/AuthSelector'
import { Spinner, TagAutoComplete, withNavigation } from '../../js-lib-react/'
import { stringComparator, withValue, withoutValue } from '../../js-lib/array-helpers'
import { uploadImage, addRemoteImage } from '../api'
import { resizeAndRotate } from './images'
import './Edit.css'
import Icon from '../../app/icon'
import { toTitleCase } from '../../js-lib/string-utils'
import RecipeAutoComplete from './RecipeAutoComplete'

const IMAGE_API = process.env.IMAGE_API_URL || 'https://images.palatable.kitchen'

const fields = [
  'id',
  'name',
  'intro',
  'ingredients',
  'instructions',
  'notes',
  'serves',
  'source',
  'relatedRecipes',
  'images',
  'lastUpdated',
  'serverLastUpdated',
  'tags',
  'archived',
  'cookingTime',
  'preparationTime',
]

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = fields.reduce((result, current) => {
      result[current] = props.recipe[current]
      return result
    }, {})
    if (this.state.images === undefined || this.state.images === null) {
      this.state.images = []
    }
    if (this.state.relatedRecipes === undefined || this.state.relatedRecipes === null) {
      this.state.relatedRecipes = []
    }
    this.state.imageUrl = ''

    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleKeyPress(event) {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault()
      this.handleSave()
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress, false)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false)
  }

  async uploadFile(file) {
    if (file) {
      this.setState({ uploadInProgress: true })
      const base64Image = await resizeAndRotate(file)

      uploadImage(new Blob([base64Image], { type: 'image/jpg' }))
        .then((checksum) => {
          this.setState({ images: withValue(this.state.images, { id: checksum }), uploadInProgress: false })
        })
        .catch((e) => {
          console.log('Image upload failed', e)
          this.setState({ uploadInProgress: false })
          notie.alert({ type: 'error', text: 'Unable to upload image' })
        })
    }
  }

  async handleSave(thenGo = null) {
    if (this.state.imageUrl) {
      this.setState({ imageFetchInProgress: true })
      const hash = await addRemoteImage(this.state.imageUrl)
      this.setState({ images: withValue(this.state.images, { id: hash }), imageFetchInProgress: false, imageUrl: '' })
    }

    const recipe = fields.reduce((result, field) => {
      const value = this.state[field]
      if (value) {
        result[field] = value
      }
      return result
    }, {})
    await window.storageService.store('recipe', [recipe])

    this.props.onFinished(recipe)
    if (thenGo) {
      this.props.navigate(thenGo)
    }
  }

  onArchive() {
    const response = window.confirm('Archive this recipe?')
    if (response) {
      this.setState({ archived: true })
      // avoid race condition with state being updated
      setTimeout(() => {
        this.handleSave('/')
      })
    }
  }

  render() {
    const { props, state } = this
    return (
      <div>
        <form onSubmit={(e) => this.handleSave()}>
          <div className="field is-horizontal">
            <label className="field-label">Name</label>
            <div className="field-body">
              <input
                type="text"
                className="input"
                value={state.name}
                onInput={(e) => e.preventDefault() || this.setState({ name: e.target.value })}
              />
              {state.name && state.name.length > 1 && state.name.toUpperCase() === state.name && (
                <button className="button" onClick={(e) => this.setState({ name: toTitleCase(state.name) })}>
                  Titlecase
                </button>
              )}
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label">Summary</label>
            <div className="field-body">
              <textarea
                className="textarea"
                rows="2"
                value={state.intro}
                onInput={(e) => e.preventDefault() || this.setState({ intro: e.target.value })}
              />
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label">Ingredients</label>
            <div className="field-body">
              <textarea
                className="textarea"
                value={state.ingredients}
                onInput={(e) => e.preventDefault() || this.setState({ ingredients: e.target.value })}
              />
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label">Instructions</label>
            <div className="field-body">
              <textarea
                className="textarea"
                value={state.instructions}
                onInput={(e) => e.preventDefault() || this.setState({ instructions: e.target.value })}
              />
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label">Serves</label>
            <div className="field-body">
              <input
                type="text"
                className="input"
                style={{ maxWidth: '100px' }}
                value={state.serves}
                onInput={(e) => e.preventDefault() || this.setState({ serves: e.target.value })}
              />
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label">Preparation Time</label>
            <div className="field-body">
              <input
                type="text"
                className="input"
                style={{ maxWidth: '100px' }}
                value={state.preparationTime}
                onInput={(e) => e.preventDefault() || this.setState({ preparationTime: e.target.value })}
              />
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label">Cooking Time</label>
            <div className="field-body">
              <input
                type="text"
                className="input"
                style={{ maxWidth: '100px' }}
                value={state.cookingTime}
                onInput={(e) => e.preventDefault() || this.setState({ cookingTime: e.target.value })}
              />
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label">Tags</label>
            <div className="field-body">
              <TagAutoComplete
                placeholder=""
                allTags={props.allTags}
                exclusions={state.tags}
                onSelect={(text) => this.setState({ tags: withValue(state.tags, text) })}
              />
              &nbsp;
              {state.tags.sort(stringComparator).map((tag) => {
                return (
                  <button
                    key={tag}
                    className="button marginRight"
                    onClick={(e) => e.preventDefault() || this.setState({ tags: withoutValue(state.tags, tag) })}
                  >
                    {tag}
                  </button>
                )
              })}
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label">Source</label>
            <div className="field-body">
              <input
                type="text"
                className="input"
                value={state.source}
                onInput={(e) => e.preventDefault() || this.setState({ source: e.target.value })}
              />
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label">Related recipes</label>
            <div className="field-body">
              <RecipeAutoComplete
                items={props.recipes.filter((r) => r.id !== this.state.id)}
                onSelect={(ignore, r) => this.setState({ relatedRecipes: withValue(state.relatedRecipes, r.id) })}
              />
              {state.relatedRecipes.map((relatedRecipeId) => {
                const relatedRecipe = props.recipes.find((r) => r.id === relatedRecipeId)
                if (!relatedRecipe) return null
                return (
                  <button
                    className="button marginRight"
                    onClick={(e) =>
                      e.preventDefault() || this.setState({ relatedRecipes: withoutValue(state.relatedRecipes, relatedRecipeId) })
                    }
                  >
                    {relatedRecipe.name}
                  </button>
                )
              })}
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label">Upload Image</label>
            <div className="field-body">
              <input className="button" type="file" name="file" onChange={(e) => this.uploadFile(e.target.files[0])} />
              {state.uploadInProgress && <Spinner />}
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label">Image from URL</label>
            <div className="field-body">
              <input
                type="text"
                className="input"
                value={state.imageUrl}
                onInput={(e) => e.preventDefault() || this.setState({ imageUrl: e.target.value })}
              />
              {state.imageFetchInProgress && <Spinner />}
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label"></label>
            <div className="field-body">
              {state.images.map((image) => {
                return (
                  <div
                    className="clickable remove-image"
                    onClick={(e) => {
                      const response = window.confirm('Remove this image?')
                      if (response) {
                        this.setState({ images: state.images.filter((i) => i.id !== image.id) })
                      }
                      e.preventDefault()
                    }}
                  >
                    <Icon width="16" height="16" id="delete" />
                    <img src={IMAGE_API + '/800x800/' + image.id + '.jpg'} alt="Recipe" width="150px" />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="field is-horizontal">
            <label className="field-label"></label>
            <div className="field-body">
              <button className="button margin-top margin-right" type="submit" onClick={(e) => e.preventDefault() || this.handleSave()}>
                Save
              </button>
              <button className="button margin-top margin-right is-warning" type="button" onClick={(e) => this.props.onFinished()}>
                Cancel
              </button>
              {state.id && (
                <button className="button margin-top margin-right is-danger" type="button" onClick={(e) => this.onArchive()}>
                  Archive
                </button>
              )}
            </div>
          </div>
        </form>
        <div className="hover-button-wrapper">
          <button onClick={(e) => this.handleSave()} className="hover-button">
            <span className="hover-button-icon">
              <Icon width="16" height="16" id="save" />
            </span>
          </button>
        </div>
      </div>
    )
  }
}

export default withNavigation(
  connect((state, ownProps) => {
    let recipes = Object.values(state.recipe.byId)

    return {
      recipes,
      username: new AuthSelector(state).username(),
    }
  })(Edit)
)
