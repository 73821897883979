// @ts-ignore
import { BaseSyntheticEvent } from "react";
import DateInput from "./DateInput";

export function StyledCheckBox(props: any) {
  return (
    <StyledInput {...props}>
      <input type="checkbox"
        checked={props.value}
        onChange={(e: BaseSyntheticEvent) => {
          props.onChange(e.target.checked)
        }} />
    </StyledInput>
  )
}

export function StyledTextArea(props: any) {
  return (
    <StyledInput {...props}>
      <textarea className="textarea" style={{ 'minHeight': props.minHeight || '200px' }} value={props.value} onInput={(e: BaseSyntheticEvent) => {
        if (props.onInput) {
          props.onInput(e)
        } else {
          e.preventDefault();
          props.onChange(e.target.value)
        }
      }} />
    </StyledInput>
  )
}

export function StyledDateInput(props: any) {
  return (
    <StyledInput {...props}>
      {/* @ts-ignore */}
      <DateInput value={props.value} onDateSelect={async (newValue) => {
        props.onChange(newValue)
      }} />
    </StyledInput>
  )
}

export function StyledTextBox(props: any) {
  return (
    <StyledInput {...props}>
      <input type={props.fieldType || "text"} className="input" value={props.value}
        onInput={(e: BaseSyntheticEvent) => {
          e.preventDefault();
          props.onChange(e.target.value)
        }} />
    </StyledInput>
  )
}

export function StyledInput(props: any) {
  return (
    <div className={"field " + (props.vertical ? '' : 'is-horizontal')}>
      <label className="field-label">{props.label}</label>
      <div className="field-body">{props.children}</div>
    </div>
  )
}

export function SubmitButton(props: any) {
  return (
    <input className="button" type="submit" value={props.buttonText || "Save"} />
  )
}

export function StyledControlButtons(props: any) {
  return (
    <StyledInput {...props}>
      <SubmitButton />&nbsp;
      {props.onClose && <input className="button" type="button" value="Cancel" onClick={(e) => props.onClose()} />}&nbsp;
      {props.onArchive && <input className="button is-warning" type="button" value="Archive" onClick={e => {
        e.preventDefault()
        if (window.confirm("Do you really want to archive?")) {
          props.onArchive()
        }
      }} />}
      {props.children}
    </StyledInput>
  )
}
