import React from 'react'
import { ingredientsWithInfo, infoForIngredient } from '../recipe/cooking-info'

class CookingInfoList extends React.Component {
  render() {
    const { props, state } = this
    return (
      <div>
        {ingredientsWithInfo().map((ingredient) => {
          const info = infoForIngredient(ingredient)
          return (
            <div key={ingredient} className="list-item">
              <div className="columns is-mobile">
                <div className="column">
                  <p className="emphasised">{ingredient}</p>
                  <p className="less-important" dangerouslySetInnerHTML={{ __html: info.description }}></p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default CookingInfoList
