import { _verifyResponse, _handleError, HeadersBuilder } from './api-helpers.js'
const API = process.env.REACT_APP_API_URL || 'https://api.palatable.kitchen'

export function authenticate(username, password) {
  return fetch(`${API}/authenticate`, {
    method: 'POST',
    body: JSON.stringify({ username: username, password: password }),
    headers: new HeadersBuilder().json().build(),
    mode: 'cors',
  }).then((res) => {
    if (res.status !== 200) {
      throw new Error('Login failed')
    }
    return res.text()
  }, _handleError)
}

export function signup(username, password) {
  return fetch(`${API}/signup`, {
    method: 'POST',
    body: JSON.stringify({ username: username, password: password }),
    headers: new HeadersBuilder().json().build(),
    mode: 'cors',
  }).then((res) => {
    if (res.status !== 200) {
      throw new Error('Login failed')
    }
    return res.text()
  }, _handleError)
}

export function reissue() {
  return fetch(`${API}/reissue`, {
    method: 'POST',
    body: '{}',
    headers: new HeadersBuilder().auth().text().build(),
    mode: 'cors',
  }).then((res) => {
    if (res.status !== 200) {
      throw new Error('Reissue failed')
    }
    return res.text()
  }, _handleError)
}

export const store = (objectType, object) => {
  return fetch(`${API}/api/${objectType}`, {
    headers: new HeadersBuilder().auth().json().build(),
    mode: 'cors',
    method: 'POST',
    body: JSON.stringify(object),
  }).then(_verifyResponse, _handleError)
}

export function fetchLatest(objectType, updatedAfter, userIdOverride = null) {
  let headers = new HeadersBuilder().auth().json()
  if (userIdOverride) {
    headers = headers.userOverride(userIdOverride)
  }
  return fetch(`${API}/api/${objectType}?updatedAfter=${updatedAfter}`, {
    headers: headers.build(),
    mode: 'cors',
  }).then(_verifyResponse, _handleError)
}
