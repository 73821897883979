/*
A storage service.

Responsibilities:
* stores things in the browser until it is able to store it remotely
* interacts with the backend and fetches just the deltas
* allows for things to be archived
* handles many types of objects to reduce duplication

TODO:
* needs to handle when sync fails

Assumptions:
* all items have an id and a lastUpdated field
* archived = true means an item exists on the backend, but should be removed from the frontend

*/
import { openDB } from 'idb'
import { generateId } from './idGenerator';
import { utcTime } from '../date-helpers'

export default class StorageService {

  constructor(objectTypes, isAuthenticated, api, database, options = {}) {
    this.changeListeners = []
    this.objectTypes = objectTypes
    this._isAuthenticated = isAuthenticated
    this.api = api
    this.database = database
    this.options = options
    this._inProgressSyncLocalToRemote = {}
  }

  addChangeListener(cl) {
    this.changeListeners.push(cl)
  }

  async sync(objectType) {
    if (!this.options.allowSyncWhenNoAuth && !this._isAuthenticated()) return
    const db = await this._initIndexedDb()
    await this._syncLocalToRemote(db, objectType)
    await this._syncRemoteToLocal(db, objectType)
  }

  async store(objectType, objects, localOnly = false) {
    const db = await this._initIndexedDb()
    for (let object of objects) {
      if (!object.id) {
        object.id = generateId()
      }
      object.lastUpdated = utcTime()
      await this._storeInIndexedDb(db, this._notSyncedTable(objectType), object)
      await this._removeFromIndexedDb(db, this._syncedTable(objectType), object.id)
    }

    // Tell listeners something has changed. We will notify them again after the backend sync is
    // completed, but it's important to do it here to cater for when we're offline, etc
    this.changeListeners.forEach(changeListener => {
      changeListener(objectType, objects)
    })

    if (!localOnly) {
      setTimeout(() => {
        // don't wait for this; it hampers the UI experience
        this._syncLocalToRemote(db, objectType).then(() => {
          // We've pushed out our changes, but there might be other changes between our lastUpdated
          // watermark and the updated date of the most recent change. Fetch everything
          this._syncRemoteToLocal(db, objectType)
          // TODO: a better way to do this would be to pass the lastUpdated date to the server and have it
          // return everything along that's new as well as the newly create/updated item
        })
      })
    }
  }

  async fetchImmediatelyAvailable(objectType) {
    const db = await this._initIndexedDb()
    // TODO: what if they are in both??? do we remove from the sync table when an update is in progress?
    const synced = (await db.getAll(this._syncedTable(objectType))).map(row => row.object)
    const notSynced = (await db.getAll(this._notSyncedTable(objectType))).map(row => row.object)
    return synced.concat(notSynced).filter(o => o && !o.archived)
  }

  async removeAllSynced() {
    const db = await this._initIndexedDb()
    for (let objectType of this.objectTypes) {
      await db.clear(this._syncedTable(objectType))
    }
  }

  async resetAndSync(objectType) {
    const db = await this._initIndexedDb()
    await db.clear(this._syncedTable(objectType))
    await db.clear(this._notSyncedTable(objectType))
    await this._syncRemoteToLocal(db, objectType)
  }

  async removeCacheWatermark(objectType) {
    const db = await this._initIndexedDb()
    await db.delete('lastUpdated', objectType)
  }

  _syncedTable = (table) => table + '-synced'

  _notSyncedTable = (table) => table + '-not-synced'

  _initIndexedDb = async () => {
    if (!('indexedDB' in window)) {
      throw new Error('This browser doesn\'t support IndexedDB');
    }

    const name = await this.database.generateName()
    const me = this
    return openDB(name, this.database.version, {
      upgrade(db, currentVersion, newVersion) {
        const range = []
        for (let i = currentVersion + 1; i <= newVersion; i++) {
          range.push(i)
        }

        if (range.indexOf(1) > -1) {
          db.createObjectStore('lastUpdated', { keyPath: 'name' })
        }

        me.database.migrate(db, currentVersion, newVersion, me._syncedTable, me._notSyncedTable)
      }
    })
  }

  async _syncRemoteToLocal(db, objectType) {
    if (!this.options.allowSyncWhenNoAuth && !this._isAuthenticated()) return
    let lastUpdated = await this._getLastUpdatedWatermark(db, objectType)

    if (lastUpdated > 0) {
      if ((await db.count(this._syncedTable(objectType))) === 0 && (await db.count(this._notSyncedTable(objectType))) === 0) {
        console.log(`Cached data for ${objectType} seemed to have disappeared. Re-fetching everything`)
        lastUpdated = 0
      }
    }

    const objects = await this.api.fetchLatest(objectType, lastUpdated)
    for (let object of objects) {
      await this._updateIndexedDb(db, objectType, object)
    }
    await this._updateLastUpdatedWatermark(db, objectType, objects, lastUpdated)

    // send changes to the UI; but don't send the latest version if we have a local conflict that hasn't synced.
    const syncConflicts = (await db.getAll(this._notSyncedTable(objectType))).map(row => row.object).filter(o => o.outOfDate)
    const syncConflictIds = syncConflicts.map(c => c.id)
    const objectsNotConflicted = objects.filter(o => syncConflictIds.indexOf(o.id) === -1)
    this.changeListeners.forEach(changeListener => {
      changeListener(objectType, objectsNotConflicted)
    })
  }

  /* Assuming that any archived items have already been removed from the redux store */
  async _syncLocalToRemote(db, objectType) {
    if (!this.options.allowSyncWhenNoAuth && !this._isAuthenticated()) return

    if (objectType in this._inProgressSyncLocalToRemote) {
      console.log('Skipping because a sync is already in progress')
      return
    }
    this._inProgressSyncLocalToRemote[objectType] = true

    try {
      const objectsToSync = (await db.getAll(this._notSyncedTable(objectType))).map(row => row.object)
      if (objectsToSync.length > 0) {
        console.log(`Found ${objectsToSync.length} ${objectType} to sync`)

        let objectsUpdateResponse = await this.api.storeRemote(objectType, objectsToSync)
        for (let objectUpdateResponse of objectsUpdateResponse) {
          if (objectUpdateResponse.outcome === 'updated') {
            let remoteUpdates = objectUpdateResponse.item
            if (!Array.isArray(remoteUpdates)) {  // recipeViews can return multiple
              remoteUpdates = [remoteUpdates]
            }
            for (let remoteUpdate of remoteUpdates) {
              await this._updateIndexedDb(db, objectType, remoteUpdate)
              await this._removeFromIndexedDb(db, this._notSyncedTable(objectType), remoteUpdate.id)  // TODO: there is a chance this will over-write conflicted data
            }

            this.changeListeners.forEach(changeListener => {
              changeListener(objectType, remoteUpdates)
            })
          }
          // Note: it is wrong for us to update the watermark here because there might be other items between
          // our watermark and the item we've just updated.
        }
        for (let objectUpdateResponse of objectsUpdateResponse) {
          if (objectUpdateResponse.outcome === 'failed') {
            const isOptimisticLockingError = objectUpdateResponse.errors.indexOf('Already have a newer version') > -1
            if (isOptimisticLockingError) {
              const updatedItem = {...objectUpdateResponse.item, outOfDate: true}
              await this._storeInIndexedDb(db, this._notSyncedTable(objectType), updatedItem)
              this.changeListeners.forEach(changeListener => {
                changeListener(objectType, [updatedItem])
              })
            } else {
              console.error('Unable to sync object with remote', objectUpdateResponse.item, 'errors', objectUpdateResponse.errors)
            }
          }
        }
      }
    } catch (e) {
      console.error('Unable to sync with remote', e)
    } finally {
      delete this._inProgressSyncLocalToRemote[objectType]
    }
  }

  async _getLastUpdatedWatermark(db, objectType) {
    let lastUpdated = await db.get('lastUpdated', objectType)
    lastUpdated = lastUpdated ? lastUpdated.value : 0
    return lastUpdated
  }

  async _updateLastUpdatedWatermark(db, objectType, objects, shouldBeAfter) {
    let newLastUpdated = shouldBeAfter
    for (let object of objects) {
      // migrating to serverLastUpdated since lastUpdated is generated locally and there
      // may be a delay in syncing that with the server
      const objectLastUpdated = object.serverLastUpdated || object.lastUpdated
      if (objectLastUpdated > newLastUpdated) {
        newLastUpdated = objectLastUpdated
      }
    }
    if (newLastUpdated > shouldBeAfter) {
      await db.put('lastUpdated', { name: objectType, value: newLastUpdated })
    }
  }

  async _updateIndexedDb(db, objectType, object) {
    if (object.archived !== true) {
      await this._storeInIndexedDb(db, this._syncedTable(objectType), object)
    } else {
      await this._removeFromIndexedDb(db, this._syncedTable(objectType), object.id)
    }
  }

  async _storeInIndexedDb(db, table, object) {
    if (!object) {
      console.error(table, object)
      throw new Error("Object must be provided")
    }
    if (!object.id) {
      console.error(table, object)
      throw new Error("Object id must be provided")
    }
    await db.put(table, { id: object.id, object })
  }

  async _removeFromIndexedDb(db, table, objectId) {
    await db.delete(table, objectId)
  }
}
