// React router 6 only supports hooks which won't work in Components.
// this sits between the router and components to inject them.
// https://stackoverflow.com/a/69967902

import { useParams } from "react-router-dom";

const withRouterParams = WrappedComponent => props => {
  const params = useParams();
  return (
    <WrappedComponent
      {...props}
      params={params}
    />
  );
};

export default withRouterParams
