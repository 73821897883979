const milisecondsInDay = 24 * 60 * 60 * 1000
const milisecondsInWeek = milisecondsInDay * 7
const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
export const daysOfWeek = days

export const addUnit = (startDateInMiliseconds, unit, quantity) => {
  if (unit === "days") {
    return startDateInMiliseconds + (quantity * milisecondsInDay)
  }

  if (unit === "weeks") {
    return startDateInMiliseconds + (quantity * milisecondsInWeek)
  }

  if (unit === "months") {
    return addMonths(new Date(startDateInMiliseconds), quantity).getTime()
  }
}

export const parseIsoDateTime = (isostr) => {
  // "2014-12-20T22:10:00+0100"
  var parts = isostr.match(/\d+/g);
  return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
}

export const parseIsoDate = (isostr) => {
  var parts = isostr.match(/\d+/g);
  return new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0)
}

export const dayOfWeek = (date) => {
  return days[date.getDay()]
}

export const shortDayOfWeek = (date) => {
  return days[date.getDay()].substring(0,3)
}

export const monthsDifferent = (d1, d2) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

export const daysBetween = (firstMilliseconds, lastMilliseconds) => {
  return Math.round((lastMilliseconds - firstMilliseconds) / (24 * 60 * 60 * 1000))
}

export const daysDifferent = (long1, long2) => {
  var date1 = new Date(long1);
  var date2 = new Date(long2);
  var timeDiff = Math.abs(date2.getTime() - date1.getTime());
  var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays
}

export const startOfMonth = (date) => {
  const d = setDay(new Date(date.getTime()), 1)
  return zeroTime(d)
}

export const startOfWeek = (date) => {
  let result = new Date(date.getTime())
  while (result.getDay() !== 0) {
    result = addDays(result, -1)
  }
  return result
}

/**
 * @param {int} The month number
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */
export const getDatesInMonth = (month, year) => {
     var date = new Date(year, month - 1, 1);
     var days = [];
     while (date.getMonth() === month -1) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
     }
     return days;
}

export const getDatesInWeek = (firstDayOfWeekDate) => {
     var days = [];
     for (var i=0; i<7; i++) {
        days.push(addDays(firstDayOfWeekDate, i));
     }
     return days;
}

export const buildDate = (year, month, day) => {
  const d = zeroTime(new Date())
  d.setDate(day)
  d.setMonth(month - 1)
  d.setYear(year)
  return d
}

export const getMonth = (date) => {
  return date.getMonth() + 1
}

export const getYear = (date) => {
  return date.getYear() + 1900
}

export const utcTime = () => {
  const now = new Date()
  return now.getTime() + now.getTimezoneOffset() * 60 * 1000
}

export const zeroTime = (date) => {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

export const setMonth = (date, month) => {
  date.setMonth(month - 1)
  return date
}

export const setDay = (date, dayOfMonth) => {
  date.setDate(dayOfMonth)
  return date
}

export const addDays = (date, days) => {
  const d = new Date(date.valueOf());
  d.setDate(d.getDate() + days);
  return d;
}

export const addDaysToEpoch = (epoch, days) => {
  return epoch + (days * 24 * 60 * 60 * 1000)
}

export const addMonth = (date) => {
  date.setMonth(date.getMonth() + 1)
  return date
}

export const addMonths = (date, months) => {
  let result = new Date(date.getTime())
  result.setMonth(date.getMonth() + months)
  return result
}

export const addYears = (date, years) => {
  let result = new Date(date.getTime())
  result.setFullYear(date.getFullYear() + years)
  return result
}

export const addWeeks = (date, weeks) => {
  let result = new Date(date.getTime())
  result.setDate(result.getDate() + (weeks * 7));
  return result
}

export const addMinutes = (date, minutes) => {
  date.setMinutes(date.getMinutes() + minutes)
  return date
}

export const addMilliseconds = (date, ms) => {
  date.setMilliseconds(date.getMilliseconds() + ms)
  return date
}

export const timezoneOffsetInMillis = date => {
  return date.getTimezoneOffset() * 60 * 1000
}


export const thisFinancialYear = (now = new Date()) => {
  let start = zeroTime(now)
  if (start.getMonth() + 1 < 7) {
    start = addYears(start, -1)
  }
  start = setMonth(start, 7)
  start = setDay(start, 1)
  return {start: start, end: addDays(addYears(start, 1), -1)}
}

export const lastFinancialYear = () => {
  const fy = thisFinancialYear()
  return {start: addYears(fy.start, -1), end: addYears(fy.end, -1)}
}

export const padWithTwoZeros = (value) => {
  let result = value.toString()
  if (result.length === 1) {
    return "0" + result
  }
  return result
}
