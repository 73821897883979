import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Icon from '../../app/icon'
import { groupByFunction, separate } from '../../js-lib/array-helpers'
import { acceptInvite, declineInvite } from '../api'
import { categories, categorise } from '../../recipe/ingredient-categoriser'
import AuthSelector from '../../app/AuthSelector'
import ShoppingItemList from './ShoppingItemList'
import { findGrocer } from '../../grocer/index'
import ShoppingItemAutoComplete from './ShoppingItemAutoComplete'
import { GROCER, GROUP_BY_CATEGORY } from './settingsConstants'
import { generateId } from '../../js-lib/storage/idGenerator'
import ShoppingItemSelector from '../selector'
import GrocerShoppingItem from '../domain/grocerShoppingItem'
import GrocerProduct from '../domain/grocerProduct'
import TextualShoppingItem from '../domain/textualShoppingItem'
import GrocerProductSelector from '../../grocer/selector'

class ShoppingListLocalSettings {
  constructor() {
    let settings = localStorage.getItem('palatable.shoppingListSettings')
    this.settings = settings ? JSON.parse(settings) : {}
  }

  get(name) {
    return this.settings[name]
  }

  set(name, value) {
    if (value) {
      this.settings[name] = value
    } else {
      delete this.settings[name]
    }
    localStorage.setItem('palatable.shoppingListSettings', JSON.stringify(this.settings))
  }
}

class ShoppingList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newItemTitle: '',
      completedMode: false,
      tickedItems: [],
    }
  }

  async addItem(itemTitle) {
    let item = null
    if (itemTitle.startsWith('https://')) {
      const url = itemTitle
      if (this.props.grocer && this.props.grocer.isGrocerUrl(url)) {
        const details = await this.props.grocer.getProductDetails(url)
        if (details) {
          const grocerProductId = details.id
          let grocerProduct = this.props.allGrocersProducts.find((agp) => agp.grocerProductId === grocerProductId)
          if (grocerProduct) {
            grocerProduct.name = details.name
            grocerProduct.image = details.image
            grocerProduct.price = details.price
            grocerProduct.onSpecial = details.onSpecial
            grocerProduct.previousPrice = details.previousPrice
            grocerProduct.pricesLastFetched = new Date().getTime()
          } else {
            grocerProduct = new GrocerProduct(
              generateId(),
              this.props.grocer.getId(),
              details.name,
              url,
              details.image,
              null /* not available here */,
              grocerProductId,
              details.price,
              details.onSpecial,
              details.previousPrice,
              new Date().getTime()
            )
          }
          await window.storageService.store('grocerProduct', [grocerProduct.toStorageFormat()])
          item = new GrocerShoppingItem({ id: generateId(), quantity: 1 }, grocerProduct)
        }
      }
      if (!item) {
        const shop = new ShoppingListLocalSettings().get('shop')
        item = new TextualShoppingItem({ id: generateId(), url, quantity: 1, shop })
      }
    }
    if (!item) {
      const shop = new ShoppingListLocalSettings().get('shop')
      item = new TextualShoppingItem({ id: generateId(), title: itemTitle, quantity: 1, shop })
    }

    await this.saveItems([item])
    this.setState({ newItemTitle: '' })
  }

  async saveItems(items) {
    await window.storageService.store(
      'shoppingItem',
      items.map((i) => i.toStorageFormat())
    )
  }

  async deleteItems(items) {
    await window.storageService.store(
      'shoppingItem',
      items.map((i) => {
        i.setArchived()
        return i.toStorageFormat()
      })
    )
  }

  render() {
    const { props, state } = this
    const allShops = props.items.reduce((acc, item) => {
      const shop = item.getShop()
      if (shop && acc.indexOf(shop) === -1) {
        acc.push(shop)
      }
      return acc
    }, [])

    let itemsToShow = props.items
    const shopFilter = new ShoppingListLocalSettings().get('shop')
    if (shopFilter) {
      itemsToShow = itemsToShow.filter((i) => i.getShop() === shopFilter)
    }

    const [completedItems, items] = separate(itemsToShow, (i) => i.isCompleted())

    const outOfDateItems = items.filter((item) => item.outOfDate)

    if (state.completedMode && completedItems.length === 0) {
      this.setState({ completedMode: !state.completedMode })
    }

    let itemGroups
    if (props.settings[GROUP_BY_CATEGORY]) {
      const itemsByCategory = groupByFunction(items, (i) => i.getCategory() || undefined)
      const cats = Object.keys(itemsByCategory)
      itemGroups = []
      categories()
        .concat(['undefined'])
        .forEach((possibleCat) => {
          if (cats.indexOf(possibleCat) > -1) {
            itemGroups.push({ title: possibleCat !== 'undefined' ? possibleCat : 'Miscellaneous', items: itemsByCategory[possibleCat] })
          }
        })
    } else {
      itemGroups = [{ items }]
    }

    return (
      <div className="shopping-list">
        <div>
          <Link className="float-right button" to="/shoppingListSettings">
            <Icon width="16" height="16" id="settings" />
          </Link>
          {completedItems.length > 0 && (
            <button
              onClick={(e) => this.setState({ completedMode: !state.completedMode })}
              style={{ marginRight: '5px', backgroundColor: state.completedMode ? '#e5795c' : null }}
              className="float-right button"
            >
              <Icon width="16" height="16" id="past" />
            </button>
          )}
          {props.grocer && (
            <Link className="float-right button" to="/grocer/products/list" style={{ marginRight: '5px' }}>
              <Icon width="16" height="16" id="categorise" />
            </Link>
          )}
        </div>
        <h1 className="subtitle">
          <span>Shopping List</span>
          {!state.completedMode && allShops.length > 0 && (
            <span>
              :{' '}
              <div className="select" style={{ fontSize: '1.1rem', marginTop: '-7px' }}>
                <select
                  value={shopFilter}
                  onChange={(e) => {
                    new ShoppingListLocalSettings().set('shop', e.target.value)
                    this.setState({ lastUpdated: new Date().getTime() }) // get a rerender
                  }}
                >
                  <option key="" value="">
                    all
                  </option>
                  {allShops.sort().map((shop) => {
                    return (
                      <option key={shop} value={shop}>
                        {shop}
                      </option>
                    )
                  })}
                </select>
              </div>
            </span>
          )}
        </h1>

        {items.length > 3 && !props.isSignedUp && (
          <div style={{ marginBottom: '10px', padding: '10px', borderRadius: '5px', backgroundColor: '#e5795c', color: 'white' }}>
            <p>
              Your shopping items are being stored locally in your browser and may be deleted whenever the browser deems necessary. Please{' '}
              <Link style={{ color: 'black' }} to="/signup">
                sign up
              </Link>{' '}
              to keep your data safe.
            </p>
          </div>
        )}

        {props.invites && props.invites.length > 0 && (
          <div style={{ marginBottom: '10px', padding: '10px', borderRadius: '5px', backgroundColor: '#e5795c', color: 'white' }}>
            {props.invites.map((invite) => {
              return (
                <div key={invite.id}>
                  <p>
                    {invite.inviterUsername} has invited you to share a shopping list. If you accept their invitation, your shopping items
                    will be merged with theirs. Would you like to share?
                  </p>
                  <button
                    className="button"
                    onClick={(e) => {
                      acceptInvite(invite.id).then((updated) => {
                        window.storageService.sync('invite')
                        window.storageService.removeCacheWatermark('shoppingItem').then(() => {
                          // don't await it
                          window.storageService.sync('shoppingItem')
                        })
                      })
                    }}
                  >
                    Yes, share shopping list
                  </button>{' '}
                  <button
                    className="button"
                    onClick={(e) => {
                      declineInvite(invite.id).then((updated) => {
                        window.storageService.sync('invite')
                      })
                    }}
                  >
                    No
                  </button>
                </div>
              )
            })}
          </div>
        )}

        {outOfDateItems.length > 0 && (
          <div style={{ marginBottom: '10px', padding: '10px', borderRadius: '5px', backgroundColor: '#e5795c', color: 'white' }}>
            <p>Sync error: these items were updated on another device and your changes conflict with those.</p>

            <ul style={{ 'list-style': 'disc', paddingLeft: '20px' }}>
              {outOfDateItems.map((outOfDateItem) => {
                return <li key={outOfDateItem.id}>{outOfDateItem.getTitle()}</li>
              })}
            </ul>
            <button
              className="button"
              onClick={(e) => {
                window.storageService.resetAndSync('shoppingItem')
              }}
            >
              Discard your changes
            </button>
          </div>
        )}

        {!state.completedMode && (
          <div>
            {props.allGrocersProducts.length > 0 && (
              <ShoppingItemAutoComplete
                placeholder="Add item"
                items={props.allGrocersProducts}
                titleGetter={(item) => item.name}
                onSelect={(text, grocerProduct) => {
                  const newShoppingItems = []
                  if (grocerProduct) {
                    newShoppingItems.push(new GrocerShoppingItem({ id: generateId(), quantity: 1 }, grocerProduct))
                  } else {
                    this.addItem(text.trim())
                  }
                  this.saveItems(newShoppingItems)
                }}
              />
            )}

            {props.allGrocersProducts.length === 0 && (
              <ShoppingItemAutoComplete
                placeholder="Add item"
                items={completedItems.filter((i) => !(i instanceof GrocerShoppingItem))}
                onSelect={(text, item) => {
                  if (!item) {
                    this.addItem(text.trim())
                  } else {
                    item.setUncompleted()
                    this.saveItems([item])
                  }
                }}
              />
            )}

            {itemGroups
              .filter((ig) => ig.items.length > 0)
              .map((itemGroup) => {
                return (
                  <div key={itemGroup.items[0].id} style={{ marginTop: '15px' }}>
                    <h3 style={{ color: '#e5795c', fontSize: '1rem' }}>{itemGroup.title}</h3>
                    <ShoppingItemList
                      items={itemGroup.items}
                      allShops={allShops}
                      saveItem={(i) => this.saveItems([i])}
                      tickedItems={state.tickedItems}
                      handleItemTick={(item) => {
                        item.setCompleted()
                        this.saveItems([item])
                      }}
                      allowCategoryEdit={props.settings[GROUP_BY_CATEGORY]}
                    />
                  </div>
                )
              })}
          </div>
        )}

        {state.completedMode && (
          <div style={{ marginTop: '15px' }}>
            <h3 style={{ color: '#e5795c', fontSize: '1rem' }}>Recently completed</h3>
            <p>These were recently marked as complete. Un-tick them to return them to your list</p>
            <ShoppingItemList
              items={completedItems.sort((a, b) => b.completedAt - a.completedAt)}
              allShops={allShops}
              saveItem={(i) => this.saveItems([i])}
              deleteItem={(i) => this.deleteItems([i])}
              tickedItems={completedItems.map((ci) => ci.id)}
              handleItemTick={(item) => {
                if (completedItems.length === 1) {
                  this.setState({ completedMode: false })
                }
                item.setUncompleted()
                this.saveItems([item])
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    inviteFetched: (invite) => {
      dispatch({ type: 'INVITES_FETCHED', objects: [invite] })
    },
  }
}

export default connect((state, ownProps) => {
  const authSelector = new AuthSelector(state)
  const username = authSelector.username()

  const items = new ShoppingItemSelector(state).all().reverse()
  for (const item of items) {
    if (item instanceof TextualShoppingItem && !item.category && item.title) {
      const newCategory = categorise(item.title)
      if (newCategory) {
        item.category = newCategory
      }
    }
    if (!item.quantity) {
      item.quantity = 1
    }
  }

  const grocer = state.setting.byName[GROCER] ? findGrocer(state.setting.byName[GROCER]) : null

  const allGrocersProducts = grocer ? new GrocerProductSelector(state).all(grocer.getId()) : []

  return {
    items,
    invites: Object.values(state.invite.byId).filter(
      (i) => i.type === 'shoppingList' && i.archived !== true && i.inviteeUsername === username
    ),
    isSignedUp: !!authSelector.token(),
    grocer,
    allGrocersProducts,
    settings: {
      [GROUP_BY_CATEGORY]: !!state.setting.byName[GROUP_BY_CATEGORY],
    },
  }
}, mapDispatchToProps)(ShoppingList)
