import React from 'react'
import { getAuthToken, isAuthenticated } from '../auth-helpers'
import { withLocation, withNavigation } from '../../js-lib-react/'
import { Link } from 'react-router-dom'
import { doLogout } from '../../app-lifecycle'
import { connect } from 'react-redux'
import { extractUsername } from '../reducers/auth'

class Header extends React.Component {
  render() {
    const { props, state } = this
    const path = props.location.pathname || ''
    const hasAuthToken = isAuthenticated()
    let username = null
    if (hasAuthToken) {
      username = extractUsername(getAuthToken())
    }

    return (
      <div>
        {hasAuthToken && (
          <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'flex-end' }}>
            <a
              style={{ margin: '10px 10px 0 0' }}
              onClick={(e) => {
                doLogout()
                props.logOut()
                props.navigate('/')
              }}
            >
              Logout
            </a>
          </div>
        )}
        {!hasAuthToken && (
          <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'flex-end' }}>
            <Link style={{ margin: '10px 10px 0 0' }} to="/login">
              Login
            </Link>
          </div>
        )}
        <section className="hero bsa">
          <div className="container">
            <p className="title">
              <svg className="space-right-large" width="32" height="32">
                <use xlinkHref="#carrot"></use>
              </svg>
              PALATABLE
            </p>
          </div>
        </section>

        <nav className="navbar has-shadow">
          <div className="main-content" style={{ margin: '0 auto' }}>
            <div className="container">
              <div className="navbar-brand">
                <Link to="/" className={'navbar-item is-tab' + (path === '/' || path.startsWith('/recipe') ? ' active' : '')}>
                  Recipes
                </Link>
                <Link to="/mealPlanner" className={'navbar-item is-tab' + (path.startsWith('/mealPlanner') ? ' active' : '')}>
                  Meal Planner
                </Link>
                <Link
                  to="/shoppingList"
                  className={
                    'navbar-item is-tab' + (path.startsWith('/shoppingList') || path.startsWith('/grocer/products') ? ' active' : '')
                  }
                >
                  Shopping List
                </Link>
                {(username === 'carwinz@gmail.com' || username === 'rochelle014@gmail.com') && (
                  <Link to="/more" className={'navbar-item is-tab' + (path.startsWith('/more') ? ' active' : '')}>
                    More
                  </Link>
                )}
                {!hasAuthToken && (
                  <Link to="/signup" className={'navbar-item is-tab' + (path.startsWith('/signup') ? ' active' : '')}>
                    Sign up
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch({ type: 'LOGOUT' })
    },
  }
}

export default withNavigation(withLocation(connect((state, ownProps) => ({}), mapDispatchToProps)(Header)))
