
const upsertRecipes = (state, recipes) => {
  const byId = {...state.byId}
  recipes.forEach(recipe => {
    if (recipe.archived) {
      delete byId[recipe.id]
    } else {
      byId[recipe.id] = recipe
    }
  })
  return {...state, byId}
}

const defaultState = { byId: {} }

const reducer = function(state = defaultState, action) {
  switch (action.type) {
    case 'LOGOUT':
      return defaultState
    case 'RECIPES_FETCHED':
      return upsertRecipes(state, action.objects)
    default:
      return state
  }
}

export default reducer
