import React, { createRef } from 'react'
import autoComplete from '../../js-lib-react/components/form-components/auto-complete/auto-complete'
import '../../js-lib-react/components/form-components/auto-complete/auto-complete.css'
import { levenshteinDistance } from '../../js-lib/levenshtein-distance'
import { v4 as uuid } from 'uuid'
import './RecipeAutoComplete.css'

const IMAGE_API = process.env.IMAGE_API_URL || 'https://images.palatable.kitchen'

class RecipeAutoComplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = { uuid: uuid(), newTag: props.value || '' }
    this.input = createRef()
    this.nameGetter = props.nameGetter || ((i) => i.name || '')
  }

  componentDidMount() {
    const instance = this
    this.autoCompleteInstance = new autoComplete({
      source: (term, callback) => {
        instance.setState({ searchQuery: term })
        if (term && term.length > 2) {
          const termLower = term.toLowerCase()
          let matches = instance.props.items.filter((i) => (this.nameGetter(i) || '').toLowerCase().indexOf(termLower) > -1)
          matches = matches.sort((a, b) => {
            const aDist = levenshteinDistance(term, this.nameGetter(a))
            const bDist = levenshteinDistance(term, this.nameGetter(b))
            if (aDist > bDist) {
              return 1
            }
            if (aDist < bDist) {
              return -1
            }
            return 0
          })

          callback(matches)
        } else {
          callback([])
        }
      },
      cache: 0,
      minChars: 1,
      selector: '.autocomplete' + this.state.uuid,
      onSelect: (event, value, item) => {
        event.preventDefault()
        const id = item.getAttribute('data-item-id')
        const recipe = instance.props.items.find((i) => i.id === id)
        this.props.onSelect(value, recipe)
        this.setState({ newTag: '' })
      },
      labelField: 'title', // TODO: use the method?
      wantedSuggestionHeight: '78',
      renderAbove: false,
      renderItem: function (item, search) {
        let image = ''
        if (item.images && item.images.length > 0) {
          const imageUrl = IMAGE_API + '/100x100/' + item.images[0].id + '.jpg'
          image = '<img src="' + imageUrl + '" />'
        }
        return (
          '<div class="autocomplete-suggestion" data-val="' +
          instance.nameGetter(item) +
          '" data-item-id="' +
          item.id +
          '">' +
          '<div class="recipe-autocomplete-suggestions-wrapper">' +
          image +
          '<div class="auto-complete-title">' +
          instance.nameGetter(item) +
          '</div>' +
          '</div>' +
          '</div>'
        )
      },
    })
    if (this.props.autoFocus) {
      this.input.current.focus()
    }
  }

  componentWillUnmount() {
    this.autoCompleteInstance.destroy()
  }

  handleKeyPress(instance, event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      // when text is meant to remain, this blanks out the text
      //   if (instance.props.value) {
      //     instance.props.onSelect(instance.props.value, null) // || this.state.newTag, null)
      //     this.setState({newTag: ''})
      //     instance.input.current.blur()
      //   }
    }

    event.stopPropagation()
  }

  render() {
    const { props, state } = this
    return (
      <span className="tag-auto-complete" onClick={(e) => e.stopPropagation()}>
        <input
          ref={this.input}
          placeholder={props.placeholder}
          className={'input autocomplete' + this.state.uuid}
          type="text"
          autoComplete="off"
          value={state.newTag}
          required={this.props.required}
          onInput={(e) => {
            this.setState({ newTag: e.target.value })
            if (this.props.onInput) {
              this.props.onInput(e.target.value)
            }
          }}
          onKeyPress={(e) => this.handleKeyPress(this, e)}
        />
      </span>
    )
  }
}

export default RecipeAutoComplete
