import {parse} from './ingredient-parser'

const formatMeasurement = ranges => {
  if (ranges.length > 1) {
    return ranges.join('-')
  }
  return ranges[0]
}

export const extractIngredientAmounts = (inputText) => {
  const ingredientAmounts = []
  const matches = []

  const regexMatches = inputText.match(/\(\((.*?)\)\)/g)

  if (regexMatches) {
    regexMatches.forEach(match => {
      ingredientAmounts.push(parse(match.substring(2, match.length - 2)))
      matches.push(match)
    })
  }

  return {ingredientAmounts, matches}
}

export const replaceIngredientAmounts = (ingredientAmounts, matches, inputText) => {
  let outputText = inputText
  for (var i=0; i<ingredientAmounts.length; i++) {
    const ia = ingredientAmounts[i]
    const replacement = (ia.measurements ? formatMeasurement(ia.getReadableMeasurements()) + ' ' : '') + (ia.unit ? ia.getReadableUnit() + ' ' : '') + ia.ingredient
    outputText = outputText.replace(matches[i], replacement)
  }

  return outputText
}

export const extractTimers = (inputText) => {
  const matches = inputText.match(/((\d+)(\s?-\s?\d+)? min(ute)?s)/g)
  return matches
    .map(m => tidyTimeUnit(m).trim())
    .map(m => {
      const unitMatch = m.match(/(minutes|minute|seconds|second|hours|hour)/)
      if (!unitMatch) {
        return undefined
      }
      const unit = unitMatch[1]
      const amountString = m.replace(unit, '').trim()
      const amountMatch = amountString.match(/(\d+)((\s?-\s?)(\d+))?/)
      if (!amountMatch) {
        return undefined
      }
      const amounts = [parseInt(amountMatch[1], 10)]
      if (amountMatch.length === 5 && amountMatch[4] !== undefined) {
        amounts.push(parseInt(amountMatch[4]))
      }

      return {amounts, unit}
    })
    .filter(m => !!m)
}

const tidyTimeUnit = (line) => {
  [
    {match: 'mins', replacement: 'minutes'},
    {match: 'min', replacement: 'minute'},
    {match: 'secs', replacement: 'seconds'},
    {match: 'sec', replacement: 'second'}
  ].forEach(replacement => {
    line = line.replace(new RegExp(replacement['match'] + '\\b'), replacement['replacement'])
  })

  return line
}
