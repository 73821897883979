
import IngredientAmount from './ingredient-amount'

export const parse = (line) => {
  let { measurements, remainingLine } = parseMeasurement(line)
  if (measurements) {
    const unitResult = extractUnit(remainingLine)
    if (unitResult) {
      remainingLine = unitResult.remaining

      let alternatives = null
      const alternativeMatch = remainingLine.trim().match(/^\((\d+.*?)\)/)
      if (alternativeMatch) {
        const alternativeLine = alternativeMatch[1]
        const alternative = parse(alternativeLine)
        if (alternative.unit) {
          remainingLine = remainingLine.substring(alternativeLine.length + 2).trim()
          alternatives = [[alternative.measurements, alternative.unit]]
        }
      }

      let extraUnitInfo = null
      if (unitResult.unitAdjective) {
        extraUnitInfo = {unitAdjective: unitResult.unitAdjective}
      }

      let notes = null
      let notesSeparatorPos = remainingLine.indexOf(' -- ')
      if (notesSeparatorPos > -1) {
        notes = remainingLine.substring(notesSeparatorPos + 4)
        remainingLine = remainingLine.substring(0, notesSeparatorPos)
      }

      return new IngredientAmount(measurements, unitResult.unit, remainingLine, alternatives, extraUnitInfo, notes);
    }
  } else {
    return new IngredientAmount(undefined, undefined, remainingLine)
  }
}

const parseMeasurement = (line) => {
  let remainingLine = line
  let measurementResult = extractMeasurement(remainingLine)
  if (!measurementResult) {
    if (remainingLine.toLowerCase().startsWith('pinch') || remainingLine.toLowerCase().startsWith('splash') || remainingLine.toLowerCase().startsWith('dollop') || remainingLine.toLowerCase().startsWith('dash')) {
      return { measurements: [1], remainingLine: remainingLine.toLowerCase() }
    }
    return { measurements: null, remainingLine }
  }

  remainingLine = measurementResult.remaining

  if (remainingLine.startsWith('-') || remainingLine.startsWith('to ') || remainingLine.startsWith('or ')) {
    let secondMeasurement = extractMeasurement(remainingLine.replace('-', '').replace('to ', '').replace('or ', '').trim())
    if (secondMeasurement) {
      measurementResult.measurements.push(secondMeasurement.measurements[0])
      remainingLine = secondMeasurement.remaining
    }
  }

  return { measurements: measurementResult.measurements, remainingLine }
}

const extractMeasurement = (line) => {
  let m = line.match(/^(\d+\/\d+)/)
  if (m) {
    let parts = m[1].split('/')
    return {measurements: [parseInt(parts[0]) / parseInt(parts[1])], remaining: line.replace(m[1], '').trim()}
  }

  m = line.match(/^(a ).*/i)
  if (m) {
    return {measurements: [1], remaining: line.replace(m[1], '').trim()}
  }

  m = line.match(/^(\d+\.\d+).*/)
  if (m) {
    return {measurements: [parseFloat(m[1])], remaining: line.replace(m[1], '').trim()}
  }

  m = line.match(/^(\.\d+)/)
  if (m) {
    return {measurements: [parseFloat(m[1])], remaining: line.replace(m[1], '').trim()}
  }

  m = line.match(/^(\d+)/)
  if (m) {
    let measurement = parseInt(m[1])
    let nextLine = line.replace(m[1], '').trim()
    if (nextLine.toLowerCase().startsWith('and')) {
      nextLine = nextLine.substring(3).trim()
    }

    const following = extractMeasurement(nextLine)
    if (following && following.measurements && following.measurements[0] < 1) {
      measurement = measurement + following.measurements[0]
      nextLine = following.remaining
    }

    return {measurements: [measurement], remaining: nextLine}
  }

  if (line.startsWith('½')) {
    return {measurements: [0.5], remaining: line.replace('½', '').trim()}
  }
  if (line.startsWith('half')) {
    return {measurements: [0.5], remaining: line.replace('half', '').trim()}
  }
  if (line.startsWith('one-half')) {
    return {measurements: [0.5], remaining: line.replace('one-half', '').trim()}
  }
  if (line.startsWith('⅓')) {
    return {measurements: [0.333333333333333], remaining: line.replace('⅓', '').trim()}
  }
  if (line.startsWith('one-third')) {
    return {measurements: [0.333333333333333], remaining: line.replace('one-third', '').trim()}
  }
  if (line.startsWith('⅔')) {
    return {measurements: [0.666666666666666], remaining: line.replace('⅔', '').trim()}
  }
  if (line.startsWith('two-third')) {
    return {measurements: [0.666666666666666], remaining: line.replace('two-third', '').trim()}
  }
  if (line.startsWith("¼")) {
    return { measurements: [0.25], remaining: line.replace("¼", "").trim() };
  }
  if (line.startsWith("one-quarter")) {
    return { measurements: [0.25], remaining: line.replace("one-quarter", "").trim() };
  }
  if (line.startsWith("¾")) {
    return { measurements: [0.75], remaining: line.replace("¾", "").trim() };
  }
  if (line.startsWith("three-quarter")) {
    return { measurements: [0.75], remaining: line.replace("three-quarter", "").trim() };
  }
  if (line.startsWith("⅛")) {
    return { measurements: [0.125], remaining: line.replace("⅛", "").trim() };
  }
  if (line.startsWith("one-eighth")) {
    return { measurements: [0.125], remaining: line.replace("one-eighth", "").trim() };
  }
  if (line.startsWith("three-eighths")) {
    return { measurements: [0.375], remaining: line.replace("three-eighths", "").trim() };
  }
  if (line.startsWith("five-eighths")) {
    return { measurements: [0.625], remaining: line.replace("five-eighths", "").trim() };
  }
  if (line.startsWith("seven-eighths")) {
    return { measurements: [0.875], remaining: line.replace("seven-eighths", "").trim() };
  }
}

const tidyUnit = (line) => {
  [
    { match: 'lbs', replacement: 'pounds' },
    { match: 'lb', replacement: 'pound' },
    { match: 'bch', replacement: 'bunch' },
    { match: 'btls', replacement: 'bottles' },
    { match: 'btl', replacement: 'bottle' },
    { match: 'c', replacement: 'cup' },
    { match: 'g', replacement: 'gram' },
    { match: 'gm', replacement: 'gram' },
    { match: 'gms', replacement: 'grams' },
    { match: 'dz', replacement: 'dozen' },
    { match: 'doz', replacement: 'dozen' },
    { match: 'dps', replacement: 'drops' },
    { match: 'dp', replacement: 'drop' },
    { match: 'ds', replacement: 'dash' },
    { match: 'ozs', replacement: 'ounces' },
    { match: 'oz', replacement: 'ounce' },
    { match: 'kgs', replacement: 'kilograms' },
    { match: 'kg', replacement: 'kilogram' },
    { match: 'kilos', replacement: 'kilograms' },
    { match: 'kilo', replacement: 'kilogram' },
    { match: 'mls', replacement: 'mililitres' },
    { match: 'ml', replacement: 'mililitres' },
    { match: 'L', replacement: 'litre', caseSensitive: true },
    { match: 'ltr', replacement: 'litre' },
    { match: 'ltrs', replacement: 'litres' },
    { match: 'mgs', replacement: 'miligrams' },
    { match: 'mg', replacement: 'miligram' },
    { match: 'pcs', replacement: 'pieces' },
    { match: 'pkts', replacement: 'packets' },
    { match: 'pkt', replacement: 'packet' },
    { match: 'pn', replacement: 'pinch' },
    { match: 'pts', replacement: 'pints' },
    { match: 'pt', replacement: 'pint' },
    { match: 'tbsp', replacement: 'tablespoon' },
    { match: 'tbp', replacement: 'tablespoon' },
    { match: 'tbs', replacement: 'tablespoon' },
    { match: 'tblsp', replacement: 'tablespoon' },
    { match: 'tbls', replacement: 'tablespoon' },
    { match: 'T', replacement: 'tablespoon', caseSensitive: true },
    { match: 'tsps', replacement: 'teaspoons' },
    { match: 'tsp', replacement: 'teaspoon' },
    { match: 'tspn', replacement: 'teaspoon' },
    { match: 't', replacement: 'teaspoon', caseSensitive: true },
    { match: 'tps', replacement: 'teaspoon' },
  ].forEach(replacement => {
    line = line.replace(new RegExp('^' + replacement['match'] + '\\b', replacement['caseSensitive'] === true ? '' : 'i'), replacement['replacement'] + ' ')
  })

  return line
}

const extractUnit = (line) => {
  let remainingLine = line
  const unitAdjective = findUnitAdjective(line)
  const tidiedUnitLine = tidyUnit(unitAdjective.remainingLine)
  const unit = findUnit(tidiedUnitLine)
  if (unit) {
    remainingLine = tidiedUnitLine.substring(unit.length).trim();
    if (remainingLine.indexOf('.') === 0) {
      remainingLine = remainingLine.substring(1).trim()
    }
    if (remainingLine.indexOf('of') === 0) {
      remainingLine = remainingLine.substring(2).trim()
    }
  }

  const result = { unit, remaining: remainingLine }
  if (unitAdjective.unitAdjective) {
    result.unitAdjective = unitAdjective.unitAdjective
  }
  return result
}

const findUnitAdjective = (line) => {
  let remainingLine = line;
  for (let adj of ['heaped', 'level']) {
    if (remainingLine.startsWith(adj + ' ')) {
      return { remainingLine: remainingLine.substring(adj.length).trim(), unitAdjective: adj}
    }
  }
  return {remainingLine}
}

const findUnit = (line) => {
  return [
      'blocks', 'block',
      'bottles', 'bottle',
      'boxes', 'box',
      'bunches', 'bunch',
      'cans', 'can',
      'cloves', 'clove',
      'cups', 'cup',
      'dash',
      'drops', 'drop',
      'dozen',
      'grams', 'gram',
      'jars', 'jar',
      'kilograms', 'kilogram',
      'litres', 'litre',
      'mililitres', 'mililitre',
      'miligrams', 'miligram',
      'ounces', 'ounce',
      'packets', 'packet',
      'packs', 'pack',
      'pinches', 'pinch',
      'pints', 'pint',
      'pieces', 'piece',
      'pounds', 'pound',
      'quarts', 'quart',
      'rashers', 'rasher',
      'sheets', 'sheet',
      'scoops', 'scoop',
      'sprigs', 'sprig',
      'stalks', 'stalk',
      'tablespoons', 'tablespoon',
      'teaspoons', 'teaspoon',
      'tins', 'tin'
    ].find(potential_unit => line.toLowerCase().indexOf(potential_unit) === 0)
}
