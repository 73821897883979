import React from 'react'
import { Link } from 'react-router-dom'
import { withNavigation } from '../../js-lib-react'
import { formatCurrency } from '../../js-lib/currency-formatting'
import { addDays } from '../../js-lib/date-helpers'
import { categories } from '../../recipe/ingredient-categoriser'
import TextualShoppingItem from '../domain/textualShoppingItem'

class ShoppingItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      title: props.item ? props.item.title : '',
      url: props.item ? props.item.url : '',
      category: props.item ? props.item.category : '',
      shop: props.item ? props.item.getShop() : '',
    }
  }

  handleAdd(item) {
    const updatedItem = item.clone()
    updatedItem.quantity = (item.quantity || 0) + 1
    this.props.saveItem(updatedItem)
  }

  handleRemove(item) {
    const updatedItem = item.clone()
    if (item.quantity === 1) {
      updatedItem.setCompleted()
    } else {
      updatedItem.quantity = (updatedItem.quantity || 0) - 1
    }
    window.storageService.store('shoppingItem', [updatedItem.toStorageFormat()])
  }

  async handleSave() {
    const updatedItem = this.props.item.clone()
    updatedItem.title = this.state.title
    updatedItem.url = this.state.url
    updatedItem.shop = this.state.shop
    updatedItem.category = this.state.category || null

    if (this.props.grocer && !updatedItem.image && this.props.grocer.isGrocerUrl(updatedItem.url || '')) {
      const details = await this.props.grocer.getProductDetails(updatedItem.url)
      if (details) {
        updatedItem.image = details.image
      }
    }

    this.props.saveItem(updatedItem)
    this.setState({ editing: false })
  }

  handleArchive() {
    const updatedItem = this.props.item.clone()
    updatedItem.setArchived()
    this.props.saveItem(updatedItem)
  }

  render() {
    const { props, state } = this
    const { item, recipe } = props
    let { allShops } = props
    if (state.shop && allShops.indexOf(state.shop) === -1) {
      allShops = [...allShops, state.shop]
    }
    let forRecipe,
      forRecipeName = null
    if (item.recipeId) {
      if (recipe) {
        // it might not be synced yet, or might be archived, or from another user
        forRecipe = recipe.name
      }
    } else {
      if (item.recipeName) {
        // User has shared shopping list, but no permissions to see the recipe itself
        forRecipeName = item.recipeName
      }
    }

    const isEditable = item instanceof TextualShoppingItem
    const price = item.getPrice()

    return (
      <div className="shopping-list-item">
        <div className={state.editing ? 'edit' : 'show'}>
          {!state.editing && (
            <div className="tickbox">
              <input className="clickable" type="checkbox" checked={props.isTicked} onChange={(e) => props.handleItemTick(item)} />
            </div>
          )}
          {!state.editing && (
            <div
              style={{ display: 'block', width: '100%', cursor: 'pointer' }}
              onClick={(e) => {
                if (isEditable) {
                  this.setState({ editing: true })
                } else {
                  if (item.grocerProduct) {
                    this.props.navigate(`/grocer/products/edit/${item.grocerProduct.id}`)
                  }
                }
              }}
            >
              {item.getImage() && (
                <img src={item.getImage()} alt={item.getTitle()} style={{ padding: '5px', float: 'right' }} width="70px" />
              )}
              <p>{item.getTitle()}</p>
              <small>
                {!!price && (
                  <span>
                    {formatCurrency('AUD', price * 100)} {item.getIsOnSpecial() ? 'sp ' : ''}
                  </span>
                )}
                {item.getUrl() && (
                  <a href={item.getUrl()} onClick={(e) => e.stopPropagation()}>
                    {item.getPrettyHost()}
                  </a>
                )}
                {props.grocer && !item.getUrl() && (
                  <a href={props.grocer.findProductUrl(item.getTitle())} onClick={(e) => e.stopPropagation()}>
                    Find on {props.grocer.getName()}
                  </a>
                )}
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    this.handleRemove(item)
                  }}
                  style={{ marginLeft: '5px' }}
                  className="button is-small"
                >
                  -
                </button>
                &nbsp;{item.quantity}
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    this.handleAdd(item)
                  }}
                  style={{ marginLeft: '5px', marginBottom: '5px' }}
                  className="button is-small"
                >
                  +
                </button>{' '}
                &nbsp;
              </small>
              {item.lastUpdated < addDays(new Date().getTime(), -10) && <span className="tag">maybe old</span>}
              {forRecipe && (
                <div className="extra">
                  <small>
                    for <Link to={'/recipe/' + item.recipeId}>{forRecipe}</Link>
                  </small>
                </div>
              )}
              {forRecipeName && (
                <div className="extra">
                  <small>for {forRecipeName}</small>
                </div>
              )}
            </div>
          )}
          {!state.editing && props.isTicked && (
            <button onClick={(e) => props.deleteItem(props.item)} className="button is-warning" style={{ margin: '10px' }}>
              Delete
            </button>
          )}
          {state.editing && (
            <div>
              <form onSubmit={(e) => this.handleSave()} style={{ margin: '10px 0' }}>
                <div className="field is-horizontal">
                  <label className="field-label">Name</label>
                  <div className="field-body">
                    <input
                      type="text"
                      className="input"
                      value={state.title}
                      onInput={(e) => e.preventDefault() || this.setState({ title: e.target.value })}
                    />
                  </div>
                </div>
                <div className="field is-horizontal">
                  <label className="field-label">URL</label>
                  <div className="field-body">
                    <input
                      type="text"
                      className="input"
                      value={state.url}
                      onInput={(e) => e.preventDefault() || this.setState({ url: e.target.value })}
                    />
                  </div>
                </div>
                {props.allowCategoryEdit && (
                  <div className="field is-horizontal">
                    <label className="field-label">Category</label>
                    <div className="field-body">
                      <div className="select">
                        <select value={state.category} onChange={(e) => this.setState({ category: e.target.value })}>
                          <option value=""></option>
                          {categories()
                            .sort()
                            .map((cat) => {
                              return <option value={cat}>{cat}</option>
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="field is-horizontal">
                  <label className="field-label">Shop</label>
                  <div className="field-body">
                    <div className="select">
                      <select
                        value={state.shop}
                        onChange={(e) => {
                          let shop = e.target.value
                          if (shop === '[create new]') {
                            shop = window.prompt('Name of shop')
                            if (!shop) {
                              return
                            }
                          }
                          this.setState({ shop })
                        }}
                      >
                        <option value=""></option>
                        {allShops.sort().map((shop) => {
                          return <option value={shop}>{shop}</option>
                        })}
                        <option value="[create new]">[create new]</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <label className="field-label"></label>
                  <div className="field-body">
                    <button
                      className="button margin-top margin-right"
                      type="submit"
                      onClick={(e) => e.preventDefault() || this.handleSave()}
                    >
                      Save
                    </button>
                    <button
                      className="button margin-top margin-right is-warning"
                      type="button"
                      onClick={(e) => this.setState({ editing: false })}
                    >
                      Cancel
                    </button>
                    {item.completedAt && (
                      <button className="button margin-top margin-right is-danger" type="button" onClick={(e) => this.handleArchive()}>
                        Archive
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withNavigation(ShoppingItem)
