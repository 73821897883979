import BaseSelector from './BaseSelector'

class AuthSelector extends BaseSelector {
  token = () => this.state.auth.token
  userId = () => this.state.auth.userId
  username = () => this.state.auth.username
  isAuthenticated = () => !!this.token()

}

export default AuthSelector
