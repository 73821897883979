import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { utcTime } from '../../js-lib/date-helpers'
import { generateId } from '../../js-lib/storage/idGenerator'
import { infoForIngredientFormatted } from '../cooking-info'
import { build } from '../recipe-builder'
import RawRecipe from '../raw-recipe'
import IngredientShow from './IngredientShow'
import './RecipeShow.css'
import Edit from './Edit'
import { stringComparator, unique, withValue, withoutValue } from '../../js-lib/array-helpers'
import Icon from '../../app/icon'
import AuthSelector from '../../app/AuthSelector'
import RecipeSelector from '../selector'
import Modal from './Modal'
import AddToShopping from './AddToShopping'
import IngredientAmount from '../ingredient-amount'
import SharedRecipeSelector from '../sharedRecipeSelector'
import { withRouterParams } from '../../js-lib-react/'

const IMAGE_API = process.env.IMAGE_API_URL || 'https://images.palatable.kitchen'

const urlsToHyperlinks = (text) => {
  var exp = /((http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)/g
  return text.replace(exp, "<a href='$1' target='_blank'>$1</a>")
}

function playItHere(e, url) {
  var audio = document.createElement('audio')
  var src = document.createElement('source')
  src.src = url
  audio.appendChild(src)
  audio.play()
  e.preventDefault()
}

const pronunciations = [
  { word: 'käsespätzle', url: '/audio/käsespätzle.mp3' },
  { word: 'königsberger klopse', url: '/audio/konigsberger klopse.mp3' },
  { word: 'laugenbrötchen', url: '/audio/laugenbrötchen.mp3' },
  { word: 'spätzle', url: '/audio/spätzle.mp3' },
  { word: 'spaetzle', url: '/audio/spätzle.mp3' },
  { word: 'kaiserschmarren', url: '/audio/kaiserschmarren.mp3' },
  { word: 'apfelpfannkuchen', url: '/audio/apfelpfannkuchen.mp3' },
  { word: 'sauerbraten', url: '/audio/sauerbraten.mp3' },
]

class RecipeShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showServeAdjustment: false,
      tickedIngredientIndexes: [],
      instructionIndex: 0,
    }
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleKeyPress(event) {
    if (event.ctrlKey && event.key === 'e') {
      event.preventDefault()
      this.setState({ showEditForm: !this.state.showEditForm })
    }
    if (event.key === 'Escape' && this.state.modalInfo) {
      this.setState({ modalInfo: null })
    }
  }

  async componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress, false)

    const views = await window.storageService.fetchImmediatelyAvailable('recipeView')
    const recipeId = this.props.recipeId
    let view = views.find((v) => v.id === recipeId)
    if (!view) {
      view = { id: recipeId }
    }
    view.viewTime = utcTime()
    await window.storageService.store('recipeView', [view])
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false)
  }

  currentServes() {
    return this.props.recipeOverride.serves || (this.props.rawRecipe ? this.props.rawRecipe.serves : null)
  }

  addServe() {
    window.storageService.store('recipeOverride', [{ ...this.props.recipeOverride, serves: this.currentServes() + 1 }])
  }

  removeServe() {
    window.storageService.store('recipeOverride', [{ ...this.props.recipeOverride, serves: this.currentServes() - 1 }])
  }

  halfServes() {
    window.storageService.store('recipeOverride', [{ ...this.props.recipeOverride, serves: this.currentServes() / 2 }])
  }

  doubleServes() {
    window.storageService.store('recipeOverride', [{ ...this.props.recipeOverride, serves: this.currentServes() * 2 }])
  }

  toggleIngredientDone(ingredientAmountIndex) {
    if (this.state.ingredientsTicked.indexOf(ingredientAmountIndex) > -1) {
      this.setState({ ingredientsTicked: withoutValue(this.state.ingredientsTicked, ingredientAmountIndex) })
    } else {
      this.setState({ ingredientsTicked: unique(withValue(this.state.ingredientsTicked, ingredientAmountIndex)) })
    }
  }

  updateRecipeOverrideWithAltIngred(alternative) {
    let recipeOverride = { ...this.props.recipeOverride }
    if (!recipeOverride.id) {
      recipeOverride.id = generateId()
    }

    if (!recipeOverride.chosenIngredientAmounts) {
      recipeOverride.chosenIngredientAmounts = [alternative]
    } else {
      const altIngredientAmount = new IngredientAmount(
        alternative.original.measurements,
        alternative.original.unit,
        alternative.original.ingredient,
        null,
        alternative.original.extraUnitInfo,
        alternative.original.notes
      )
      let existingIndex = recipeOverride.chosenIngredientAmounts.findIndex((c) => altIngredientAmount.matches(c.original))
      if (existingIndex > -1) {
        if (alternative.chosenAlternative) {
          // update the over-ride
          recipeOverride.chosenIngredientAmounts.splice(existingIndex, 1, alternative)
        } else {
          // using original; remove the over-ride
          recipeOverride.chosenIngredientAmounts.splice(existingIndex, 1)
        }
      } else {
        // add a new override
        recipeOverride.chosenIngredientAmounts.push(alternative)
      }
    }
    recipeOverride.lastUpdated = utcTime()

    window.storageService.store('recipeOverride', [recipeOverride])
  }

  findAlternateIndex(ingredientAmount) {
    const override = (this.props.recipeOverride.chosenIngredientAmounts || []).find((c) => {
      return ingredientAmount.matches(c.original)
    })
    let alternativeIndex = null
    if (override) {
      alternativeIndex = ingredientAmount.getAlternatives().findIndex((alt) => {
        const chosen = override.chosenAlternative
        return alt.matches(chosen)
      })
    }
    return alternativeIndex
  }

  renderIngredients(ingredients, username) {
    let lastGroup = null
    return (
      <div>
        <h3 className="subtitle is-6 text-section-header">Ingredients</h3>
        <div className="ingredients">
          {ingredients.map((ingredientAmount, index) => {
            const alternativeIndex = this.findAlternateIndex(ingredientAmount)
            const showNewGroup = lastGroup !== ingredientAmount.group
            lastGroup = ingredientAmount.group
            return (
              <div key={index}>
                {showNewGroup && <p className="ingredient-group">{ingredientAmount.group}</p>}
                <IngredientShow
                  showModal={(modalInfo) => this.setState({ modalInfo })}
                  username={username}
                  ingredientAmount={ingredientAmount}
                  activeAlternativeIndex={alternativeIndex}
                  handleTickChange={(ticked) => {
                    const tickedIngredientIndexes = ticked
                      ? withValue(this.state.tickedIngredientIndexes, index)
                      : withoutValue(this.state.tickedIngredientIndexes, index)
                    this.setState({ tickedIngredientIndexes })
                  }}
                  handleMeasurementChange={(alternative) => {
                    this.updateRecipeOverrideWithAltIngred(alternative)
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    const { props, state } = this
    if (!props.rawRecipe) {
      return <div>Loading...</div>
    }
    const recipe = build(props.rawRecipe, {
      wantedServes: this.currentServes(),
      temperatureUnit: this.props.settings.temperatureUnit,
    })

    let tickedIngredients =
      this.state.tickedIngredientIndexes.length > 0
        ? recipe.ingredients.filter((ingredient, index) => this.state.tickedIngredientIndexes.indexOf(index) > -1)
        : recipe.ingredients
    tickedIngredients = tickedIngredients.map((ingredientAmount) => {
      const alternativeIndex = this.findAlternateIndex(ingredientAmount)
      if (alternativeIndex != null) {
        const alt = ingredientAmount.getAlternatives()[alternativeIndex]
        return new IngredientAmount(alt.measurements, alt.unit, alt.ingredient, null, null, alt.notes)
      } else {
        return ingredientAmount
      }
    })

    const instructionSteps = (recipe.instructions || '').split('\n').filter((e) => !!e)

    const pronunciation = pronunciations.find((p) => (recipe.name || '').toLowerCase().indexOf(p.word) > -1)

    return (
      <div>
        <div>
          <Link className="float-right button" to="/recipeSettings">
            <Icon width="16" height="16" id="settings" />
          </Link>
        </div>
        <h1 className="subtitle">
          {recipe.name}
          {pronunciation && (
            <a href={pronunciation.url} id="pronounce" onClick={(event) => playItHere(event, event.target.href)}>
              🗣
            </a>
          )}
        </h1>

        {state.modalInfo && (
          <Modal title={state.modalInfo.title} details={state.modalInfo.details} onClose={(e) => this.setState({ modalInfo: null })} />
        )}

        {state.showShopping && (
          <AddToShopping
            ingredients={tickedIngredients}
            isAllIngredients={this.state.tickedIngredientIndexes.length === 0}
            onClose={(e) => this.setState({ showShopping: false })}
            recipeId={props.recipeId}
            recipeName={recipe.name}
          />
        )}

        {state.cookingMode && (
          <div className="item">
            <p style={{ fontSize: '2rem' }}>{instructionSteps[state.instructionIndex]}</p>
            <div>
              <button
                className="button"
                onClick={(e) => this.setState({ instructionIndex: state.instructionIndex - 1 })}
                disabled={state.instructionIndex === 0}
              >
                Prev
              </button>
              &nbsp;
              <button
                className="button"
                onClick={(e) => this.setState({ instructionIndex: state.instructionIndex + 1 })}
                disabled={state.instructionIndex + 1 === instructionSteps.length}
              >
                Next
              </button>
            </div>
            <div className="hover-button-wrapper">
              {recipe.instructions && (
                <button onClick={(e) => this.setState({ cookingMode: false })} className="hover-button">
                  <span className="hover-button-icon">x</span>
                </button>
              )}
            </div>
          </div>
        )}

        {!state.showEditForm && !state.cookingMode && (
          <div>
            {recipe.intro && (
              <div className="item">
                <div style={{ paddingTop: '15px', paddingBottom: '15px', fontSize: '1.2rem' }}>{recipe.intro}</div>
              </div>
            )}

            {recipe.images.length > 0 && (
              <div style={{ backgroundColor: 'white' }}>
                <div
                  className="is-hidden-touch"
                  style={{
                    height: '200px',
                    backgroundImage: `url(${IMAGE_API}/800x800/${recipe.images[0].id}.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></div>
                <img className="is-hidden-desktop" src={IMAGE_API + '/800x800/' + recipe.images[0].id + '.jpg'} alt={recipe.name} />
              </div>
            )}

            <div className="item">
              {!!recipe.serves && (
                <div className="serves">
                  <span className="clickable" onClick={(e) => this.setState({ showServeAdjustment: !state.showServeAdjustment })}>
                    Serves {recipe.serves}&nbsp;
                    <Icon width="10" height="10" id={!state.showServeAdjustment ? 'next' : 'previous'} />
                  </span>
                  {state.showServeAdjustment && (
                    <div className="aside">
                      <p>Change the number of serves:</p>
                      &nbsp;
                      {recipe.serves > 1 && (
                        <button className="button is-small" onClick={(e) => this.halfServes()}>
                          half
                        </button>
                      )}
                      &nbsp;
                      {recipe.serves > 1 && (
                        <button className="button is-small" onClick={(e) => this.removeServe()}>
                          remove one
                        </button>
                      )}
                      &nbsp;
                      <button className="button is-small" onClick={(e) => this.addServe()}>
                        add one
                      </button>
                      &nbsp;
                      <button className="button is-small" onClick={(e) => this.doubleServes()}>
                        double
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div>
                {recipe.preparationTime && <p>Preparation time: {recipe.preparationTime}</p>}
                {recipe.cookingTime && <p>Cooking time: {recipe.cookingTime}</p>}
              </div>

              {this.renderIngredients(recipe.ingredients, props.username)}

              {recipe.instructions && (
                <div>
                  <h3 className="subtitle is-6 text-section-header">Instructions</h3>
                  <div
                    className="instructions"
                    dangerouslySetInnerHTML={{ __html: recipe.instructionsAsHtml() }}
                    onClick={(e) => {
                      if (e.target.localName === 'span' && e.target.className === 'clickable-text') {
                        this.setState({
                          modalInfo: {
                            title: e.target.innerHTML.toLowerCase(),
                            details: infoForIngredientFormatted(e.target.innerHTML),
                          },
                        })
                      }
                    }}
                  ></div>
                </div>
              )}

              {recipe.tags.length > 0 && (
                <div>
                  <h3 className="subtitle is-6 text-section-header">Tags</h3>
                  <div className="tags">
                    {recipe.tags.sort(stringComparator).map((tag) => {
                      return (
                        <span key={tag} className="tag">
                          {tag}
                        </span>
                      )
                    })}
                  </div>
                </div>
              )}

              {recipe.source && (
                <div>
                  <h3 className="subtitle is-6 text-section-header">Source</h3>
                  <p style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: urlsToHyperlinks(recipe.source) }}></p>
                </div>
              )}

              {recipe.relatedRecipes && recipe.relatedRecipes.length > 0 && (
                <div>
                  <h3 className="subtitle is-6 text-section-header">Related</h3>
                  {recipe.relatedRecipes.map((relatedRecipeId) => {
                    const recipe = props.allRecipesById[relatedRecipeId]
                    if (!recipe) return null
                    return (
                      <div key={recipe.id} className="list-item">
                        <Link to={'/recipe/' + recipe.id}>
                          <div className="columns is-mobile">
                            <div className="column is-narrow" width="100px" style={{ minWidth: '124px' }}>
                              {recipe.images && recipe.images.length > 0 && (
                                <img
                                  style={{ paddingLeft: '-10px' }}
                                  width="100px"
                                  src={IMAGE_API + '/100x100/' + recipe.images[0].id + '.jpg'}
                                  alt={recipe.name}
                                />
                              )}
                            </div>
                            <div className="column">
                              <p className="emphasised">{recipe.name}</p>
                              <p className="less-important">{recipe.intro}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>

            <div className="hover-button-wrapper">
              {props.allowEdit && (
                <button onClick={(e) => this.setState({ showEditForm: true })} className="hover-button">
                  <span className="hover-button-icon">
                    <Icon width="16" height="16" id="edit" />
                  </span>
                </button>
              )}
              {recipe.ingredients.length > 0 && (
                <button onClick={(e) => this.setState({ showShopping: true })} className="hover-button">
                  <span className="hover-button-icon">
                    <Icon width="20" height="20" id="shop" />
                  </span>
                </button>
              )}
              <Link to={'/mealPlanner/add/' + props.recipeId}>
                <button alt="Add to Meal Plan" className="hover-button">
                  <span className="hover-button-icon">
                    <Icon width="20" height="20" id="calendar-simple" />
                  </span>
                </button>
              </Link>
              {props.username === 'carwinz@gmail.com' && instructionSteps.length > 0 && (
                <button onClick={(e) => this.setState({ cookingMode: true })} className="hover-button">
                  <span className="hover-button-icon">c</span>
                </button>
              )}
            </div>
          </div>
        )}

        {state.showEditForm && (
          <Edit allTags={props.allTags} recipe={props.rawRecipe} onFinished={(e) => this.setState({ showEditForm: false })} />
        )}
      </div>
    )
  }
}

export default withRouterParams(
  connect((state, ownProps) => {
    const recipeId = ownProps.params.id
    let recipe = state.recipe.byId[recipeId]
    let allowEdit = true
    if (!recipe) {
      recipe = new SharedRecipeSelector(state).get(recipeId)
      allowEdit = false
    }

    if (!recipe) {
      return { recipeId, settings: {} }
    }

    const recipeOverride = Object.values(state.recipeOverride.byId).find((ur) => ur.recipeId === recipeId) || {
      recipeId: recipeId,
      id: generateId(),
    }

    return {
      recipeId,
      rawRecipe: new RawRecipe(
        recipe.id,
        recipe.name,
        recipe.intro,
        recipe.ingredients,
        recipe.instructions,
        recipe.notes,
        recipe.serves ? parseInt(recipe.serves, 10) : null,
        recipe.preparationTime,
        recipe.cookingTime,
        recipe.source,
        recipe.images,
        recipe.tags || [],
        recipe.relatedRecipes,
        recipe.serverLastUpdated || null
      ),
      allTags: new RecipeSelector(state).tags(),
      allRecipesById: state.recipe.byId,
      settings: { temperatureUnit: state.setting.byName['recipe.temperatureUnit'] },
      recipeOverride,
      allowEdit,
      username: new AuthSelector(state).username(),
    }
  })(RecipeShow)
)
