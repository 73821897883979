import BaseShoppingItem from "./baseShoppingItem"

class TextualShoppingItem extends BaseShoppingItem {

  constructor(attributes) {
    const {id, category, userId, lastUpdated, serverLastUpdated, shoppingListId, completedAt, title, url, shop, image, outOfDate, recipeId, recipeName, archived, quantity} = attributes
    super(id, category, userId, lastUpdated, serverLastUpdated, shoppingListId, completedAt, outOfDate, recipeId, recipeName, archived, quantity)
    this.title = title
    this.url = url
    this.image = image
    this.shop = shop
  }

  getTitle() {
    return this.title
  }

  getUrl() {
    return this.url
  }

  getPrettyHost() {
    let url = this.getUrl().trim()
    try {
      if (url === 'Www.Bunnings.com.au') {
        url = "https://www.Bunnings.com.au"
      }
      if (url.startsWith('www.')) {
        url = "https://" + url
      }
      return url ? new URL(url).hostname.replace('www.', '') : null
    } catch (e) {
      console.error("Could not get pretty hostname from", url, e)
      return null
    }
  }

  getShop() {
    return this.shop
  }

  getPrice() {
    return null
  }

  getIsOnSpecial() {
    return null
  }

  getImage() {
    return this.image
  }

  toStorageFormat() {
    const {id, category, userId, lastUpdated, serverLastUpdated, shoppingListId, completedAt, title, url, shop, image, outOfDate, recipeId, recipeName, archived, quantity} = this
    return {id, category, userId, lastUpdated, serverLastUpdated, shoppingListId, completedAt, title, url, shop, image, outOfDate, recipeId, recipeName, archived, quantity}
  }

  clone() {
    return new TextualShoppingItem(this.toStorageFormat())
  }
}

export default TextualShoppingItem
