import {mapListById, separate} from '../js-lib/array-helpers'
import MealPlan from './meal-plan'

const defaultState = {
  byId: {},
}

const migrateData = mealPlans => {
  return mealPlans.map(mealPlan => {
    return new MealPlan({ ...mealPlan, dishes: mealPlan.dishes.map(dish => {
      if (typeof dish == "string") {
        return { type: 'plainText', text: dish }
      }
      return dish
    })})
  })
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return defaultState
    case 'MEAL_PLANS_FETCHED':
      const mealPlans = migrateData(action.objects.map(o => new MealPlan(o)))
      const [archived, nonArchived] = separate(mealPlans, o => o.archived === true)
      const result = { ...state, byId: { ...state.byId, ...mapListById(nonArchived) } }
      for (let arch of archived) {
        delete result.byId[arch.id]
      }
      return result
    default:
      return state
  }
}

export default reducer
