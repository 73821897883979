import Recipe from './recipe'
import {extractIngredientAmounts, replaceIngredientAmounts} from './recipe-inference'
import {parse} from './ingredient-parser'
import {round} from './ingredient-amount-translator'
import IngredientAmount from './ingredient-amount'
import { makeUnitSingular } from './unit-helpers'

const adjustServe = (ingredientAmount, serveFraction) => {
  if (!ingredientAmount.measurements || ingredientAmount.measurements.length === 0) {
    return ingredientAmount
  }
  const measurements = ingredientAmount.measurements.map(m => m * serveFraction)
  const newIngredientAmount = new IngredientAmount(measurements, ingredientAmount.unit ? makeUnitSingular(ingredientAmount.unit) : ingredientAmount.unit, ingredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, ingredientAmount.extraUnitInfo, ingredientAmount.notes)
  return new IngredientAmount(newIngredientAmount.measurements.map(m => round(m)), newIngredientAmount.unit ? makeUnitSingular(newIngredientAmount.unit) : newIngredientAmount.unit, newIngredientAmount.ingredient, ingredientAmount.alternativeMeasurementsAndUnits, ingredientAmount.extraUnitInfo, ingredientAmount.notes)
}

const fahrenheit = 'f'
const celsius = 'c'
const fahrenheitPattern = /(\d+)( )?(°|degrees)?( )?F(\b|ahrenheit)/g
const celsiusPattern = /(\d+)( )?(°|degrees)?( )?C(\b|elsius)/g
const celsiusToFahrenheit = (celsius) => celsius * 9 / 5 + 32
const fahrenheitToCelsius = (fahrenheit) => (fahrenheit - 32) * 5 / 9

export const findTemperatureUnits = (text) => {
  const units = []
  if (text.match(celsiusPattern)) {
    units.push(celsius)
  }
  if (text.match(fahrenheitPattern)) {
    units.push(fahrenheit)
  }
  return units
}

const formatTemperatureUnit = (text, unit) => {
  let result = text

  if (unit === celsius) {
    result = text.replace(celsiusPattern, (match, groups) => {
      return groups + '°C'
    })
    result = result.replace(fahrenheitPattern, (match, groups) => {
      return round(fahrenheitToCelsius(parseInt(groups, 10))) + '°C'
    })
  }
  if (unit === fahrenheit) {
    result = text.replace(celsiusPattern, (match, groups) => {
      return round(celsiusToFahrenheit(parseInt(groups, 10))) + '°F'
    })
  }
  return result
}

export const build = (rawRecipe, options = {}) => {
  let ingredients = []
  let instructions = rawRecipe.instructions
  const serveFraction = rawRecipe.serves && options.wantedServes ? options.wantedServes / rawRecipe.serves : 1

  if (rawRecipe.ingredients) {
    let group = null
    ingredients = rawRecipe.ingredients.split('\n')
      .filter(i => i.trim() !== '')
      .map(line => {
        if (line.startsWith('# ') || line.startsWith('## ')) {
          group = line.replace('## ', '').replace('# ', '')
          return null
        }
        let ia = adjustServe(parse(line), serveFraction)
        if (group) {
          ia.group = group
        }
        return ia
      })
      .filter(ia => !!ia)
  }

  if (instructions) {
    let {ingredientAmounts, matches} = {...extractIngredientAmounts(instructions)}
    ingredientAmounts = ingredientAmounts.map(ingredientAmount => {
      return adjustServe(ingredientAmount, serveFraction)
    })
    ingredients = ingredients.concat(ingredientAmounts)
    instructions = replaceIngredientAmounts(ingredientAmounts, matches, instructions)

    if (options.temperatureUnit) {
      instructions = formatTemperatureUnit(instructions, options.temperatureUnit)
    }
  }

  return new Recipe(
    rawRecipe.name,
    rawRecipe.intro,
    ingredients,
    instructions,
    rawRecipe.notes,
    rawRecipe.serves * serveFraction,
    rawRecipe.preparationTime,
    rawRecipe.cookingTime,
    rawRecipe.source,
    rawRecipe.images || [],
    rawRecipe.tags,
    rawRecipe.relatedRecipes,
  )
}
