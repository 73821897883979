import React from 'react'
import { Link } from 'react-router-dom'

class More extends React.Component {
  render() {
    const { props, state } = this
    return (
      <div>
        <div className="list-item">
          <Link to="/more/seasonalProduce">Seasonal Produce</Link>
        </div>
        <div className="list-item">
          <Link to="/more/cookingInfo">Cooking info</Link>
        </div>
        <div className="list-item">
          <Link to="/shoppingList/plan">Plan shopping list</Link>
        </div>
      </div>
    )
  }
}

export default More
