import React from 'react'
import { connect } from 'react-redux'
import { fetchShoppingListDetails } from '../api'
import notie from 'notie'
import { withoutValue } from '../../js-lib/array-helpers'
import AuthSelector from '../../app/AuthSelector'

import { setSetting } from '../../app/settings'
import { getAuthToken } from '../../app/auth-helpers'

import { GROCER, GROUP_BY_CATEGORY } from './settingsConstants'
import { grocerNames } from '../../grocer'

class Settings extends React.Component {
  constructor(props) {
    super(props)
    this.state = { shareWith: '' }
  }

  async componentWillMount() {
    const details = await fetchShoppingListDetails()
    this.setState({ listDetails: details })
  }

  async handleShareList(email) {
    if (email) {
      email = email.trim()
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      notie.alert({ type: 'error', text: 'That email address does not appear to be valid.' })
      return
    }

    if (email === this.props.username) {
      notie.alert({ type: 'error', text: "You can't invite yourself!" })
      return
    }

    let invite = {
      type: 'shoppingList',
      inviteeUsername: email,
    }
    await window.storageService.store('invite', [invite])

    notie.alert({ type: 'success', text: 'Successfully invited ' + email + ' to share your shopping list.' })
  }

  async handleChange(property, newValue) {
    const setting = await setSetting(property, newValue)
    this.props.settingsFetched([setting])
  }

  render() {
    const { props, state } = this
    const authToken = getAuthToken()
    return (
      <div>
        <h4 className="subtitle is-4">Shopping list settings</h4>

        <div className="padded-below">
          <form onSubmit={(e) => e.preventDefault() || this.handleShareList(this.state.shareWith)}>
            <h5 className="subtitle is-5">Share list</h5>
            <p>
              People can share shopping lists. If you'd like to share a list with someone, enter their email address and an invitation will
              be sent to them. If they accept your invitation, their items will be merged with yours.
            </p>
            {authToken && (
              <input
                id="shareList"
                autoComplete="off"
                type="email"
                placeholder="Email address"
                className="input"
                value={state.shareWith}
                onInput={(e) => this.setState({ shareWith: e.target.value })}
              />
            )}

            {!authToken && (
              <div style={{ marginBottom: '10px', padding: '10px', borderRadius: '5px', backgroundColor: '#e5795c', color: 'white' }}>
                Only available after you are logged in
              </div>
            )}

            {state.listDetails && state.listDetails.users && state.listDetails.users.length > 1 && (
              <div style={{ marginTop: '10px' }}>
                Your list is currently shared with:{' '}
                <ul>
                  {withoutValue(state.listDetails.users, props.username).map((user) => {
                    return (
                      <div key={user}>
                        <li>&middot; {user}</li>
                      </div>
                    )
                  })}
                </ul>
              </div>
            )}
          </form>
        </div>

        {/*
        <div className="padded-below">
          <h5 className="subtitle is-5">Sort</h5>
          <div className="select">
            <select disabled="disabled">
              <option value="alpha">Alphabetically</option>
              <option value="youngest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </div>
        */}

        <div className="padded-below">
          <h5 className="subtitle is-5">Grouping</h5>
          <input
            checked={props.settings[GROUP_BY_CATEGORY]}
            id="grouped"
            type="checkbox"
            className="checkbox"
            onChange={(e) => this.handleChange(GROUP_BY_CATEGORY, e.target.checked)}
          />{' '}
          <label htmlFor="grouped">Group by category (e.g. Dairy, Baking, etc)</label>
        </div>

        <div className="padded-below">
          <h5 className="subtitle is-5">Grocer</h5>
          <div className="select">
            <select value={props.settings[GROCER]} onChange={(e) => this.handleChange(GROCER, e.target.value)}>
              <option value=""></option>
              {grocerNames().map((grocerName) => {
                return (
                  <option key={grocerName} value={grocerName}>
                    {grocerName}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        {/*
        <div className="padded-below">
          <input disabled="disabled" id="merge" type="checkbox" className="checkbox" /> <label htmlFor="merge">Merge multiple instances of the same item </label>
        </div>
        */}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    settingsFetched: (settings) => {
      dispatch({ type: 'SETTINGS_FETCHED', objects: settings })
    },
  }
}

export default connect((state, ownProps) => {
  return {
    username: new AuthSelector(state).username(),
    settings: {
      [GROUP_BY_CATEGORY]: state.setting.byName[GROUP_BY_CATEGORY],
      [GROCER]: state.setting.byName[GROCER],
    },
  }
}, mapDispatchToProps)(Settings)
