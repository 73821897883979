
export const formatNumber = (amount, digits = 2) => {
  if (!amount && amount !== 0) {
    return ''
  }
  var formatter = new Intl.NumberFormat('en-AU', {
    style: 'decimal',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });

  return formatter.format(amount/100);
}

export const formatCurrency = (currency, amount) => {
  if (!amount && amount !== 0) {
    return ''
  }
  var formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
  });

  return formatter.format(amount/100);
}
