
import {buildDate} from '../js-lib/date-helpers'

export default class MealPlan {

  constructor(values) {
    ['id', 'lastUpdated', 'serverLastUpdated', 'day', 'month', 'year', 'meal', 'dishes', 'archived'].forEach(field => {
      this[field] = values[field]
    })
  }

  getDate() {
    return buildDate(this.year, this.month, this.day)
  }
}
