import notie from 'notie'
import React from 'react'
import { connect } from 'react-redux'
import { setSetting } from '../../app/settings'
import RecipeSelector from '../selector'
import AuthSelector from '../../app/AuthSelector'
import { extractData } from '../../js-lib/auth/jwt-helpers'
import { getAuthToken } from '../../app/auth-helpers'

class RecipeSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = { shareWith: '' }
  }

  async handleChange(property, newValue) {
    const setting = await setSetting(property, newValue)
    this.props.settingsFetched([setting])
  }

  async handleShareRecipes(email) {
    if (email) {
      email = email.trim()
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      notie.alert({ type: 'error', text: 'That email address does not appear to be valid.' })
      return
    }

    if (email === this.props.username) {
      notie.alert({ type: 'error', text: "You can't invite yourself!" })
      return
    }

    let invite = {
      type: 'recipe',
      inviteeUsername: email,
    }
    await window.storageService.store('invite', [invite])

    notie.alert({ type: 'success', text: 'Successfully invited ' + email + ' to share your recipes.' })
  }

  render() {
    const { props, state } = this
    const authToken = getAuthToken()
    return (
      <div>
        <h4 className="subtitle is-4">Recipe settings</h4>

        <div className="padded-below">
          <span>On recipe listing, show </span>
          <div className="select">
            <select
              value={props.settings['recipe.listingPageContent']}
              onChange={(e) => this.handleChange('recipe.listingPageContent', e.target.value)}
            >
              <option value="alphabetically">alphabetical listing</option>
              <option value="recentlyViewed">most recently viewed</option>
              <option value="recentlyUpdated">most recently updated</option>
              <option value="withTag">recipes with tag</option>
            </select>
          </div>
          &nbsp;
          {props.settings['recipe.listingPageContent'] === 'withTag' && (
            <div className="select">
              <select
                value={props.settings['recipe.listingPageContentTag']}
                onChange={(e) => this.handleChange('recipe.listingPageContentTag', e.target.value)}
              >
                <option value=""></option>
                {props.tags.map((tag) => {
                  return <option value={tag}>{tag}</option>
                })}
              </select>
            </div>
          )}
        </div>

        <div className="padded-below">
          <span>Show temperatures </span>
          <div className="select">
            <select
              value={props.settings['recipe.temperatureUnit']}
              onChange={(e) => this.handleChange('recipe.temperatureUnit', e.target.value)}
            >
              <option value="">without change</option>
              <option value="c">in Celsius</option>
              <option value="f">in Fahrenheit</option>
            </select>
          </div>
        </div>

        <div className="padded-below">
          <form onSubmit={(e) => e.preventDefault() || this.handleShareRecipes(this.state.shareWith)}>
            <h5 className="subtitle is-5">Share list</h5>
            <p>
              You can share your recipes with specific people. If you'd like to share with someone, enter their email address and an
              invitation will be sent to them. If they accept your invitation, they will be able to see all your recipes, but not change
              them.
            </p>
            {authToken && (
              <input
                id="shareList"
                autoComplete="off"
                type="email"
                placeholder="Email address"
                className="input"
                value={state.shareWith}
                onInput={(e) => this.setState({ shareWith: e.target.value })}
              />
            )}

            {!authToken && (
              <div style={{ marginBottom: '10px', padding: '10px', borderRadius: '5px', backgroundColor: '#e5795c', color: 'white' }}>
                Only available after you are logged in
              </div>
            )}

            {props.shareDetails && props.shareDetails.length > 0 && (
              <div style={{ marginTop: '10px' }}>
                You can currently see recipes from:{' '}
                <ul>
                  {props.shareDetails.map((recipeShare) => {
                    return (
                      <div key={recipeShare.sharingUsername}>
                        <li>&middot; {recipeShare.sharingUsername}</li>
                      </div>
                    )
                  })}
                </ul>
              </div>
            )}
          </form>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    settingsFetched: (settings) => {
      dispatch({ type: 'SETTINGS_FETCHED', objects: settings })
    },
  }
}

export default connect((state, ownProps) => {
  const authSelector = new AuthSelector(state)
  let shareDetails = null
  const authToken = authSelector.token()
  if (authToken) {
    shareDetails = extractData(authToken).recipesSharedWithMe || []
  }

  return {
    username: authSelector.username(),
    shareDetails,
    tags: new RecipeSelector(state).tags(),
    settings: {
      'recipe.temperatureUnit': state.setting.byName['recipe.temperatureUnit'],
      'recipe.listingPageContent': state.setting.byName['recipe.listingPageContent'],
      'recipe.listingPageContentTag': state.setting.byName['recipe.listingPageContentTag'],
    },
  }
}, mapDispatchToProps)(RecipeSettings)
