import BaseSelector from '../app/BaseSelector'
import GrocerProduct from '../shoppingList/domain/grocerProduct'

class GrocerProductSelector extends BaseSelector {
  all = (grocerId) => {
    return Object.values(this.state.grocerProduct.byId)
      .filter(gp => gp.grocerId === grocerId)
      .map(g => new GrocerProduct(g.id, g.grocerId, g.name, g.url, g.image, g.category, g.grocerProductId,
        g.price, g.onSpecial, g.previousPrice, g.shoppingListId, g.serverLastUpdated, g.lastUpdated))
  }
}

export default GrocerProductSelector
