import { Route } from 'react-router-dom'
import HomeScreen from '../recipe/ui/HomeScreen'
import RecipeShow from '../recipe/ui/RecipeShow'
import RecipeSettings from '../recipe/ui/RecipeSettings'
import AuthenticationForm from '../auth/AuthenticationFormWrapper'
import SignupForm from '../auth/SignupForm'
import More from '../More'
import CookingInfoList from '../cookingInfo/list'
import { SeasonalProduceList } from '../seasonal/List'
import EatingPlanShow from '../eatingPlan/ui/EatingPlanShow'
import EatingPlanSettings from '../eatingPlan/ui/Settings'
import { ShoppingList, ShoppingListSettings } from '../shoppingList/ui'
import Plan from '../shoppingList/ui/Plan'
import GrocerCatalogue from '../grocer/ui/GrocerCatalogue'
import GrocerProductEdit from '../grocer/ui/GrocerProductEdit'

const routes = (layout: any) => {
  return (
    <Route path="/" element={layout}>
      <Route index element={<HomeScreen />} />
      <Route path="login" element={<AuthenticationForm />} />
      <Route path="signup" element={<SignupForm />} />
      <Route path="recipe/:id" element={<RecipeShow />} />
      <Route path="recipeSettings" element={<RecipeSettings />} />
      <Route path="more" element={<More />} />
      <Route path="more/cookingInfo" element={<CookingInfoList />} />
      <Route path="more/seasonalProduce" element={<SeasonalProduceList />} />
      <Route path="mealPlanner" element={<EatingPlanShow />} />
      <Route path="mealPlanner/add/:recipeId" element={<EatingPlanShow />} />
      <Route path="mealPlannerSettings" element={<EatingPlanSettings />} />
      <Route path="shoppingList" element={<ShoppingList />} />
      <Route path="shoppingList/plan" element={<Plan />} />
      <Route path="shoppingListSettings" element={<ShoppingListSettings />} />
      <Route path="grocer/products/list" element={<GrocerCatalogue />} />
      <Route path="grocer/products/add" element={<GrocerProductEdit />} />
      <Route path="grocer/products/edit/:id" element={<GrocerProductEdit />} />
    </Route>
  )
}

export default routes
