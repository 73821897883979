
const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const padWithTwoZeros = (value) => {
  let result = value.toString()
  if (result.length === 1) {
    return "0" + result
  }
  return result
}

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export const formatPersonFriendly = (date) => {
  if (date.getYear() === new Date().getYear()) {
      return date.getDate() + ' ' + monthAbbreviations[date.getMonth()]
  } else {
    return date.getDate() + ' ' + monthAbbreviations[date.getMonth()] + ' ' + (date.getYear() + 1900)
  }
}

export const formatForAussies = (date) => {
  return date.getDate() + '/' + (date.getMonth()+1) + '/' + (date.getYear() + 1900)
}

export const formatShortIso = (date) => {
  return "" + date.getFullYear() + '-' + padWithTwoZeros(date.getMonth() + 1) + '-' + padWithTwoZeros(date.getDate());
}

export const formatDayMonthYear = (date) => {
  return padWithTwoZeros(date.getDate()) + '/' + padWithTwoZeros(date.getMonth() + 1) + '/' + (date.getYear() + 1900)
}

export const formatMonthYear = (date) => {
  return monthAbbreviations[date.getMonth()] + ' ' + date.getFullYear();
}

export const formatMonth = (date) => {
  return monthAbbreviations[date.getMonth()];
}

export const formatIcs = (date) => {
  return (date.getYear() + 1900) + "" + padWithTwoZeros(1 + date.getMonth()) + padWithTwoZeros(date.getDate()) + 'T110000'
}
