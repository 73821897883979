import { combineReducers } from 'redux'
import auth from './auth'
import recipe from '../../recipe/reducer'
import sharedRecipe from '../../recipe/sharedRecipeReducer'
import recipeView from '../../recipe/recipe-view-reducer'
import recipeOverride from '../../recipe/recipe-override-reducer'
import mealPlan from '../../eatingPlan/meal-plan-reducer'
import shoppingItem from '../../shoppingList/reducer'
import grocerProduct from '../../grocer/reducer'
import setting from './setting'
import invite from './invite'

const combinedReducer = combineReducers({
  auth,
  invite,
  grocerProduct,
  recipe,
  recipeView,
  sharedRecipe,
  mealPlan,
  setting,
  shoppingItem,
  recipeOverride,
})

function crossSliceReducer(state, action) {
  switch(action.type) {
    default: {
      return state
    }
  }
}

export default function rootReducer(state, action) {
  const intermediateState = combinedReducer(state, action);
  const finalState = crossSliceReducer(intermediateState, action);
  return finalState;
}
