
const SINGULAR_TRANSLATIONS = {
  'bunch': 'bunches',
  'box': 'boxes',
  'pinch': 'pinches',
}

const PLURAL_TRANSLATIONS = {
  'bunches': 'bunch',
  'boxes': 'box',
  'pinches': 'pinch',
}

export const unitWithCorrectPlurality = (measurements, unit) => {
  return measurements.length === 1 && measurements[0] === 1 ? makeUnitSingular(unit) : makeUnitPlural(unit)
}

export const makeUnitPlural = (unit) => {
  if (['dozen'].indexOf(unit) > -1) {
    return unit
  }

  const hardcodedTranslation = SINGULAR_TRANSLATIONS[unit]
  if (hardcodedTranslation) {
    return hardcodedTranslation
  }
  if (!unit.endsWith('s')) {
    return unit + 's'
  }
  return unit
}

export const makeUnitSingular = (unit) => {
  const hardcodedTranslation = PLURAL_TRANSLATIONS[unit]
  if (hardcodedTranslation) {
    return hardcodedTranslation
  }

  if (unit.endsWith('s')) {
    return unit.substring(0, unit.length - 1)
  }
  return unit
}
