import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const IMAGE_API = process.env.IMAGE_API_URL || 'https://images.palatable.kitchen'

class MealPlanShow extends React.Component {
  render() {
    const { props } = this
    const { mealPlan, onTick } = props
    let imageUrl = null
    const dishWithRecipe = mealPlan.dishes.find((d) => d.recipeId)
    if (dishWithRecipe) {
      const recipe = props.recipesById[dishWithRecipe.recipeId]
      if (recipe && recipe.images && recipe.images.length > 0) {
        imageUrl = IMAGE_API + '/100x100/' + recipe.images[0].id + '.jpg'
      }
    }

    return (
      <div className={'meal meal-' + mealPlan.meal} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
        {onTick && (
          <div style={{ width: '20px' }}>
            <input type="checkbox" onClick={onTick} />
          </div>
        )}
        {imageUrl && (
          <div style={{ width: '60px' }}>
            <img style={{ paddingLeft: '-10px', paddingRight: '10px' }} src={imageUrl} alt="preview" />
          </div>
        )}
        <div>
          <div className="meal-name">{mealPlan.meal}</div>
          <div>
            {mealPlan.dishes.map((dish, index) => {
              const recipe = dish.recipeId ? props.recipesById[dish.recipeId] : null
              return (
                <div key={index}>
                  {dish.type === 'plainText' && <div>{dish.text}</div>}
                  {dish.type === 'recipe' && (
                    <div>
                      {recipe && (
                        <div>
                          <Link to={'/recipe/' + recipe.id}>{recipe.name}</Link>
                        </div>
                      )}
                      {!recipe && <div>{dish.recipeName || dish.recipeId}</div>}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state, ownProps) => {
  return {
    recipesById: { ...state.recipe.byId, ...state.sharedRecipe.byId },
  }
})(MealPlanShow)
