import { HeadersBuilder, _handleError, _verifyResponse } from '../app/api-helpers.js'
const API = process.env.REACT_APP_API_URL || 'https://api.palatable.kitchen'

export const fetchShoppingListDetails = () => {
  return fetch(`${API}/api/shoppingList`, {
    headers: new HeadersBuilder().auth().json().build(),
    mode: 'cors',
  }).then(_verifyResponse, _handleError)
}

export const acceptInvite = (inviteId) => {
  return fetch(`${API}/api/invite/acceptInvite`, {
    method: 'POST',
    body: JSON.stringify({ id: inviteId }),
    headers: new HeadersBuilder().auth().json().build(),
    mode: 'cors',
  }).then(_verifyResponse, _handleError)
}

export const declineInvite = (inviteId) => {
  return fetch(`${API}/api/invite/declineInvite`, {
    method: 'POST',
    body: JSON.stringify({ id: inviteId }),
    headers: new HeadersBuilder().auth().json().build(),
    mode: 'cors',
  }).then(_verifyResponse, _handleError)
}
