import BaseSelector from '../app/BaseSelector'

class SharedRecipeSelector extends BaseSelector {
  list = () => {
    return Object.values(this.state.sharedRecipe.byId)
  }
  get = (id) => {
    return this.state.sharedRecipe.byId[id]
  }
}

export default SharedRecipeSelector
