const info = {
  'self-raising flour': {
    d: 'Self-raising flour is all-purpose flour, with the addition of a leavening agent such as bicarb soda. And sometimes, salt'
  },
  'bicarb soda': {
    d:'Bicarbonate of soda is a leavening agent which needs to be mixed with moisture and an acidic ingredient. This causes a chemical reaction and creates carbon dioxide which causes the food to rise. For this reason, it\'s often used in recipes where there is already an acidic ingredient present, such as lemon juice, chocolate, buttermilk or honey.'
  },
  'baking powder': {
    d: 'Baking powder is made up sodium bicarbonate, an acid (often cream of tartar), and a filler like cornflour to absorb any moisture. All you need to add is the moisture',
  },
  'masarepa': {
    d: '<p>Masarepa is precooked, ground corn flour that is used to prepare arepas.</p><p>Masarepa is dried and ground into fine cornmeal and is easily confused with Masa harina which is made from corn treated with lye in a process called nixtamalization in order to remove the germ and outer lining before it is ground. Masa harina is used for making tortillas, tamales, pupusas, and gorditas and translates as "dough flour."</p>'
  },
  'arepa': {
    d: 'A circular bread/pastry made from maize flour or corn meal. Very common in Colombia and Venezuela'
  },
  'al dente': {
    d: 'Italian phrase meaning "to the tooth," used to describe pasta or other food that is cooked only until it offers slight resistance when bitten into.'
  },
  'au gratin': {
    d: 'A dish that is topped with cheese or a mixture of breadcrumbs and butter, then heated in the oven or under the broiler until brown and crispy'
  },
  'au jus': {
    d: 'French phrase describing meat that is served with its own natural cooking juices.'
  },
  'au lait': {
    d: 'French for "with milk"'
  },
  'broth': {
    d: 'A liquid made by cooking vegetables, poultry, meat, or fish. The flavored liquid is strained off after cooking.'
  },
  'braise': {
    d: 'A cooking method, on top of a stove or in the oven, in which food is browned in fat, and then cooked, tightly covered, in a small amount of liquid, at low heat for a long time.'
  },
  'broil': {
    d: 'To cook or brown food by placing it under the broiling unit in an oven. The broiling unit is usually at the top of the oven, but older ovens may have a broiler drawer underneath. Recipes often call for placing the food 4-6 inches away from the broiling unit.'
  },
  // 'brown': {
  //   d: 'To cook quickly over high heat, causing the surface of the food to turn brown while the interior stays moist.'
  // },
  'colombian quesito': {
    d: 'A creamy, semi-soft white cheese made by processing the curds of cow\'s milk. Similar to Mexican Queso Fresco.'
  },
  'caramelise': {
    d: 'To heat sugar until it liquefies and becomes a clear syrup ranging in color from golden to dark brown.',
    alt: ['caramelize']
  },
  'cornstarch': {
    d: '<p>Corn starch is starch from corn kernels. Most commonly used to thicken liquid for when you want richer soups, stews, or gravies.</p><p>Cornstarch is different from flour (another thickening agent) because it mixes in clear; flour lightens the mixture a little bit. But like flour, one of the biggest issues with using cornstarch is that it can form lumps if it’s not prepared correctly. To prevent lumps from forming, you have to mix the cornstarch with a cold liquid (usually water or milk) before adding it into the recipe.</p><p>In Australia (and the UK), cornstarch and corn flour often refer to the same thing.</p>',
    alt: ['maize starch']
  },
  'chiffonade': {
    d: 'A slicing technique in which leafy green vegetables are cut into long, thin strips. This is accomplished by stacking leaves, rolling them tightly, then slicing the leaves perpendicular to the roll.'
  },
  'kosher salt': {
    d: 'The larger size of the salt crystal is ideal for drawing out moisture from meat, making it perfect for use in the koshering process.'
  },
  'puree': {
    d: 'To mash a food to a smooth, thick consistency.'
  },
  'poaching': {
    d: 'Cooking by submerging food in a liquid, such as water, milk, stock or wine or in a tray. Poaching uses a relatively low temperature, thus differentiating it from simmering and boiling'
  },
  'roasting': {
    d: 'A dry heat cooking method where hot air covers the food, cooking it evenly on all sides with temperatures of at least 150 °C. Roasting can enhance the flavor through caramelization and Maillard browning on the surface of the food.'
  },
  'satue': {
    d: 'Fried quickly in a little hot oil or fat'
  },
  'sear': {
    d: 'To burn or scorch a food with an application of intense heat.'
  },
  'simmer': {
    d: 'To cook food gently in liquid at a temperature low enough that tiny bubbles just begin to break the surface'
  },
  'saute': {
    d: 'To cook in a small amount of fat over high heat, making sure that the food doesn\'t stick to the pan by making it "jump" in and out of the heat. The term comes from "sauter" -- the French word for jump. Sautéing is ideal for browning or searing food, especially vegetables.'
  },
  'caster sugar': {
    d: 'Refined white sugar often used in baking because of its light texture and ability to dissolve easily. Often used in meringues and pavlova.'
  },
  'brown sugar': {
    d: 'Partially refined sugar that has molasses added to it. Molasses gives it a distinct caramel flavour.'
  },
  'ground beef': {
    d: 'Beef finely chopped by a meat grinder or a chopping knife. Other types of meat are ground as well. Called mince or minced meat outside North America and keema or qeema in the Indian subcontinent.'
  },
  'granulated sugar': {
    d: 'Made from beet or cane sugar, this sugar has undergone a refining process. Larger grains than caster sugar. It is less likely to crystalize so it\'s  great when making toffee or in baking when your want a slightly coarser texture',
    alt: ['white sugar', 'table sugar']
  },
  'sour cream': {
    d: 'Includes active culture to give it a sour taste. Lower in fat than its sweeter counterparts. Use with baked potatoes or in cheesecakes, brownies, and Eastern European savoury cooking (e.g. stroganoffs and goulash)'
  },
  'thickened cream': {
    d: 'Ideal for whipping and for cake applications. Known as heavy cream in the US. Minimum fat content of 35%'
  },
  'heavy cream': {
    d: 'Ideal for whipping and for cake applications. Also known as thickened cream. Minimum fat content of 35%'
  },
  'double cream': {
    d: 'Ideal for dolloping on desserts or stirring into hot dishes such as goulash for a richer flavour. Has 45% to 60% milk fat, with no thickeners'
  },
  'cooking cream': {
    d: 'Designed so that it won\'t curdle or separate during cooking. It\'s great for pasta bakes and creamy soups'
  },
  'pure cream': {
    d: 'Best for quiches and pasta sauces. It has a milk fat content of 35% to 50% and contains no thickeners. Also known as pouring cream or single cream.'
  },
  'pouring cream': {
    d: 'Best for quiches and pasta sauces. It has a milk fat content of 35% to 50% and contains no thickeners. Also known as pure cream or single cream.'
  },
  'single cream': {
    d: 'Best for quiches and pasta sauces. It has a milk fat content of 35% to 50% and contains no thickeners. Also known as pure cream or pouring cream'
  },
  'quark': {
    d: 'Quark is said to be a cross between yoghurt and cottage cheese. It is a fresh, soft, white cheese prepared from pasteurised cow\'s milk with a small amount of rennet added to achieve a good, firm curd. It is moist, snowy white in colour with a subtle taste and smooth & soft texture. Its texture is similar to that of cream cheese, pot cheese or ricotta with a fat content ranging from low to medium.'
  },
  'greek yogurt':  {
    d: 'Greek yogurt is yogurt that has been strained to remove most of its whey, resulting in a thicker consistency than normal unstrained yogurt, while still preserving the distinctive sour taste of yogurt.',
    alt: ['strained yogurt', 'yogurt cheese', 'sack yogurt', 'kerned yogurt'],
  },
  'chipotle': {
    d: 'A smoke-dried ripe jalapeño chili pepper used for seasoning'
  },
  'decant': {
    d: 'Slow pour liquid from its original container into a different one. This is done to remove any sediment that\'s formed in the container.'
  },
  'parmesan': { d: 'A straw-coloured hard cheese with a natural yellow rind and rich, fruity flavour'},
  'monterey jack cheese': {
    d: 'An American white, semi-hard cheese made using cow\'s milk. It is noted for its mild flavor and slight sweetness. Subsitiutes: Havarti, Gouda, or Muenster'
  },
  'aubergine': {
    d: 'A fruit used as a vegetable.There are many varieties but they all have a bland, mildly smoky flavour and flesh that\'s spongy when raw but soft when cooked. Also known as eggplant.'
  },
  'eggplant': {
    d: 'A fruit used as a vegetable.There are many varieties but they all have a bland, mildly smoky flavour and flesh that\'s spongy when raw but soft when cooked. Also known as aubergine.'
  },
  'courgette': {
    d: 'A vegetable with deep green skin and firm pale flesh.It is from the same family as cucumber, squash and melon.Extremely versatile, tender and easy to cook - just don\'t boil them! Also known as zucchini.'
  },
  'zucchini': {
    d: 'A vegetable with deep green skin and firm pale flesh.It is from the same family as cucumber, squash and melon.Extremely versatile, tender and easy to cook - just don\'t boil them! Also known as courgette.'
  },
  'tomatillo': {
    d: '<p>They look like green, unripe tomatoes with a dry, leafy husk that wraps around the outside.</p><p>To prep tomatillos, peel the husk and rinse off the sticky residue it leaves behind. You don\'t need to remove the seeds. If eaten raw, tomatillos can be a little acidic and sharp-tasting. When cooked, their flavor tends to mellow, letting their sweeter side shine.</p><p>Tomatillo salsa is great! Or toss raw chopped tomatillos in salads, or roast or grill them whole and add them to salsas and dips. You can also cut them into wedges before stirring into stews and braises, or sauté them in small chunks and add them to omelets or scrambled eggs.</p>',
    alt: ['husk tomatoes', 'tomates verdes'],
  },
  'goulash': {
    d: 'A stew of meat and vegetables usually seasoned with paprika and other spices. Originating in medieval Hungary, goulash is a common meal predominantly eaten in Central Europe but also in other parts of Europe. It is one of the national dishes of Hungary and a symbol of the country. The literal translation of goulash, or gulyas in Hungarian is "herd of cattle" soup'
  },
  'blanch': {
    d: 'To  briefly immerse in boiling water, especially in order to remove the skin or to prepare it for further cooking.',
    alt: 'blanched'
  },
  'knead': {
    d: 'To work dough into a uniform mixture by pressing, folding and stretching with the hands.'
  },
  'kolach': {
    d: 'A type of pastry that holds a portion of fruit surrounded by puffy dough. Originated as a semisweet wedding dessert from Central Europe; later became a breakfast item in South London'
  },
  'marjoram': {
    d: 'A digestive and slightly minty herb that is a refreshing cousin of oregano'
  },
  'macerate': {
    d: 'Macerating is a technique that softens fresh fruit and draws out its natural juices, in which the fruit then soaks, sort of like marinating. '
  },
  'spring onion': {
    d: 'Have a sweeter, milder flavor than mature onions and are a bit stronger than chives. The white part has more flavor than the green leaves. The tops of these green onions may be used as a substitute for chives in many recipes.',
    alt: ['scallions', 'green onion']
  },
  'lemon zest': {
    d: 'The outermost layer of the lemon that has the flavour and no bitterness. It excludes the inner white fleshy layer that is bitter'
  },
  'cake flour': {
    d: '<p>Finely ground flour containing very low protein and it usually bleached. Because of its lower protein content, it produces less gluten. Ideal for cakes that require a fluffy texture and find crumb.</p><p>You generally can\'t buy it in Australia, but an alternative is this: start with one level cup of plain flour, remove 2 tablespoons and replace with cornstarch/arrowroot powder. Sift.</p>'
  },
  'all-purpose flour': {
    d: '<p>All-purpose flour is a general kind of white flour that is designed to be used in a variety of recipes. It is not a "whole" flour as it only uses a starchy part of the wheat kernel, the endosperm. Because it doesn\'t include the oil-contributing wheat germ, AP flour generally has a long shelf life.</p><p>All-purpose flour is actually a combination of hard flours (which have a high gluten content; often used in making chewy breaks) and soft flours (with a low gluten content; often used in soft, light, delicate cakes). Most AP flour has a rough protein/gluten content of about 8-11%, depending on the mix.</p>',
    alt: ['plain flour']
  },
  'cream of tartar': {
    d: '<p>An acid used as a leavener. Combined with backing soda, they make carbon dioxide gas (like yeast in bread). When added to egg whites, it boosts the strength of the individual air bubbles and slow down their natural tendency to deflate. When added to simple syrup, it prevent\'s sugar\'s natural tendency to re-bond and form crystals. It is made as a by-product in wine production.</p><p>Substitutes: for every 1/2 teaspoon of cream of tartar needed, use 1 teaspoon of lemon juice or white vinegar.</p>'
  },
  'caraway': {
    d: '<p>Caraway seeds are highly aromatic and have a distinctive mild anise flavor that adds a subtle licorice hint. Their taste is earthy, with a hint of citrus and pepper. They are actually the dried fruit of the caraway plant (which is in the carrot family).</p>' +
      '<p>They are frequently used in baking and often appear in rye bread. They are also used in flavoring curries, soups, sausages, vegetables, and even liqueurs</p>' +
      '<p>Caraway seeds pair well with garlic, pork, and cabbage. Uses include <ul><li>Add caraway seeds to potato salad or coleslaw</li><li>Add a pinch to any tomato- based sauce or soup</li><li>Sprinkle over roasted potatoes or sweet potatoes</li><li>Mix into cheese dip</li><li>Sprinkle onto baked apples to enhance the flavor</li><li>Add to shortbread cookies or Irish soda bread cookies</li><li>Use to flavor beef goulash or kielbasa stew</li><li>Add to any recipe that includes cabbage</li><li>Use with a pork roast or pork chops</li></ul>' +
      '<p>To remove the pungent anise flavour, toast caraway seeds for three minutes in a pan on low heat and stir frequently. Unground seeds might be unpleasant to chew, so place them in cheesecloth so they can be easily removed. Use a mortar and pestle to crush them.',
  },
  'bell pepper': {
    d: 'Called capsicum in Australia, India, New Zealand and Singapore',
    alt: ['capsicum']
  },
  'jalapeño': {
    d: 'a medium-sized chili pepper pod type cultivar of the species Capsicum annuum.',
    alt: ['jalapeno']
  },
  'white wine vinegar': {
    d: 'White wine that has been fermented and oxidized into an acid with a lightly fruity flavor. The distilling process usually takes place in stainless steel vats called acetators that expose the ethanol in the wine to oxygen. The resulting acetic acid is then diluted with water to a palatable acidity, somewhere between 5 and 7 percent.'
  },
  'marigold': {
    d: '<p>The flavour is earthy, spicy, with a hint of green apple and orange.</p><p>Frequently used in Georgian cooking, it plays a base note in the finished dish, lending a lingering brightness to chakhokhbili, chicken stew with tomato and herbs, spice blends, chili pastes, or walnut sauces.</p><p> It marries well with coriander, cumin, chili pepper and onions. You can also fry ground marigold in butter and use it to garnish vegetable soups. It doesn\'t have any good substitutes.</p>',
    alt: ['yellow marigold']
  },
  'blue fenugreek': {
    d: '<p>It belongs to the same family as the fenugreek used in Indian cooking, but blue fenugreek has a milder, sweeter flavor reminiscent of walnuts and autumnal leaves.</p><p>It is called utskho suneli or "suddenly appeared spice" in Georgian. It is picked towards the end of its bloom and the seed pods are dried. When whole, they have a mild scent, but as soon as they\'re ground, they develop a rich, warm perfume.</p>',
    alt: ['utskho suneli']
  },
  'kondari': {
    d: '<p>It has a green, minty flavor with the lemony sweetness of thyme</p>',
    alt: ['summer savory', 'satureja hortensis']
  },
  'ombalo': {
    d: '<p>Ombalo has a minty, delicately bitter flavor, and it can be used fresh as well as dried.</p><p>Often used in Georgian cooking, particularly in spice mixtures and in tkemali, sour plum sauce.</p><p>Mentha pulegium, commonly known as pennyroyal, has a similar flavor. It\'s used in small quantities. You can substitute it with dried mint.</p>',
  },
  'khmeli suneli': {
    d: '<p>The crowning glory of Georgian spices is a blend called khmeli suneli. It includes up to 15 different herbs and spices and has a heady perfume dominated by the burnt orange notes of coriander seeds.</p><p>It can be used in marinades for vegetables, chicken, fish, and lamb chops. You can add it to soups and sauces. You can use it in bean ragouts and seafood stews.</p><p>It adds a sunny yellow color to finished dishes and a complex aroma with well-defined top, heart, and base notes.</p>',
  },
  'feta': {
    d: '<p>Feta is a brined curd white cheese made in Greece from sheep milk or from a mixture of sheep and goat milk. It is a crumbly aged cheese, commonly produced in blocks, and has a slightly grainy texture in comparison to other cheeses</p>'
  },
  'mozzarella': {
    d: '<p>Mozzarella is a traditionally southern Italian cheese made from Italian buffalo\'s milk by the pasta filata method. Fresh mozzarella is generally white but may vary seasonally to slightly yellow depending on the animal\'s diet.'
  },
  'farmer cheese': {
    d: '<p>An unaged mild white cheese with a crumbly texture.</p>',
    alt: ['farmers cheese']
  },
  'skillet': {
    d: 'A frying pan, frypan, or skillet is a flat-bottomed pan used for frying, searing, and browning foods'
  },
  'vanilla sugar': {
    d: 'Granulated sugar with vanilla beans or vanilla extract. Common in European desserts and can be used in apple pies, pavlovas, etc.'
  },
  'almond meal': {
    d: 'Ground almonds, peeled or unpeeled. If peeled, it can also be called almond flour.'
  },
  'almond flour': {
    d: 'Ground blanched (peeled) almonds. Differs from almond meal which includes the peel.'
  }
}

export const ingredientsWithInfo = () => {
  let result = Object.keys(info)
  Object.values(info).filter(i => i.alt).forEach(info => {
    result = result.concat(info.alt)
  })
  return result
}

const transformInfo = (info) => {
  const result = { ...info }
  result.description = result.d
  delete result.d
  result.otherNames = result.alt
  delete result.alt
  return result
}

export const infoForIngredient = (ingredient) => {
  const ingredientLower = tidyIngredient(ingredient.toLowerCase())

  if (ingredientLower in info) {
    return transformInfo(info[ingredientLower])
  }

  const asOtherName = Object.values(info).find(info => (info.alt || []).indexOf(ingredientLower) > -1)
  if (asOtherName) {
    return transformInfo(asOtherName)
  }

  return null
}

export const infoForIngredientFormatted = (ingredient) => {
  const ingredientInfo = infoForIngredient(ingredient)
  let details = ingredientInfo.description
  if (ingredientInfo.otherNames) {
    details += '<p>Also known as: ' + ingredientInfo.otherNames.join(', ') + '</p>'
  }
  return details
}

const tidyIngredient = (ingredient) => {
  if (['self raising flour', 'sr flour'].indexOf(ingredient) > -1) {
    return 'self-raising flour'
  }
  return ingredient
}
