import React from 'react'
import { connect } from 'react-redux'
import Intro from './intro'
import RecipeList from './RecipeList'
import AuthSelector from '../../app/AuthSelector'

class HomeScreen extends React.Component {
  render() {
    const { props } = this
    const showIntro = !props.hasAuthToken && !props.hasRecipes
    return (
      <div>
        {showIntro && <Intro />}
        {!showIntro && <RecipeList />}
      </div>
    )
  }
}

export default connect((state, ownProps) => {
  return {
    hasAuthToken: new AuthSelector(state).isAuthenticated(),
    hasRecipes: Object.keys(state.recipe.byId).length > 0,
  }
})(HomeScreen)
