import React from 'react'
import { connect } from 'react-redux'
import Icon from '../../app/icon'

class MealPlanEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      meal: 'dinner',
      dishes: [{ type: 'plainText', text: '' }],
    }

    if (props.mealPlan) {
      this.state.id = props.mealPlan.id
      this.state.lastUpdated = props.mealPlan.lastUpdated
      this.state.serverLastUpdated = props.mealPlan.serverLastUpdated
      this.state.meal = props.mealPlan.meal
      this.state.dishes = props.mealPlan.dishes.concat(this.state.dishes)
    }

    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleKeyPress(event) {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault()
      this.handleMealPlansSave()
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress, false)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false)
  }

  removeEmptyDishes(dishes) {
    return dishes.filter((d) => (d.type === 'plainText' && d.text !== '') || d.type === 'recipe')
  }

  async handleMealPlansSave() {
    const mealPlan = {
      id: this.state.id,
      lastUpdated: this.state.lastUpdated,
      serverLastUpdated: this.state.serverLastUpdated,
      meal: this.state.meal,
      dishes: this.removeEmptyDishes(this.state.dishes),
    }
    if (mealPlan.dishes.length === 0) {
      mealPlan.archived = true
    }

    await window.storageService.store('mealPlan', [mealPlan])

    this.props.onFinished()
  }

  handleInput(index, value) {
    const dishes = [...this.state.dishes]
    dishes[index] = value

    const last = dishes[dishes.length - 1]
    if (last && last.type === 'plainText' && last.text !== '') {
      dishes.push({ type: 'plainText', text: '' })
    }
    this.setState({ dishes })
  }

  removeDish(index) {
    const dishes = [...this.state.dishes]
    dishes.splice(index, 1)
    this.setState({ dishes })
  }

  render() {
    const { props, state } = this
    const meal = state.meal
    const dishes = state.dishes
    return (
      <div>
        <div className={'meal meal-' + meal}>
          <form onSubmit={(e) => this.handleMealPlansSave()}>
            <div className="padded-below">
              <div className="subtitle is-6">Meal</div>
              <div className="select">
                <select value={this.state.meal} onChange={(e) => this.setState({ meal: e.target.value })}>
                  <option value="breakfast">Breakfast</option>
                  <option value="lunch">Lunch</option>
                  <option value="dinner">Dinner</option>
                </select>
              </div>
            </div>
            <div>
              <div className="subtitle is-6">Dishes</div>
              {dishes.map((dish, index) => {
                const recipe = dish.recipeId ? this.props.recipesById[dish.recipeId] : null
                return (
                  <div key={index} className="dish-input">
                    {dish.type === 'plainText' && (
                      <input
                        type="text"
                        className="input"
                        value={dish.text}
                        onInput={(e) => this.handleInput(index, { ...dish, text: e.target.value })}
                      />
                    )}
                    {dish.type === 'recipe' && (
                      <div>
                        {recipe ? recipe.name : dish.recipeId}
                        <button
                          className="button"
                          style={{ marginLeft: '10px' }}
                          onClick={(e) => e.preventDefault() || this.removeDish(index)}
                        >
                          <Icon width="16" height="16" id="delete" />
                        </button>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            <button
              className="button margin-top margin-right"
              type="submit"
              onClick={(e) => e.preventDefault() || this.handleMealPlansSave()}
            >
              Save
            </button>
            <button className="button margin-top margin-right" type="button" onClick={(e) => this.props.onFinished()}>
              Cancel
            </button>
          </form>
        </div>
        <div className="hr"></div>
      </div>
    )
  }
}

export default connect((state, ownProps) => {
  return {
    recipesById: state.recipe.byId,
  }
})(MealPlanEdit)
