// @ts-ignore
import { useEffect, useState } from "react";
import './AppWarnings.css'

export function AppWarnings(props: any) {
  let [online, isOnline] = useState(navigator.onLine);

  let [serviceWorkerWaitingToInstall, setServiceWorkerWaitingToInstall] = useState(false);

  if ('serviceWorker' in navigator) {
    useEffect(() => {
      (async () => {
        const registrations = await navigator.serviceWorker.getRegistrations();
        if (registrations.length > 0) {
          const registration = registrations[0]
          if (registration.waiting && registration.active) {
            setServiceWorkerWaitingToInstall(true)
          }
          registration.addEventListener('updatefound', () => {
            if (registration.installing) {
              registration.installing.addEventListener('statechange', (event: Event) => {
                if (event && event.target && (event.target as ServiceWorker).state === 'installed') {
                  if (registration.active) {
                    setServiceWorkerWaitingToInstall(true)
                  }
                }
              })
            }
          })
        }
      })();
      return () => { };
    }, []);
  }

  const setOnline = () => isOnline(true)
  const setOffline = () => isOnline(false)

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    // cleanup when we unmount
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    }
  }, []);

  return (
    <div>
      {props.children}
      {!online && <div className="app-error has-background-warning has-text-white">Currently offline</div>}
      {serviceWorkerWaitingToInstall && <div className="app-error has-background-warning has-text-white">Update pending. Please close all windows and re-open.</div>}
    </div>
  )
}
